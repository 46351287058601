import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Spinner } from "../../components";
import { Button as Btn, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PermissionModel from "../../model/permission";
import EditIcon from "../../assets/admin/edit-icon.svg";
import RoleModel from "../../model/role";
import ApiService from "../../service/ApiService";
import DeleteModal from "../modal/DeleteModal";
import useStyles from "./styles";

//TODO: fix css; add permission list; add delete button

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const RoleModal = ({ role, handleCreate, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = role != null;
  const id = edit ? role.id : 0;
  const [name, setName] = useState(edit ? role.name : "");
  const [permissions, setPermissions] = useState(edit ? role.Permissions : []);
  const [permissionList, setPermissionList] = useState<PermissionModel[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [togglePermissions, setTogglePermissions] = useState(false);

  // TODO
  // useEffect(() => {
  //   const fetchData = async () => {
  //     await ApiService.get<PermissionModel[]>("permissions").then(
  //       (data: PermissionModel[]) => {
  //         setPermList(data);

  //         setToggleSpinner(false);
  //       }
  //     );
  //   };

  //   if (permList.length <= 0) fetchData();
  // }, [permList]);

  const handleName = (e: any) => {
    setName(e.target.value);
  };

  const handleTogglePermissions = () => {
    setTogglePermissions((togglePermissions) => !togglePermissions);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: RoleModel = {
      id: id,
      name: name,
      //Permissions: permissions,
    };

    if (edit) {
      await handleUpdate(payload);
    } else {
      await handleCreate(payload);
    }

    handleClose();
  };

  const invalidInput = () => {
    return name === "";
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Role
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <Typography className={classes.title}>
                  <DeleteModal item={role} handleDelete={handleDelete} />
                </Typography>
              ) : (
                <Typography className={classes.title}>Add role</Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            {edit && <hr />}
            <form className={classes.form}>
              <input
                className={classes.input}
                type="text"
                value={name}
                onChange={handleName}
                placeholder="Role name"
              />
              {/* <div className={classes.permissionDiv}>
                <Typography className={classes.subTitle}>
                  Permissions
                </Typography>
                {togglePermissions ? (
                  <ExpandLessIcon onClick={handleTogglePermissions} />
                ) : (
                  <ExpandMoreIcon onClick={handleTogglePermissions} />
                )}
              {togglePermissions &&
                permissionList.map((perm: PermissionModel) => (
                  <Typography>{perm.name}</Typography>
                ))}
                  </div> */}
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={register}
                disabled={invalidInput()}
              >
                Save
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default RoleModal;
