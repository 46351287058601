import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import TransformDate from "../../../utils/TransformDate";
import { BlobProvider } from "@react-pdf/renderer";
import PDFFile from "../../../utils/PDFFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LoopIcon from "@mui/icons-material/Loop";
import ClientInfoModal from "../../client/ClientInfoModal";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../../shared/styles/colors";
import FirebaseService from "../../../service/FirebaseService";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import ClientModel from "../../../model/client";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { Spinner } from "../../../components";
import ApiService from "../../../service/ApiService";
import AlertService from "../../../service/AlertService";
import ToastMessages from "../../../model/enum/ToastMessages";
import EstimateModel from "../../../model/estimate";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../../styles/cardStyles";
import EstimateModal from "./EstimateModal";
import Status from "../../../model/enum/StatusClass";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EstimateDetailModal from "./EstimateDetailModal";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Estimates = () => {
  const classes = useStyles();
  const [estimates, setEstimates] = useState<EstimateModel[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  // Get all the checklist items from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<EstimateModel[]>(DatabaseEntities.ESTIMATES).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);

          setEstimates(data);
          setToggleSpinner(false);
        }
      );
    };

    if (toggleSpinner && estimates.length <= 0) {
      fetchData();
    }
  }, [estimates]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<EstimateModel>(DatabaseEntities.ESTIMATES, payload)
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<EstimateModel>(
      `${DatabaseEntities.ESTIMATES}/${payload.id}`,
      payload
    )
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.ESTIMATES}/${payload.id}`)
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = () => {
    setEstimates([]);
    setToggleSpinner(true);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}></Typography>
        <div className={classes.headerDiv}>
          <EstimateModal handleCreate={handleCreate} />
          <Button onClick={resetList}>
            <CachedIcon />
          </Button>
        </div>
      </div>
      <Paper>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="customized table"
            padding="normal"
            size="medium"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">Id</StyledTableCell>
                <StyledTableCell align="right">Code</StyledTableCell>
                <StyledTableCell align="right">Costumer</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">DueDate</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
                <StyledTableCell align="right">
                  <MoreVertIcon />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {toggleSpinner ? (
              <Spinner />
            ) : (
              <TableBody>
                {estimates.length > 0 ? (
                  estimates.map((row: EstimateModel) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="right">{row.id}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.code}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.User?.firstName} {row.User?.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.total}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography
                          style={Status.mapStatusColor(row.Status?.name)}
                        >
                          <div
                            style={Status.mapStatusSubDivColor(
                              row.Status?.name
                            )}
                          ></div>
                          {row.Status?.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {TransformDate.toFullDate(row.dueDate)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <PictureAsPdfIcon />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <EstimateDetailModal
                          estimate={row}
                          handleCreate={handleCreate}
                          handleUpdate={handleUpdate}
                          handleDelete={handleDelete}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell align="right">No items</StyledTableCell>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Estimates;
