import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../styles";

const Step1 = ({ entryZipCode, zipCode, city, state }: any) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subtitleDiv}>
        <Typography className={classes.subtitle}>
          Fill in the form with your location details
        </Typography>
      </div>
      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="text"
          placeholder="ZIP code..."
          name="zipcode"
          maxLength={5}
          onChange={entryZipCode}
          required
          value={zipCode}
        />
      </div>
      {city ? (
        <Typography className={classes.city}>
          {city}, {state}
        </Typography>
      ) : (
        <Typography className={classes.city}>{state}</Typography>
      )}
    </>
  );
};

export default Step1;
