import { makeStyles } from "@material-ui/core/styles";
import { BackgroundBlue, PrimaryColor } from "../../../styles/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  card: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    padding: "5rem",
    "@media (max-width: 640px)": {
      width: "90% !important",
    },
    "@media (min-width: 1536px)": {
      width: "60% !important",
    },
  },
  title: {
    width: "100%",
    fontSize: "1.5rem",
    fontWeight: 700,
    textAlign: "start",
    color: PrimaryColor,
    marginTop: "5%",
    marginBottom: "3%",
  },
  description: {
    fontSize: "1.1rem",
    textAlign: "justify",
  },
  text: {
    fontSize: "1.1rem",
    textAlign: "justify",
    marginTop: "5%",
  },
  textDecor: {
    fontWeight: "600",
  },
}));
