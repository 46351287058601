import * as React from "react";
import { useEffect, useState } from "react";
import { PrimaryColor, TableHeaderGrey } from "../../shared/styles/colors";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import FirebaseService from "../../service/FirebaseService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import ProjectModel from "../../model/project";
import RowHorizontal from "../../assets/admin/row-horizontal.svg";
import RowGrid from "../../assets/admin/row-grid.svg";
import useStyles from "./styles";
import styles from "../styles/cardStyles";
import { Spinner } from "../../components";
import ProfileIcon from "../../assets/admin/profile-icon.svg";
import CalendarIcon from "../../assets/admin/calendar-icon.svg";
import TimeIcon from "../../assets/admin/time-icon.svg";
import ApiService from "../../service/ApiService";

const Projects = () => {
  const classes = useStyles();
  const cardStyles = styles();

  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [items, setItems] = useState<ProjectModel[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ProjectModel[]>(DatabaseEntities.PROJECTS).then(
        (data: ProjectModel[]) => {
          if (data.length == 0) {
            setToggleSpinner(false);
            return;
          }

          setProjects(data);
          setToggleSpinner(false);
        }
      );
    };

    if (toggleSpinner && projects.length <= 0) fetchData();
  }, [projects]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<ProjectModel>(DatabaseEntities.PROJECTS, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<ProjectModel>(
      `${DatabaseEntities.PROJECTS}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const resetList = (): void => {
    setProjects([]);
    setItems([]);
    setPage(0);
  };

  const changeRowDisplay = () => {};

  return (
    <>
      <Card raised className={cardStyles.card}>
        <div className={cardStyles.headerContainer}>
          <Typography className={cardStyles.title}>Projects</Typography>
          <div className={cardStyles.headerDiv}>
            <Button onClick={changeRowDisplay}>
              <img src={RowGrid} />
            </Button>
            <Button onClick={changeRowDisplay}>
              <img src={RowHorizontal} />
            </Button>
          </div>
        </div>
        <CardContent className={classes.container}>
          <div className={classes.addItemGrid}>
            <Button style={{ fontSize: "3rem", color: "#999999" }}>+</Button>
          </div>
          {toggleSpinner ? (
            <Spinner />
          ) : (
            <>
              {projects.map((item) => (
                <div className={classes.itemGrid}>
                  <div className={classes.itemHeader}>
                    <img
                      src={ProfileIcon}
                      className={classes.img}
                      alt="profile"
                    />
                    <Typography className={classes.priceText}>
                      $450.00
                    </Typography>
                  </div>
                  <div className={classes.itemBody}>
                    <Typography className={classes.title}>
                      Kitchen Remodel
                    </Typography>
                    <Typography className={classes.description}>
                      Upgrade kitchen with new cabinets, countertops, and
                      appliances.{" "}
                    </Typography>
                  </div>
                  <div className="d-flex col mt-2 align-items-center">
                    <div className="d-flex col">
                      <img
                        src={CalendarIcon}
                        className={classes.icon}
                        alt="calendar"
                      />
                      <Typography className={classes.time}>
                        May 20, 2024
                      </Typography>
                    </div>
                    <img
                      src={ProfileIcon}
                      className={classes.img}
                      alt="profile"
                    />
                  </div>
                  <div className="d-flex col align-items-center">
                    <img src={TimeIcon} className={classes.icon} alt="time" />
                    <Typography className={classes.time}>8:30 A.M</Typography>
                  </div>
                  <Button className={classes.button}>Apply now</Button>
                  {/* <Button className={classes.button}>Show details</Button> */}
                </div>
              ))}
            </>
          )}
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Projects;
