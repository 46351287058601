import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Typography } from "@material-ui/core";
import ProductModel from "../../../model/product";
import ProductModal from "./ProductModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  product: ProductModel;
  handleUpdate: any;
  handleDelete: any;
}

const ProductDetailModal = ({ product, handleUpdate, handleDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}>Product details</Typography>
              <div className={classes.headerSubDiv}>
                <ProductModal
                  product={product}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <form className={classes.form}>
              {product.imageUrl && (
                <img
                  className={classes.imageDetail}
                  src={product.imageUrl}
                  alt="product img"
                />
              )}
              <Typography className="fw-light">
                {product.Category?.name}
              </Typography>
              <div className={classes.producDiv}>
                <Typography className={classes.productName}>
                  {product.name}
                </Typography>
                <Typography className={classes.productName}>
                  ${product.price}.00
                </Typography>
              </div>
              <Typography className={classes.productDescription}>
                {product.description}
              </Typography>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ProductDetailModal;
