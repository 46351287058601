import { makeStyles } from "@material-ui/core/styles";
import { Black, PrimaryColor, White } from "../../shared/styles/colors";

export default makeStyles(() => ({
  title: {
    color: PrimaryColor,
    fontSize: "1.7rem",
    fontWeight: "bold",
    marginTop: "3rem",
    marginBottom: "3rem",
    "@media (max-width: 640px)": {
      fontSize: "1.5rem",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
  },
  listServices: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50rem",
    height: "auto",
    borderRadius: "1rem",
    marginBottom: "5%",
    "@media (max-width: 640px)": {
      width: "18rem",
    },
    // "@media (max-width: 820px)": {
    //   width: "75%",
    // },
    // "@media (min-width: 1400px)": {
    //   width: "50%",
    // },
  },
  rows: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  categoryDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    border: "1px solid lightgrey",
    borderRadius: "10px",
    cursor: "pointer",
    background: White,
    margin: "1%",
    "&:hover": {
      transition: "all 0.5s ease",
      transform: "scale(1.05)",
      backgroundColor: White,
      boxShadow: "0 0 5px 0 rgba(0,0,0,0.5)",
    },
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  img: {
    width: "100px",
    height: "50px",
    "@media (max-width: 640px)": {
      width: "70px",
    },
  },
  label: {
    color: Black,
    textTransform: "uppercase",
    fontSize: "1rem",
    fontWeight: "bold",
    width: "70%",
    marginTop: "10%",
    cursor: "pointer",
    "@media (max-width: 640px)": {
      fontSize: "1.1rem",
    },
    "@media (max-width: 820px)": {
      fontSize: "1rem",
    },
  },
}));
