import { makeStyles } from "@material-ui/core/styles";
import {
  BackgroundBlue,
  LightPrimaryColor,
  PrimaryColor,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  errorPage: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    top: "0%",
    background: BackgroundBlue,
  },
  card: {
    width: "1000px !important",
    borderRadius: "25px !important",
    "@media (max-width: 640px)": {
      width: "90% !important",
      height: "auto",
    },
  },
  img: {
    width: "60%",
    marginTop: "10px",
    "@media (max-width: 640px)": {
      width: "90% !important",
    },
  },
  text: {
    color: PrimaryColor,
    fontWeight: "bold",
    "&:hover": {
      color: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      fontSize: "1.2rem",
    },
  },
}));
