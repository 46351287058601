import React from "react";
import Stats from "./Stats";
import UpcomingMeetings from "./UpcomingMeetings";
import useStyles from "./styles";

const Dashboard = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.statsContainer}>
        <Stats />
      </div>
      <div className={classes.meetingContainer}>
        <UpcomingMeetings />
      </div>
    </>
  );
};

export default Dashboard;
