import { makeStyles } from "@material-ui/core/styles";
import {
  DarkGrey,
  LightPrimaryColor,
  PrimaryColor,
  White,
} from "../../styles/colors";

export default makeStyles(() => ({
  footer: {
    display: "block",
    background: White,
    width: "100%",
  },
  container: {
    display: "flex",
    width: "100%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logoDiv: {
    display: "flex",
    flexDirection: "row",
    width: "17.5%",
    alignItems: "start",
    justifyContent: "center",
    marginTop: "6%",
    marginLeft: "5%",
    "@media (max-width: 640px)": {
      width: "30%",
    },
  },
  logo: {
    width: "7.5rem",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  linksDiv: {
    display: "flex",
    flexDirection: "column",
    width: "17.5%",
    justifyContent: "start",
    alignItems: "start",
    marginTop: "5%",
    marginLeft: "5%",
    "@media (max-width: 640px)": {
      height: "auto",
      width: "100%",
    },
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: "bold",
    color: PrimaryColor,
  },
  text: {
    color: DarkGrey,
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "1rem",
    "&:hover": {
      color: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      textAlign: "start",
    },
  },
  callDiv: {
    display: "flex",
    flexDirection: "column",
    width: "17.5%",
    justifyContent: "start",
    marginTop: "5%",
    marginRight: "5%",
    "@media (max-width: 640px)": {
      alignItems: "center",
      width: "100%",
    },
  },
  anchor: {
    width: "100%",
    cursor: "pointer",
  },
  qrCode: {
    width: "7rem",
  },
  socialMediaDiv: {
    width: "100%",
    marginTop: "5%",
  },
  socialMediaContent: {
    width: "15%",
  },
  copyright: {
    color: DarkGrey,
    fontSize: "0.7rem",
    marginBottom: "2%",
  },
  lineDiv: {
    display: "flex",
    width: "100%",
    marginTop: "3%",
    alignItems: "center",
    justifyContent: "center"
  }
}));
