import React, { useState, useMemo, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import TransformDate from "../../utils/TransformDate";
import { LightPrimaryColor, PrimaryColor } from "../../shared/styles/colors";
import useStyles from "./styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FullscreenImage from "../../shared/components/images/FullscreenImage";
import ApiService from "../../service/ApiService";

const Maps = () => {
  const classes = useStyles();

  const MapId = process.env.REACT_APP_GOOGLE_MAP_ID;

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [reviews, setReviews] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
  });

  // Center to Fall River region
  const center = useMemo(() => ({ lat: 41.6897843, lng: -71.1372697 }), []);

  const customMarker = {
    path: "M79.3,10.74c-3.88-1-7.91-1.56-12.08-1.63h-0.93c-4.16,0.07-8.21,0.63-12.06,1.63 C31.11,16.61,14.02,37.6,14.02,62.6c0,3.99,0.43,7.89,1.26,11.64c0.96,3.51,2.53,7.95,4.98,13.48c0,0.01,0,0.04,0.02,0.06 c6.67,15.08,20.04,38.32,46.39,73.15c0.03,0.03,0.07,0.08,0.09,0.11c0.02-0.03,0.06-0.08,0.08-0.11 c26.36-34.83,39.7-58.07,46.39-73.15c0.01-0.02,0.02-0.05,0.02-0.06c2.45-5.53,4.01-9.97,4.99-13.48 c0.83-3.75,1.25-7.65,1.25-11.64C119.49,37.6,102.41,16.61,79.3,10.74z M66.75,106.95c-24.57,0-44.49-19.92-44.49-44.49 c0-24.58,19.92-44.5,44.49-44.5c24.58,0,44.5,19.92,44.5,44.5C111.25,87.03,91.33,106.95,66.75,106.95z M67.104,44.726l16.562,18.925v15.887c0,1.294-1.069,2.363-2.363,2.363h-7.09 c-1.294,0-2.363-1.069-2.363-2.363v-9.453h-9.435v9.453c0,1.294-1.069,2.363-2.363,2.363h-7.09c-1.294,0-2.363-1.069-2.363-2.363 V63.557L67.104,44.726z M90.756,60.65c0,1.013-0.638,1.876-1.538,2.194L67.104,37.617L44.99,62.826 c-0.562-0.225-1.013-0.656-1.275-1.219c-0.375-0.863-0.225-1.857,0.413-2.551l21.251-23.614c0.9-0.994,2.607-0.994,3.507,0 l21.251,23.614C90.531,59.506,90.756,60.069,90.756,60.65z M48.519,48.554l3.63-4.033v-4.55c0-0.417-0.338-0.755-0.755-0.755h-2.208 c-0.42,0-0.759,0.343-0.755,0.763L48.519,48.554z",
    fillColor: PrimaryColor,
    fillOpacity: 2,
    strokeWeight: 0.5,
    rotation: 0,
    scale: 0.4,
  };

  useEffect(() => {
    const getReviews = async () => {
      await ApiService.get("frontend/reviews").then((data) => {
        setReviews(data);
      });
    };

    if (reviews.length <= 0) getReviews();
  }, [reviews]);

  const markers = reviews.map((mark) => {
    return {
      id: mark.id,
      address: mark.location,
      lat: Number.parseFloat(mark.latitude),
      lng: Number.parseFloat(mark.longitude),
      imageUrl: mark.imageUrl,
      //imagesUrl: mark.ImagesUrl,
      description: mark.description,
      date: TransformDate.toMonthNameAndYear(mark.finishedAt),
    };
  });

  console.log(markers);
  
  const handleMarkerClick = (id, lat, lng, address, imgId) => {
    console.log(imgId);
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  const toggleFullScreen = (img) => {
    setFullScreen((fullScreen) => !fullScreen);
    setImgUrl(img);
  };

  return (
    <div className={classes.mapContainer}>
      <div className={classes.map}>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            center={center}
            zoom={10.5}
            options={{ mapId: MapId, backgroundColor: LightPrimaryColor }}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            onClick={() => setIsOpen(false)}
          >
            {markers.map(
              ({ address, lat, lng, imageUrl, description, date, id }, ind) => (
                <Marker
                  key={ind}
                  position={{ lat, lng }}
                  icon={customMarker}
                  onClick={() => {
                    handleMarkerClick(ind, lat, lng, address, id);
                  }}
                >
                  {isOpen && infoWindowData?.id === ind && (
                    <InfoWindow
                      onCloseClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Card>
                        {/* {imagesUrl?.length > 0 &&
                          imagesUrl.map((imgg) => {
                            return (
                              <CardMedia
                                image={imgg}
                                style={{
                                  backgroundSize: "contains",
                                  width: "100%",
                                  height: "auto",
                                  aspectRatio: 1 / 1,
                                  cursor: "zoom-in",
                                }}
                                onClick={() => toggleFullScreen(imgg)}
                              ></CardMedia>
                            );
                          })} */}
                        <CardMedia
                          image={imageUrl}
                          style={{
                            backgroundSize: "contains",
                            width: "100%",
                            height: "auto",
                            aspectRatio: 1 / 1,
                            cursor: "zoom-in",
                          }}
                          onClick={() => toggleFullScreen(imageUrl)}
                        ></CardMedia>
                        <CardContent>
                          <div className={classes.markerContent}>
                            <div className={classes.markerLocation}>
                              <LocationOnIcon />
                              <Typography style={{ fontSize: ".9rem" }}>
                                {address}
                              </Typography>
                            </div>
                            <Typography style={{ fontSize: ".8rem" }}>
                              {date}
                            </Typography>
                          </div>
                          <Typography style={{ fontSize: ".9rem" }}>
                            {description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </InfoWindow>
                  )}
                </Marker>
              )
            )}
          </GoogleMap>
        )}
        {fullScreen && (
          <FullscreenImage
            imageUrl={imgUrl}
            toggleFullScreen={toggleFullScreen}
            reviewMode={true}
          />
        )}
      </div>
    </div>
  );
};

export default Maps;
