import { makeStyles } from "@material-ui/core/styles";
import {
  LightPrimaryColor,
  Red,
  White,
  PrimaryColor,
  LightGreyBackground,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  login: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    background: LightGreyBackground,
  },
  loginCard: {
    display: "flex",
    flexDirection: "column",
    width: "700px !important",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "25px !important",
    background: White,
    "@media (min-width: 1536px)": { //xxl
      width: "30% !important",
    },
    "@media (max-width: 1024px)": { //md
      width: "60% !important",
    },
    "@media (max-width: 768px)": { //md
      width: "80% !important",
    },
    "@media (max-width: 640px)": { //sm
      width: "90% !important",
    },
  },
  logo: {
    width: "8rem",
    height: "auto",
    marginTop: "5%",
    marginBottom: "2%",
  },
  inputDiv: {
    display: "flex",
    width: "100%",
    height: "44px",
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  input: {
    width: "100%",
    height: "44px",
    border: "1px solid lightgrey",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
  },
  emptyInput: {
    width: "87%",
    border: "none",
    padding: "8px 16px 8px 16px",
  },
  errorText: {
    color: Red,
  },
  pwdViewer: {
    marginTop: "5%",
  },
  button: {
    padding: "1% 3% 1% 3%",
    width: "70%",
    color: White,
    marginTop: "10%",
    marginBottom: "5%",
    borderRadius: "10px",
    background: PrimaryColor,
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "not-allowed",
    },
  },
  checkboxDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    accentColor: PrimaryColor,
    marginRight: "1%"
  },
  //Activation screen
  activationCard: {
    display: "flex",
    flexDirection: "column",
    width: "700px !important",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "25px !important",
    background: White,
    "@media (min-width: 1536px)": { //xxl
      width: "30% !important",
    },
    "@media (max-width: 1024px)": { //md
      width: "65% !important",
    },
    "@media (max-width: 768px)": { //md
      width: "80% !important",
    },
    "@media (max-width: 640px)": { //sm
      width: "90% !important",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
    alignItems: "center",
  },
  activationHeader: {
    display: "flex",
    flexDirection: "column",
    width: "80% !important",
    textAlign: "start",
    marginTop: "5%",
    marginBottom: "5%",
  },
  boldText: {
    width: "100%",
    marginTop: "2%",
    fontSize: "1.2rem",
    fontWeight: 700
  }
}));
