import React from "react";
import { Typography } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import useStyles from "../styles";

const BreadCrumb = ({step}: any) => {
  const classes = useStyles();

  return (
    <div className={classes.stpperDiv}>
      <div className="d-flex row justify-content-center w-25">
        <div className={step >= 0 ? classes.breadcrumbCircular : classes.breadcrumbCircularDisabled}>{step > 0 ? <CheckIcon /> : 1}</div>
        <Typography className={step >= 0 ? classes.breadcrumbText : classes.breadcrumbTextDisabled}>Location</Typography>
      </div>
      <hr style={{ width: "10rem" }} />
      <div className="d-flex row justify-content-center w-25">
        <div className={step >= 1 ? classes.breadcrumbCircular : classes.breadcrumbCircularDisabled}>{step > 1 ? <CheckIcon /> : 2 }</div>
        <Typography className={step >= 1 ? classes.breadcrumbText : classes.breadcrumbTextDisabled}>
          Request details
        </Typography>
      </div>
      <hr style={{ width: "10rem", color: step >= 1 ? "" : "lightgrey"}}/>
      <div className="d-flex row justify-content-center w-25">
        <div className={step >= 2 ? classes.breadcrumbCircular : classes.breadcrumbCircularDisabled}>3</div>
        <Typography className={step >= 2 ? classes.breadcrumbText : classes.breadcrumbTextDisabled}>
          Personal information
        </Typography>
      </div>
    </div>
  );
};

export default BreadCrumb;
