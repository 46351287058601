import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import Calendar from "react-calendar";
import CalendarService from "../../service/CalendarService";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Status from "../../model/enum/StatusClass";
import CloseIcon from "@mui/icons-material/Close";
import AppointmentModal from "./AppointmentModal";
import SendEmailModal from "../modal/SendEmailModal";
import ZillowIcon from "../../assets/admin/zillow-icon.svg";
import ClientModel from "../../model/client";
import TransformDate from "../../utils/TransformDate";
import AppointmentModel from "../../model/appointment";
import UserModel from "../../model/user";
import useStyles from "./styles";
import styled from "styled-components";

// Customize the button styles here
export const CalendarWrapper = styled.div`
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: white; /* Custom color */
    color: black; /* Button text color */
    border: none; /* Remove default border */
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:after {
    color: white; /* Text color for active date */
    background: #1e2e55;
    border-radius: 20px; /* Optional: make the button rounded */
  }

  .react-calendar__tile--active:hover {
    background-color: #1e2e5580; /* Darker color on hover */
  }
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
    top: "70%",
  },
};

interface Props {
  appointment: AppointmentModel;
  handleUpdate: any;
  handleDelete: any;
}

const AppointmentDetailModal = ({
  appointment,
  handleUpdate,
  handleDelete,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const client: ClientModel | undefined = appointment.Client;
  const user: UserModel | undefined = appointment.User;

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}>
                <div
                  style={Status.mapStatusDivColor(appointment?.Status?.name)}
                >
                  <div
                    style={Status.mapStatusSubDivColor(
                      appointment?.Status?.name,
                      false
                    )}
                  ></div>
                </div>
              </Typography>
              <div className={classes.headerSubDiv}>
                {appointment?.Status?.name === Status.ACCEPTED && (
                  <CalendarService
                    payload={appointment}
                    client={client}
                    seller={user}
                    handleClose={handleClose}
                  />
                )}
                {handleUpdate && handleDelete && (
                  <AppointmentModal
                    appointment={appointment}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                  />
                )}
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <form className={classes.form}>
              <div className={classes.container}>
                <div className={classes.inputContainer}>
                  <Typography className={classes.label}>Client</Typography>
                  <input
                    className={classes.inputDetail}
                    type="text"
                    value={client?.firstName + " " + client?.lastName}
                    disabled
                  />
                  <div className={classes.inputGroup}>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.label}>
                        Starting time
                      </Typography>
                      <input
                        className={classes.inputDetail}
                        value={TransformDate.convertTo12HourFormat(
                          appointment.initialTime
                        )}
                        disabled
                      />
                    </div>
                    <div className={classes.inputDiv}>
                      <Typography className={classes.label}>
                        Ending time
                      </Typography>
                      <input
                        className={classes.inputDetail}
                        value={TransformDate.convertTo12HourFormat(
                          appointment.finalTime
                        )}
                        disabled
                      />
                    </div>
                  </div>
                  <Typography className={classes.label}>Seller</Typography>
                  <input
                    className={classes.inputDetail}
                    value={appointment.User ? appointment.User.firstName : ""}
                    disabled
                  />
                  <Typography className={classes.label}>Notes</Typography>
                  <textarea
                    className={classes.textareaDetail}
                    value={appointment.notes}
                    disabled
                  />
                </div>
                <div
                  className={`calendar-container ${classes.calendarContainer}`}
                >
                  <CalendarWrapper>
                    <Calendar
                      className={classes.calendar}
                      calendarType={"US"}
                      value={new Date(appointment.scheduledAt)}
                      tileDisabled={({ date }) => date.getDay() === 6} //disabled on saturdays
                    />
                  </CalendarWrapper>
                </div>
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <SendEmailModal name={client?.firstName} email={client?.email} />
              {client?.street && (
                <Btn
                  className={classes.buttonDetail}
                  onClick={() =>
                    window.open(
                      `https://www.zillow.com/homes/${client.street}, ${client.city}, ${client.state} ${client.zip}`
                    )
                  }
                >
                  <img src={ZillowIcon} alt="zillow" />
                  <Typography style={{ marginLeft: "5%" }}>Zillow</Typography>
                </Btn>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AppointmentDetailModal;
