// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getDatabase } from "firebase/database";

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcJMWi4t084ceiGoowAPsG1mKepm5kwJ0",
  authDomain: "supple-moon-377718.firebaseapp.com",
  projectId: "supple-moon-377718",
  storageBucket: "supple-moon-377718.appspot.com",
  messagingSenderId: "861643724516",
  appId: "1:861643724516:web:0cc63e85cc32660c65bef1",
  measurementId: "G-77GYSDDLX4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const auth = getAuth(app);
export const dbF = getFirestore(app);
export const db = getDatabase(app);
export const analytics = getAnalytics(app);

export default getFirestore();