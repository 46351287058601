import React from "react";

const Employees = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      Employees
    </div>
  );
};

export default Employees;
