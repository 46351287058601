import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useStyles from "./styles";

const CategoryInGallery = ({
  autoplay,
  showArrows,
  showIndicators,
  service,
  data,
}: any) => {
  const classes = useStyles();

  return (
    <Carousel
      className={classes.carousel}
      autoPlay={autoplay}
      infiniteLoop={true}
      interval={6000}
      showArrows={showArrows}
      showStatus={false}
      showThumbs={false}
      showIndicators={showIndicators}
    >
      {data.map((image: string) => {
        return (
          <div key={image}>
            <img
              src={image}
              alt="kitchen and bathroom renewd"
              className={classes.carouselMedia}
              loading="lazy"
              height={100}
              width={100}
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default CategoryInGallery;
