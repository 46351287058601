import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useCookies } from "react-cookie";
import CookiesSettings from "./CookiesSettings";
import Logo from "../../../assets/icon.png";
import useStyles from "./styles";

const Cookies = () => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["acceptedCookie"]);
  const [showCookie, setShowCookie] = useState(false);
  const [showCookieSetting, setShowCookieSetting] = useState(false);

  useEffect(() => {
    if (cookies.acceptedCookie !== null && cookies.acceptedCookie === "true")
      setShowCookie(false);
    else setShowCookie(true);
  }, [cookies.acceptedCookie]);

  const handleAccept = () => {
    setCookie("acceptedCookie", true);
    setShowCookie(false);
    setShowCookieSetting(false);
  };

  const handleSettings = () => {
    setShowCookieSetting(true);
    setShowCookie(false);
  };

  return (
    <>
      {showCookie && !showCookieSetting && (
        <div className={classes.container}>
          <div className={classes.logoDiv}>
            <img src={Logo} className={classes.logo} alt="logo" />
          </div>
          <div className={classes.textDiv}>
            <Typography className={classes.text}>
              We use cookies to ensure that we give you the best experience on
              our website.
              <a href="/privacy-policy"> Read cookies policies.</a>
            </Typography>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.cookiesSettings}
              onClick={handleSettings}
            >
              Cookies Settings
            </Button>
            <Button className={classes.acceptButton} onClick={handleAccept}>
              Accept All Cookies
            </Button>
          </div>
        </div>
      )}
      {showCookieSetting && <CookiesSettings handleAccept={handleAccept} />}
    </>
  );
};

export default Cookies;
