import React from "react";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-transitions.css";
import "lightgallery/css/lightgallery-bundle.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import "./ListInGallery.css";
import { Skeleton } from "@mui/material";
import Gallery from "../../../model/gallery";

const ListInGallery = ({ images, handleNavbar }: any) => {
  const updateQueryParam = (id: number | undefined) => {
    if (id) {
      const url = new URL(window.location.href);
      url.searchParams.set("toggleFullscreen", "true");
      window.history.pushState({}, "gallery", url);
      handleNavbar(false);
    }
  };

  const removeQueryParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("toggleFullscreen");
    window.history.pushState({}, "gallery", url);
    handleNavbar(true);
  };

  return (
    <div className="text-center clip-path-bg-inner">
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        onBeforeClose={() => removeQueryParam()}
        closeOnTap
      >
        {images.map((item: Gallery) => (
          <a
            key={item.id}
            className="gallery-item"
            data-src={item.imageUrl}
            data-sub-html={item.description + "<br/>" + item?.Service?.name}
            onClick={() => updateQueryParam(item.id)}
          >
            {item.imageUrl ? (
              <img
                className="img-responsive img-gallery"
                src={item.imageUrl}
                alt="Gallery"
              />
            ) : (
              <Skeleton variant="rectangular" width={210} height={118} />
            )}
          </a>
        ))}
      </LightGallery>
    </div>
  );
};

export default ListInGallery;
