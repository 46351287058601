import React, { useState } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import Calendar from "react-calendar";
import US_States from "../../model/enum/US_States";
import ClientModel from "../../model/client";
import ClientServices from "../../model/enum/ClientServices";
import ToastMessages from "../../model/enum/ToastMessages";
import TimePicker from "react-time-picker";
import ThanksPage from "../../shared/components/greetings/ThanksPage";
import AppointmentModel from "../../model/appointment";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { NumberFormatter } from "react-number-formatter";
import { gtag_report_conversion } from "../../utils/GoogleReport";
import AlertService from "../../service/AlertService";
import EmailService from "../../service/EmailService";
import SmsService from "../../service/SmsService";
import ApiService from "../../service/ApiService";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import useStyles from "./styles";
import styled from "styled-components";

// Customize the button styles here
export const CalendarWrapper = styled.div`
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: white; /* Custom color */
    color: black; /* Button text color */
    border: none; /* Remove default border */
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow:hover{
    background-color: #0056b3; /* Darker shade on hover */
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:after {
    color: white; /* Text color for active date */
    background: #1e2e55;
    border-radius: 20px; /* Optional: make the button rounded */
  }

  .react-calendar__tile--active:hover {
    background-color: #1e2e5580; /* Darker color on hover */
  }
`;

const Appointment = () => {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [scheduledAt, setScheduledAt] = useState<Date>(new Date());
  const [start, setStart] = useState("08:00");
  const [end, setEnd] = useState("09:00");
  const [notes, setNotes] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const location = useLocation();

  const miDate = new Date();
  let atualDate = new Date().getDate();
  miDate.setDate(atualDate + 1);

  const US_State = new US_States();

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const handleZip = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleStreet = (e: any) => {
    setStreet(e.target.value);
  };

  const handleStart = (value: any) => {
    setStart(value);
  };

  const handleEnd = (value: any) => {
    setEnd(value);
  };

  const handleNotes = (e: any) => {
    setNotes(e.target.value);
  };

  const handleSubmit = async () => {
    if (!invalidInput()) {
      setToggleSpinner(true);

      const client: ClientModel = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        zip: zipCode,
        city: city,
        state: state,
        street: street,
        service: ClientServices.APPOINTMENT,
      };

      var clientId = 0;

      await ApiService.post<any>("frontend/client", client)
        .then((cl) => {
          if (cl.error && cl.existingClient) clientId = cl.existingClient.id;
          else clientId = cl.id;
        })
        .catch(() => {
          AlertService.error(ToastMessages.APPOINTMENT_FAILED);
          setToggleSpinner(false);
        });

      const payload: AppointmentModel = {
        scheduledAt: scheduledAt,
        initialTime: start,
        finalTime: end,
        notes: notes,
        clientId: clientId,
      };

      await ApiService.post<any>("frontend/appointment", payload)
        .then(async (res) => {
          if (res) {
            AlertService.succes(ToastMessages.APPOINTMENT_SENT);
            await SmsService.send(
              phone,
              "Appointment Schedule",
              start,
              end,
              scheduledAt
            );
            await EmailService.sendEmailToUser(
              firstName,
              email,
              "Appointment Schedule",
              scheduledAt,
              start,
              end,
              notes
            ).then(() => {
              setFirstName("");
              setLastName("");
              setEmail("");
              setPhone("");
              setZipCode("");
              setStreet("");
              setCity("");
              setState("");
              setNotes("");
            });
            setSubmitted(true);
            setTimeout(() => {
              gtag_report_conversion(location.pathname + location.search);
            }, 2000);
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.APPOINTMENT_FAILED_TO_SEND);
        });
      setToggleSpinner(false);
    }
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      email.indexOf("@") === -1 ||
      email.indexOf(".") === -1 ||
      phone === "" ||
      zipCode === "" ||
      street === "" ||
      scheduledAt === undefined ||
      start === "" ||
      start === null ||
      end === "" ||
      end === null ||
      toggleSpinner
    );
  };

  return (
    <>
      <Helmet>
        <title>Schedule Appointment - Setup Remodeling</title>
        <meta
          name="description"
          content="Schedule a Consultation: Book your appointment with our expert team. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/appointment" />
      </Helmet>
      <div className={classes.appointmentContainer}>
        {!submitted ? (
          <Card raised className={classes.appointmentCard}>
            <div className={classes.titleDiv}>
              <Typography className={classes.title}>
                Schedule Appointment
              </Typography>
              <Typography className={classes.description}>
                Ready to reimagine your space with expert advice and
                personalized plans. Effortless scheduling for exceptional
                results. Book your appointment now.
              </Typography>
            </div>
            <CardContent>
              <div className={classes.cardContent}>
                <div
                  className={`calendar-container ${classes.calendarContainer}`}
                >
                  <CalendarWrapper>
                    <Calendar
                      className={classes.calendar}
                      calendarType="US"
                      value={scheduledAt}
                      minDate={miDate}
                      tileDisabled={({ date }) => date.getDay() === 6} //disabled on saturdays
                      onChange={(date: any) => setScheduledAt(date)}
                    />
                  </CalendarWrapper>
                </div>
                <div className={classes.inputDiv}>
                  <div className={classes.inputGroup}>
                    <input
                      type="text"
                      placeholder="Your first name..."
                      className={classes.input}
                      onChange={handleFirstName}
                      value={firstName}
                    />
                    <input
                      type="text"
                      placeholder="Your last name..."
                      className={classes.input}
                      onChange={handleLastName}
                      value={lastName}
                    />
                  </div>
                  <input
                    type="email"
                    placeholder="Your email..."
                    className={classes.input}
                    onChange={handleEmail}
                    value={email}
                  />
                  <div className={classes.inputGroup}>
                    <div className={classes.input}>
                      <NumberFormatter
                        defaultCountry="USA"
                        placeholder="Your phone..."
                        value={phone}
                        getValue={(n: string) => handlePhone(n)}
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Zip code..."
                      className={classes.input}
                      maxLength={5}
                      onChange={handleZip}
                      value={zipCode}
                    />
                  </div>
                  {city && state ? (
                    <Typography className={classes.city}>
                      {city}, {state}
                    </Typography>
                  ) : null}
                  <input
                    className={classes.input}
                    type="text"
                    placeholder="Street..."
                    onChange={handleStreet}
                    value={street}
                  />
                  <div className={classes.inputGroup}>
                    <TimePicker
                      className={classes.input}
                      onChange={handleStart}
                      value={start}
                      clockIcon={null}
                      shouldOpenClock={() => false}
                      format="h:mm a" // This will display time in 12-hour format with AM/PM
                    />
                    <TimePicker
                      className={classes.input}
                      onChange={handleEnd}
                      value={end}
                      clockIcon={null}
                      shouldOpenClock={() => false}
                      format="h:mm a" // This will display time in 12-hour format with AM/PM
                    />
                  </div>
                  <textarea
                    placeholder="Add some notes..."
                    className={classes.textarea}
                    onChange={handleNotes}
                    value={notes}
                  />
                </div>
              </div>
              <Button
                className={classes.button}
                disabled={invalidInput()}
                onClick={handleSubmit}
              >
                Schedule Now
              </Button>
            </CardContent>
          </Card>
        ) : (
          <ThanksPage service="appointment" />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Appointment;
