import React, { useState } from "react";
import { Button, Card, Typography } from "@material-ui/core";
import useStyles from "./styles";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { DarkGrey, PrimaryColor } from "../../styles/colors";

const CookiesSettings = ({ handleAccept }: any) => {
  const classes = useStyles();
  const [strict, setStrict] = useState(true);
  const [performance, setPerformance] = useState(true);
  const [target, setTarget] = useState(true);
  const [functionality, setFunctionality] = useState(true);

  const handleStrictCookie = () => {
    setStrict(!strict);
  };

  const handlePerformanceCookie = () => {
    setPerformance(!performance);
  };
  const handleTargetCookie = () => {
    setTarget(!target);
  };
  const handleFunctionalityCookie = () => {
    setFunctionality(!functionality);
  };

  return (
    <Card className={classes.middleContainer}>
      <Typography className={classes.title}>
        This website uses cookies
      </Typography>
      <Typography className={classes.textSettings}>
        This website uses cookies to improve user experience. By using our
        website, you consent to all cookies in accordance with our Cookie
        Policy.
      </Typography>
      <div className={classes.checklist}>
        <div className={classes.toggleDiv}>
          {strict ? (
            <Button
              style={{ color: PrimaryColor }}
              onClick={handleStrictCookie}
            >
              <ToggleOnIcon />
            </Button>
          ) : (
            <Button style={{ color: DarkGrey }} onClick={handleStrictCookie}>
              <ToggleOffIcon />
            </Button>
          )}
          <Typography className={classes.cookieName}>
            Stricktly Necessary Cookies
          </Typography>
        </div>
        <div className={classes.toggleDiv}>
          {performance ? (
            <Button
              style={{ color: PrimaryColor }}
              onClick={handlePerformanceCookie}
            >
              <ToggleOnIcon />
            </Button>
          ) : (
            <Button
              style={{ color: DarkGrey }}
              onClick={handlePerformanceCookie}
            >
              <ToggleOffIcon />
            </Button>
          )}{" "}
          <Typography className={classes.cookieName}>Performance</Typography>
        </div>
        <div className={classes.toggleDiv}>
          {target ? (
            <Button
              style={{ color: PrimaryColor }}
              onClick={handleTargetCookie}
            >
              <ToggleOnIcon />
            </Button>
          ) : (
            <Button style={{ color: DarkGrey }} onClick={handleTargetCookie}>
              <ToggleOffIcon />
            </Button>
          )}{" "}
          <Typography className={classes.cookieName}>Target</Typography>
        </div>
        <div className={classes.toggleDiv}>
          {functionality ? (
            <Button
              style={{ color: PrimaryColor }}
              onClick={handleFunctionalityCookie}
            >
              <ToggleOnIcon />
            </Button>
          ) : (
            <Button
              style={{ color: DarkGrey }}
              onClick={handleFunctionalityCookie}
            >
              <ToggleOffIcon />
            </Button>
          )}{" "}
          <Typography className={classes.cookieName}>Functionality</Typography>
        </div>
      </div>
      <div className={classes.buttonSettingDiv}>
        <Button
          className={classes.declineButton}
          onClick={() => handleAccept()}
        >
          Decline
        </Button>
        <Button className={classes.acceptButton} onClick={() => handleAccept()}>
          Accept
        </Button>
      </div>
    </Card>
  );
};

export default CookiesSettings;
