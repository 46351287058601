import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Spinner from "../../shared/components/loader/Spinner";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Pathnames from "../../model/enum/Pathnames";
import UserModel from "../../model/user";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import ApiService from "../../service/ApiService";
import Logo from "../../assets/main.png";
import useStyles from "./styles";
import "./Login.scss";

const ActivationAccount = () => {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");
  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [errorText, setErrortext] = useState("");
  const [toggleError, setToggleError] = useState(false);
  const [user, setUser] = useState<UserModel>();
  const location = useLocation();

  let queryParams = location.search.split("&");
  let userId = queryParams[0].split("=")[1]; // Get the userId from the query param
  let email = queryParams[1].split("=")[1]; // Get the email from the query param

  useEffect(() => {
    const fetchUser = async () => {
      await ApiService.get<UserModel>(
        `${DatabaseEntities.USERS}/${userId}`
      ).then((data: UserModel) => {
        setUser(data);
      });
    };

    if (!user) fetchUser();
  }, [user]);

  const handleActivationAccount = async (): Promise<boolean> => {
    var result = false;

    if (user && email) {
      try {
        const payload: UserModel = {
          id: user.id,
          username: user.username,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          deleted: false,
          phone: user.phone,
          roleId: user.roleId,
          city: user.city,
          state: user.state,
          street: user.street,
          zip: user.zip,
          activated: true,
        };

        await ApiService.put<UserModel>(
          `${DatabaseEntities.USERS}/${userId}`,
          payload
        ).then((res) => {
          if (res) result = true;
        });
      } catch (error: any) {
        console.log(error.message);
      }
    } else {
      AlertService.error(ToastMessages.LOGIN_FAILED);
    }

    return result;
  };

  const submit = async () => {
    if (invalidInput() && !user) return;

    if (currentPassword === newPassword || newPassword !== password) {
      setErrortext("Invalid password");
      setToggleError(true);
      return;
    }

    setToggleError(false);
    setToggleSpinner(true);

    await handleActivationAccount().then(async (res: boolean) => {
      if (res) {
        const payload = {
          oldPassword: currentPassword,
          newPassword: newPassword,
        };

        //Handle pwd change
        await ApiService.put<UserModel>(
          `${DatabaseEntities.USERS}/updatePassword/${userId}`,
          payload
        ).then(() => {
          //TODO: add string to ToasMessages
          AlertService.succes("Password updated sucessfully");
          setInterval(() => {
            setToggleSpinner(false);
            window.open(Pathnames.DASHBOARD, "_self");
            setErrortext("");
          }, 3000);
        }).catch((error) => {
          console.log(error);
          setToggleSpinner(false);
        });
      }
    });
    setToggleSpinner(false);
  };

  const invalidInput = (): boolean => {
    return (
      currentPassword === "" ||
      newPassword === "" ||
      password === "" ||
      currentPassword.length < 5 ||
      newPassword.length < 5 ||
      password.length < 5
    );
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!invalidInput()) submit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  return (
    <div className={classes.login}>
      {toggleSpinner && <Spinner />}
      <Card className={classes.activationCard}>
        <img src={Logo} className={classes.logo} alt="logo" />
        <CardContent className={classes.container}>
          <div className={classes.activationHeader}>
            <Typography className={classes.boldText}>
              Change password
            </Typography>
            <Typography>
              Welcome to setup remodeling {user?.firstName}. To start of, reset
              your password down below
            </Typography>
          </div>
          <div className="form__group">
            <div className={classes.inputDiv}>
              <input
                type={viewCurrentPassword ? "text" : "password"}
                className={classes.emptyInput}
                placeholder="**********"
                name="current_password"
                id="current_password"
                required
                value={currentPassword}
                onChange={(event: any) =>
                  setCurrentPassword(event.target.value)
                }
              />
              <label className="form__label">Current password</label>
              {viewCurrentPassword ? (
                <Button
                  onClick={() => setViewCurrentPassword(!viewCurrentPassword)}
                >
                  <VisibilityOffIcon />
                </Button>
              ) : (
                <Button
                  onClick={() => setViewCurrentPassword(!viewCurrentPassword)}
                >
                  <VisibilityIcon />
                </Button>
              )}
            </div>
          </div>
          <div className="form__group">
            <div className={classes.inputDiv}>
              <input
                type={viewNewPassword ? "text" : "password"}
                className={classes.emptyInput}
                placeholder="**********"
                name="new_password"
                id="new_password"
                required
                value={newPassword}
                onChange={(event: any) => setNewPassword(event.target.value)}
              />
              <label className="form__label">New password</label>
              {viewNewPassword ? (
                <Button onClick={() => setViewNewPassword(!viewNewPassword)}>
                  <VisibilityOffIcon />
                </Button>
              ) : (
                <Button onClick={() => setViewNewPassword(!viewNewPassword)}>
                  <VisibilityIcon />
                </Button>
              )}
            </div>
          </div>
          <div className="form__group">
            <div className={classes.inputDiv}>
              <input
                type={viewPassword ? "text" : "password"}
                className={classes.emptyInput}
                placeholder="**********"
                name="password"
                id="password"
                required
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
              />
              <label className="form__label">Confirm password</label>
              {viewPassword ? (
                <Button onClick={() => setViewPassword(!viewPassword)}>
                  <VisibilityOffIcon />
                </Button>
              ) : (
                <Button onClick={() => setViewPassword(!viewPassword)}>
                  <VisibilityIcon />
                </Button>
              )}
            </div>
          </div>
          {toggleError && <Typography>{errorText}</Typography>}
          <button
            className={classes.button}
            onClick={submit}
            disabled={invalidInput()}
          >
            Confirm
          </button>
        </CardContent>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default ActivationAccount;
