import { makeStyles } from "@material-ui/core/styles";
import {
  LightOrange,
  Orange,
  PrimaryColor,
  White,
  BackgroundBlue,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  financeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  financeCard: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    "@media (max-width: 550px)": {
      width: "90% !important",
      //marginTop: "30%",
      marginTop: "120px",
    },
    "@media (min-width: 1536px)": {
      width: "60% !important",
    },
  },
  mediaDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "3rem",
    "@media (max-width: 550px)": {
      flexDirection: "column",
      padding: "0",
    },
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    alignItems: "start",
    marginLeft: "5%",
    "@media (max-width: 550px)": {
      alignItems: "center",
      width: "100%",
    },
  },
  secondContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    "@media (max-width: 550px)": {
      width: "90%",
    },
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: PrimaryColor,
  },
  description: {
    width: "80%",
    marginTop: "5%",
    fontSize: "1.3rem",
    textAlign: "start",
  },
  serviceDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: "5%",
  },
  text: {
    fontSize: "1.3rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
  hts_button: {
    width: "12rem",
    height: "2.5rem",
    marginTop: "10%",
    borderRadius: "5px",
    border: "none",
    background: Orange,
    color: White,
    fontSize: "1rem",
    cursor: "pointer",
    "&:hover": {
      background: LightOrange,
    },
    "@media (max-width: 550px)": {
      marginTop: "0%",
      width: "5rem",
      fontSize: ".8rem",
      marginTop: "5%",
    },
    "@media (max-width: 820px)": {
      width: "8rem",
      fontSize: ".8rem",
    },
  },
  image: {
    width: "110%",
    height: "auto",
  },
  frameVideo: {
    width: "100%",
    height: "300px",
    "@media (max-width: 550px)": {
      width: "100%",
      height: "350px",
      marginBottom: "5%",
    },
  },
}));
