export default class ToastMessages {
    //Success
    static readonly CREATED = "Created successfully";
    static readonly DELETED = "Deleted successfully";
    static readonly UPDATED = "Updated successfully";
    static readonly FILE_UPLOADED = "File uploaded successfully";
    static readonly REQUEST_SENT = "Request quote sent successfully";
    static readonly APPOINTMENT_CREATED = "Appointment created successfully";
    static readonly USER_CREATED = "User created successfully";
    static readonly EMAIL_SENT = "Email sent successfully";
    static readonly REVIEW_CREATED = "Review created successfully";
    static readonly APPOINTMENT_SENT = "Appointment request sent successfully";
    static readonly MESSAGE_SENT = "Message sent successfully";
    static readonly ADDED_TO_CALENDAR = "Event added to calendar";
    static readonly LOGGED_IN = "Logged in successfully, Welcome!";

    //Error
    static readonly CREATED_FAILED = "Insert failed";
    static readonly DELETE_FAILED = "Delete failed";
    static readonly UPDATE_FAILED = "Update failed";
    static readonly FAILED_UPLOAD = "Failed to upload file";
    static readonly READ_FAILED = "Cannot get items";
    static readonly REQUEST_FAILED = "Error sending request quote!";
    static readonly APPOINTMENT_FAILED = "Error creating appointment!";
    static readonly EMAIL_FAILED = "Failed to send email";
    static readonly APPOINTMENT_FAILED_TO_SEND = "Error sending appointment request!";
    static readonly MESSAGE_FAILED_TO_SEND = "Error sending message!";
    static readonly CLIENT_FAILED = "Failed to create client";
    static readonly GOOGLE_CALENDAR_FAILED = "Failed to add event to calendar, please login again";
    static readonly LOGIN_FAILED = "Username or password invalid";

    //Warning
    static readonly SELECT_FILE = "Please select a file";
    static readonly INVALID_LOGIN_FORM = "Please fill in the form correctly";

    //Info
    static readonly MISSING_INFO = "Missing some informations";
    static readonly NO_CHANGES = "No changes to save";
}