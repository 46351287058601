export default class Categories {
  
  static readonly kitchen = [
    {
      name: "CABINETS",
      percentage: 0.40,
    },
    {
      name: "COUNTERTOP",
      percentage: 0.22,
    },
    {
      name: "FAUCETS, SINK & PLUMBING",
      percentage: 0.03,
    },
    {
      name: "FLOORING",
      percentage: 0.04,
    },
    {
      name: "LIGHTING & ELECTRICAL",
      percentage: 0.03,
    },
    {
      name: "WALL TREATMENT & TRIM",
      percentage: 0.01,
    },
    {
      name: "LABOR & CONTINGENCY",
      percentage: 0.27,
    },
  ];

  static readonly bathroom = [
    {
      name: "CABINET / SINK",
      percentage: 0.06,
    },
    {
      name: "SHOWER BUILD",
      percentage: 0.18,
    },
    {
      name: "TOILET & HARDWARE",
      percentage: 0.04,
    },
    {
      name: "PLUMBING & ELECTRICAL",
      percentage: 0.15,
    },
    {
      name: "FLOORING",
      percentage: 0.12,
    },
    {
      name: "WALL TREATMENT & TRIM",
      percentage: 0.05,
    },
    {
      name: "LABOR & CONTINGENCY",
      percentage: 0.40,
    },
  ];
}
