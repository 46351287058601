import { makeStyles } from "@material-ui/core/styles";
import { BackgroundBlue, DarkBlueText } from "../../shared/styles/colors";

export default makeStyles(() => ({
  review: {
    display: "flex",
    flexDirection: "column",
    marginTop: "7.5rem",
    background: BackgroundBlue,
    width: "100%",
    "@media (max-width: 640px)": {
      marginTop: "5%",
    },
    "@media (min-width: 1536px)": {
      marginTop: "6%",
    },
  },
  title: {
    font: "verdana, sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: DarkBlueText,
    marginTop: "5%",
  },
  mapContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  map: {
    width: "100vw",
    height: "70vh",
    "@media (max-width: 640px)": {
      width: "90%",
    },
  },
  markerContent: {
    display: "flex",
    flexDirection: "row",
    width: "110%",
    justifyContent: "space-between",
    marginBottom: "5%",
    marginLeft: "-5%",
  },
  markerLocation: {
    display: "flex",
    flexDirection: "row",
  },
  reviewsList: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    background: BackgroundBlue,
    "@media (max-width: 640px)": {
      //width: "40% !important",
    },
  },
}));
