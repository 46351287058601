import React from 'react'
import './Spinner.css'

const Spinner = () => {
  return (
    <div className='spinner-div d-flex justify-content-center align-items-center'>
      <div className="spinner"></div>
    </div>
    )
}

export default Spinner