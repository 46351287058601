export default class ServiceClass {
  kitchen: string = "Kitchen";
  bathroom: string = "Bathroom";
  painting: string = "Painting";
  staining: string = "Staining";
  flooring: string = "Flooring";
  plumbing: string = "Plumbing";
  washingPressure: string = "Washing pressure";
  windows: string = "Windows";
  doors: string = "Doors";
  walls: string = "Walls";
  ceilings: string = "Ceilings";
  carpentry: string = "Carpentry";
  drywall: string = "Drywall";
  handyman: string = "Handyman";
  replacements: string = "Replacements";
  repairs: string = "Repairs";
  porch_and_decks: string = "Porch & Decks";
  fence: string = "Fence";
  general_remodeling: string = "General Remodeling";
  additions: string = "Additions";
  other: string = "Other";
  framing: string = "Framing";
  homepage: string = "Homepage";
  basement: string = "Basement";
  sheetrock: string = "Sheetrock";

  services: string[] = [
    this.kitchen,
    this.bathroom,
    this.painting,
    this.staining,
    this.flooring,
    this.plumbing,
    this.washingPressure,
    this.windows,
    this.doors,
    this.walls,
    this.ceilings,
    this.carpentry,
    this.drywall,
    this.handyman,
    this.replacements,
    this.repairs,
    this.porch_and_decks,
    this.fence,
    this.general_remodeling,
    this.additions,
    this.other,
    this.framing,
    this.homepage,
    this.basement,
    this.sheetrock
  ];
}
