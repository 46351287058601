import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  DarkGrey,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  // Dashboard
  statsContainer: {
    maxWidth: "1000px",
    marginBottom: "20px",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  meetingContainer: {
    maxWidth: "1100px",
    marginBottom: "20px",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  // Statistics
  statsCardContent: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  statsActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  selector: {
    border: "1px solid lightgrey",
    borderRadius: "5px",
    "&:focus": {
      outline: "none",
      border: "1px solid #1e2e55",
    },
  },
  // Mettings
  container: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: "100%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "50%",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: DarkGrey,
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  name: {
    width: "100%",
    textAlign: "start",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  calendarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    "@media (max-width: 640px)": {
      width: "100%",
      marginTop: "5%",
      marginBottom: "5%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    border: "none",
    "& div, button": {
      borderRadius: "20px",
    },
    "@media (max-width: 640px)": {
      padding: 0,
    },
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "50%",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    marginTop: "50px",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
}));
