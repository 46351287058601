import db, { dbF, storage } from "../firebase";
import {
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  OrderByDirection,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

const MAXRANGE = 10000;
//TODO add try catch to methods and report logs and errors

export default class FirebaseService {
  /**
   * Add new entry to a DB table
   * @param payload
   * @param service
   * @returns Id
   */
  static create = async (payload: any, service: string) => {
    let id = getRandomNumbers(randomNumberInRange(0, MAXRANGE - 1));
    const docRef = doc(db, service, id.toString());
    payload.Id = id;
    await setDoc(docRef, payload);

    return id.toString();
  };

  /**
   * Query the DB with orderBy
   * @param path
   * @param element
   * @param order
   * @returns
   */
  static queryOrder = async (
    path: string,
    element: string,
    order: OrderByDirection | undefined = "desc"
  ): Promise<[]> => {
    var data: any = [];

    const q = query(collection(db, path), orderBy(element, order));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      data.push(doc.data());
    });

    return data;
  };

  /**
   * Query the DB with the a condition
   * @param service database table
   * @param filter column
   * @param condition == < >
   * @param filter2 value
   * @returns
   */
  static queryCondition = async (
    service: string,
    filter: string,
    condition: any = "==",
    filter2: string | number | boolean
  ) => {
    var data: any = [];

    const q = query(collection(db, service), where(filter, condition, filter2));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      data.push(doc.data());
    });

    return data;
  };

  /**
   * Read from the database
   * @param path DB table
   * @returns
   */
  static read = async (path: string): Promise<[]> => {
    var data: any = [];

    const querySnapshot = await getDocs(collection(db, path));
    querySnapshot.forEach((doc: any) => {
      data.push(doc.data());
    });

    return data;
  };

  /**
   * Update item on the DB
   * @param service
   * @param payload
   */
  static update = async (service: string, payload: any) => {
    const userDoc = doc(dbF, service, payload.Id.toString());
    await updateDoc(userDoc, payload);
  };

  /**
   * Delete item on the DB
   * @param service
   * @param id
   */
  static delete = async (service: string, id: any) => {
    const userDoc = doc(dbF, service, id.toString());
    await deleteDoc(userDoc);
  };

  /**
   * Read from the firebase Storage
   * @param path
   * @returns
   */
  static getImages = async (path: string): Promise<[]> => {
    const listRef = ref(storage, path);
    var data: any = [];

    await listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((x: string) => {
            data.push(x);
          });
        });
      })
      .catch((error: any) => {
        console.log(error); //TODO: add to errors table
      });

    return data;
  };

  /**
   * Update file on the firebase Storage
   * @param file
   * @param fileName
   * @param directory
   * @returns
   */
  static uploadFile = async (
    file: any,
    fileName: string,
    directory: string
  ) => {
    const imageRef = ref(storage, `${directory}/${fileName}`);
    const snapshot = await uploadBytes(imageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  };

  /**
   * Delete the file on firestore
   * @param filePath 
   * @returns 
   */
  static deleteFile = async (filePath: string): Promise<boolean> => {
    try {
      // Create a reference to the file to delete
      const fileRef = ref(storage, filePath);

      // Delete the file
      await deleteObject(fileRef);
      console.log(`Successfully deleted file: ${filePath}`);
      return true;
    } catch (error) {
      console.error("Error deleting file from Firebase Storage:", error);
    }
    return false;
  };

  /**
   * Check if the given email exists on selected table
   * @param param
   * @param service
   * @returns
   */
  static emailExists = async (
    param: string | number,
    service: string
  ): Promise<any> => {
    var data: any = null;
    await this.queryCondition(service, "Email", "==", param).then(
      (doc: any) => {
        data = doc[0];
      }
    );

    return data;
  };
}

/**
 * Pick random number between ranges
 * @param min
 * @param max
 * @returns
 */
function randomNumberInRange(min: number, max: number): number {
  //  get number between min and max
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generate random array of numbers
 * @param pos
 * @returns
 */
function getRandomNumbers(pos: number): number {
  var array = new Uint32Array(MAXRANGE);
  window.crypto.getRandomValues(array);

  return array[pos];
}
