import { Button, Toolbar } from "@material-ui/core";
import Pathnames from "../../model/enum/Pathnames";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NotificationIcon from "../../assets/admin/notifications-icon.svg";
import GoogleMeetIcon from "../../assets/admin/google-meet-icon.svg";
import PhoneIcon from "../../assets/admin/phone-icon.svg";
import ProfileIcon from "../../assets/admin/profile-icon.svg";
import ActivitiesIcon from "../../assets/admin/recent-activities-icon.svg";
import useStyles from "./styles";

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.appBar}>
        <Toolbar className={classes.toolBarDiv}>
          <div className={classes.inputGroup}>
            <input
              type="text"
              placeholder="Search..."
              className={classes.searchInput}
            />
            <Button>
              <SearchIcon className={classes.searchButton} />
            </Button>
          </div>
          <div className={classes.leftDiv}>
            {/* TODO: include it in upcoming versions */}
            {/* <div className={classes.callDiv}>
                  <Button>
                    <img src={GoogleMeetIcon} alt="meet icon" />{" "}
                  </Button>
                  <Button>
                    <img src={PhoneIcon} alt="phone icon" />
                  </Button>
                </div> */}
            <div className={classes.otherDiv}>
              <Button>
                <img src={ActivitiesIcon} alt="activities icon" />
              </Button>
              <Button>
                <img src={NotificationIcon} alt="notification icon" />
              </Button>
              <Button onClick={() => navigate(Pathnames.PROFILE)}>
                <img src={ProfileIcon} alt="profile icon" />
              </Button>
            </div>
          </div>
        </Toolbar>
      </div>
    </>
  );
};

export default Navbar;
