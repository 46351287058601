import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { Spinner } from "../../components";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import Status from "../../model/enum/StatusClass";
import MessageModel from "../../model/message";
import ClientModel from "../../model/client";
import StatusModel from "../../model/status";
import EmailService from "../../service/EmailService";
import AlertService from "../../service/AlertService";
import SmsService from "../../service/SmsService";
import ToastMessages from "../../model/enum/ToastMessages";
import DeleteModal from "../modal/DeleteModal";
import EditIcon from "../../assets/admin/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import ApiService from "../../service/ApiService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ContactModal = ({ contact, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = contact != null;
  const id = edit ? contact.id : 0;
  const [client, setClient] = useState(edit ? contact.Client : null);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [text, setText] = useState(edit ? contact.text : null);
  const [status, setStatus] = useState(edit ? contact.Status : null);
  const [statusList, setStatusList] = useState<StatusModel[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(true);
  const [notify, setNotify] = useState(false);

  ///Get clients list and client full info from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          setClients(data);
        }
      );

      setToggleSpinner(false);
    };

    if (clients.length <= 0 && open) fetchData();
  }, [open, clients]);

  //Get status list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<StatusModel[]>(
        `${DatabaseEntities.STATUS}?type=General`
      ).then((data: StatusModel[]) => {
        setStatusList(data);
        setToggleSpinner(false);
      });
    };

    if (statusList.length <= 0 && open) fetchData();
  }, [open, statusList]);

  const handleClient = (e: any) => {
    let value = e.target.value;
    var clt = clients.find((x) => x.id == value);
    setClient(clt);
  };

  const handleMessage = (e: any) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const handleStatus = (e: any) => {
    let value = e.target.value;
    var stt = statusList.find((x) => x.id == value);
    setStatus(stt);
  };

  const handleNotification = () => {
    setNotify((notify) => !notify);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: MessageModel = {
      id: id,
      text: text,
      clientId: client.id,
      statusId: status.id,
    };

    if (edit) {
      await handleUpdate(payload)
        .then(async () => {
          if (notify) {
            if (client && client.firstName && client.email && client.phone) {
              if (status.name === Status.ACCEPTED) {
                await SmsService.send(client.phone, "Contact Accepted");
                await EmailService.sendEmailToUser(
                  client.firstName,
                  client.email,
                  "Contact Accepted"
                );
              } else if (status.name === Status.CANCELED) {
                await SmsService.send(client.phone, "Contact Denied");
                await EmailService.sendEmailToUser(
                  client.firstName,
                  client.email,
                  "Contact Denied"
                );
              }
            }
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.UPDATE_FAILED);
        });
    }
  };

  const invalidInput = () => {
    return text === "" || !client || !status;
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Contact
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <DeleteModal item={contact} handleDelete={handleDelete} />
              ) : (
                <Typography className={classes.title}>
                  Add contact message
                </Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            {edit && <hr />}
            <form className={classes.form}>
              <select
                className={classes.input}
                onChange={handleClient}
                value={client?.id}
              >
                <option value="">Select Client</option>
                {clients.map((usr: ClientModel) => {
                  return (
                    <option key={usr.id} value={usr.id}>
                      {usr.firstName} {usr.lastName}
                    </option>
                  );
                })}
              </select>
              <select
                className={classes.input}
                onChange={handleStatus}
                value={status?.id}
              >
                <option value="">Status</option>
                {statusList.map((stts) => {
                  return (
                    <option key={stts.id} value={stts.id}>
                      {stts.name}
                    </option>
                  );
                })}
              </select>
              <textarea
                className={classes.textarea}
                value={text}
                onChange={handleMessage}
                placeholder="Message"
              />
            </form>
            <div className={classes.inputGroup}>
              <input
                type="checkbox"
                className={classes.checkbox}
                onChange={handleNotification}
                checked={notify}
              />
              <Typography className={classes.checkboxtext}>
                Send notification{" "}
              </Typography>
            </div>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={register}
                disabled={invalidInput()}
              >
                Save
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default ContactModal;
