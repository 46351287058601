import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  //container: {
  //   display: "flex",
  //   flexDirection: "column",
  // },
  appBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
    position: "relative",
    border: "none",
    background: "inherit",
    WebkitTapHighlightColor: "transparent",
    opacity: 1,
    // "@media (max-width: 640px)": {
    //   position: "fixed",
    // },
},
  toolBarDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // background: "rgba(214, 214, 214, .3)",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    width: "400px",
    border: "1px solid lightgrey",
    borderRadius: "8px",
    color: "rgba(217, 217, 217, 1)",
    marginRight: "20px",
  },
  searchInput: {
    width: "100%",
    border: "none",
    padding: "8px 16px 8px 16px",
    borderRadius: "8px 0px 0px 8px",
    color: "rgba(117, 117, 117, 1)",
  },
  searchButton: {
    color: "rgba(117, 117, 117, 1)",
  },
  leftDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  callDiv: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
    borderRadius: "5px",
    justifyContent: "space-around",
    background: "rgba(209, 213, 222, 1)",
  },
  otherDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
}));
