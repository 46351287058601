import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Error from "../../assets/error.png";
import useStyles from "./styles";
import { Card, CardMedia } from "@mui/material";

const Errorpage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Error Page - Setup Remodeling";
  });

  return (
    <div className={classes.errorPage}>
      <Card raised className={classes.card}>
        <CardMedia>
          <img
            src={Error}
            className={classes.img}
            alt="error page"
            loading="lazy"
          />
          <Typography className="mt-4 mb-4">
            An error occurred, please return to{" "}
            <Link to="/" className={classes.text}>
              Homepage
            </Link>
          </Typography>
        </CardMedia>
      </Card>
    </div>
  );
};

export default Errorpage;
