import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { Spinner } from "../../components";
import Status from "../../model/enum/StatusClass";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import ToastMessages from "../../model/enum/ToastMessages";
import AlertService from "../../service/AlertService";
import EmailService from "../../service/EmailService";
import SmsService from "../../service/SmsService";
import ApiService from "../../service/ApiService";
import RequestModel from "../../model/request";
import ServiceModel from "../../model/service";
import ClientModel from "../../model/client";
import ClientModal from "../client/ClientModal";
import DeleteModal from "../modal/DeleteModal";
import ServiceTypeModel from "../../model/serviceType";
import StatusModel from "../../model/status";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/admin/edit-icon.svg";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const RequestModal = ({
  request,
  handleUpdate,
  handleCreate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = request != null;
  const id = edit ? request.id : 0;
  const [client, setClient] = useState(edit ? request.Client : null);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [serviceId, setServiceId] = useState(edit ? request.Service?.id : null);
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [serviceTypeId, setServiceTypeId] = useState(
    edit ? request.ServiceType?.id : null
  );
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeModel[]>([]);
  const [description, setDescription] = useState(
    edit ? request.description : null
  );
  const [status, setStatus] = useState(edit ? request?.Status : null);
  const [statusList, setStatusList] = useState<StatusModel[]>([]);
  const [notify, setNotify] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all services from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ServiceModel[]>(DatabaseEntities.SERVICES).then(
        (data: ServiceModel[]) => {
          setServices(data);
        }
      );

      await ApiService.get<ServiceTypeModel[]>(
        DatabaseEntities.SERVICE_TYPES
      ).then((data: ServiceTypeModel[]) => {
        setServiceTypes(data);
      });
    };

    if (services.length <= 0 && open) fetchData();
  }, [open, services]);

  //Get clients list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          setClients(data);
        }
      );

      setToggleSpinner(false);
    };

    if (clients.length <= 0 && open) fetchData();
  }, [open, clients]);

  //Get status list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<StatusModel[]>(
        `${DatabaseEntities.STATUS}?type=General`
      ).then((data: StatusModel[]) => {
        setStatusList(data);
        setToggleSpinner(false);
      });
    };

    if (statusList.length <= 0 && open) fetchData();
  }, [open, statusList]);

  const handleClient = (e: any) => {
    let value = e.target.value;
    var clt = clients.find((x) => x.id == value);
    setClient(clt);
  };

  const handleServiceType = (e: any) => {
    e.preventDefault();
    setServiceTypeId(e.target.value);
  };

  const handleService = (e: any) => {
    e.preventDefault();
    setServiceId(e.target.value);
  };

  const handleDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const handleStatus = (e: any) => {
    let value = e.target.value;
    var stt = statusList.find((x) => x.id == value);
    setStatus(stt);
  };

  const handleNotification = () => {
    setNotify((notify) => !notify);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: RequestModel = {
      id: id,
      serviceId: serviceId,
      serviceTypeId: serviceTypeId,
      description: description,
      clientId: client.id,
      statusId: status.id,
    };

    if (edit) {
      await handleUpdate(payload)
        .then(async () => {
          if (notify) {
            await handleEmailAndSmsSend();
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.REQUEST_FAILED);
        });
    } else {
      await handleCreate(payload)
        .then(async () => {
          if (notify) {
            await handleEmailAndSmsSend();
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.REQUEST_FAILED);
        });
    }
    handleClose();
  };

  const handleEmailAndSmsSend = async () => {
    let type: string = "";

    switch (status.name) {
      case Status.ACCEPTED:
        type = "Request Accepted";
        break;
      case Status.CANCELED:
        type = "Request Denied";
        break;
      case Status.COMPLETED:
        type = "Service Completed";
        break;
      default:
        break;
    }

    if (type != "") {
      await SmsService.send(client.phone, type)
        .then(() => {
          AlertService.succes("Sms notification sent");
        })
        .catch(() => {
          AlertService.error("Sms notification not sent");
        });
      await EmailService.sendEmailToUser(client.firstName, client.email, type)
        .then(() => {
          AlertService.succes("Email notification sent");
        })
        .catch(() => {
          AlertService.error("Email notification not sent");
        });
    }
  };

  const invalidInput = () => {
    return !serviceTypeId || !serviceId || !description || !status;
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Request
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <DeleteModal item={request} handleDelete={handleDelete} />
              ) : (
                <Typography className={classes.title}>Request quote</Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            {edit && <hr />}
            <form className={classes.form}>
              <div className={classes.inputGroup}>
                <select
                  className={classes.input}
                  onChange={handleClient}
                  value={client?.id}
                >
                  <option value="">Select Client</option>
                  {clients.map((clt: ClientModel) => {
                    return (
                      <option key={clt.id} value={clt.id}>
                        {clt.firstName} {clt.lastName}
                      </option>
                    );
                  })}
                </select>
                <ClientModal
                  externalService={true}
                  resetList={() => setClients([])}
                  setClient={(clt: ClientModel) => setClient(clt)}
                />
              </div>
              <div className={classes.inputGroup}>
                <select
                  className={classes.input}
                  onChange={handleServiceType}
                  value={serviceTypeId}
                >
                  <option value="">Select a service</option>
                  {services.map((service: ServiceModel) => {
                    return (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    );
                  })}
                </select>
                <select
                  className={classes.input}
                  onChange={handleService}
                  value={serviceId}
                >
                  <option value="">Select a project</option>
                  {serviceTypes.map((serviceType: ServiceTypeModel) => {
                    return (
                      <option key={serviceType.id} value={serviceType.id}>
                        {serviceType.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <textarea
                className={classes.textarea}
                name="description"
                placeholder="Description..."
                onChange={handleDescription}
                defaultValue={description}
              />
              <select
                className={classes.input}
                onChange={handleStatus}
                value={status?.id}
              >
                <option value="">Status</option>
                {statusList.map((stts) => {
                  return (
                    <option key={stts.id} value={stts.id}>
                      {stts.name}
                    </option>
                  );
                })}
              </select>
              <div className={classes.inputGroup}>
                <input
                  type="checkbox"
                  className={classes.checkbox}
                  onChange={handleNotification}
                  checked={notify}
                />
                <Typography className={classes.checkboxtext}>
                  Send notification{" "}
                </Typography>
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={() => register()}
                disabled={invalidInput()}
              >
                Save
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default RequestModal;
