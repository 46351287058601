import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import FaqModel from "../../model/faq";
import CloseIcon from "@mui/icons-material/Close";
import Status from "../../model/enum/StatusClass";
import EditIcon from "../../assets/admin/edit-icon.svg";
import DeleteModal from "../modal/DeleteModal";
import { DarkGrey, PrimaryColor } from "../../shared/styles/colors";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  order: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const FaqModal = ({ faq, handleUpdate, handleCreate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = faq != null;
  const [question, setQuestion] = useState(edit ? faq.question : "");
  const [answer, setAnswer] = useState(edit ? faq.answer : "");
  const [available, setAvailable] = useState(edit ? faq.available : true);

  const handleQuestion = (e: any) => {
    setQuestion(e.target.value);
  };

  const handleAnswer = (e: any) => {
    setAnswer(e.target.value);
  };

  const handleAvailability = (e: any) => {
    setAvailable(!available);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: FaqModel = {
      question: question,
      answer: answer,
      available: available,
    };

    if (edit) {
      payload.id = faq.id;
      await handleUpdate(payload);
    } else {
      await handleCreate(payload);
    }
    handleClose();
  };

  const invalidInput = () => {
    return question === "" || answer === "";
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Faq
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <Typography className={classes.title}>
                <DeleteModal item={faq} handleDelete={handleDelete} />
              </Typography>
            ) : (
              <Typography className={classes.title}>Add new FAQ</Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          {edit && <hr />}
          <form className={classes.form}>
            <input
              className={classes.input}
              value={question}
              placeholder="Question"
              onChange={handleQuestion}
            />
            <textarea
              className={classes.textarea}
              placeholder="Answer"
              onChange={handleAnswer}
              value={answer}
            />
            <div className={classes.availabilityDiv}>
              <Typography>{Status.AVAILABLE}:</Typography>
              {available ? (
                <Button
                  sx={{ color: PrimaryColor }}
                  onClick={handleAvailability}
                >
                  <ToggleOnIcon />
                </Button>
              ) : (
                <Button sx={{ color: DarkGrey }} onClick={handleAvailability}>
                  <ToggleOffIcon />
                </Button>
              )}
            </div>
          </form>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={register}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FaqModal;
