import React, { useEffect, useState } from "react";
import { Card, CardMedia, Typography } from "@material-ui/core";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Orange } from "../../shared/styles/colors";
import useStyles from "./styles";
import HFS_Logo from "../../assets/finance/HFS.png";
import { Helmet } from "react-helmet-async";
import Spinner from "../../shared/components/loader/Spinner";

const Finance = () => {
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = HFS_Logo; // Use the imported image

    // Set the imageLoaded state to true once the image has finished loading
    img.onload = () => {
      setImageLoaded(true);
    };

    // Cleanup function to avoid memory leaks
    return () => {
      img.onload = null;
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const services = [
    "No Stage Funding",
    "No Equity / No Appraisal",
    "No Money Down",
    "Direct-to-Consumer Funded",
    "Personal Loans",
    "Terms Up To 20 Years",
  ];

  return (
    <>
      <Helmet>
        <title>Finance - Setup Remodeling</title>
        <meta
          name="description"
          content="Get financing with HFS. And start your dream project with Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/financing" />
      </Helmet>
      {imageLoaded ? (
        <div className={classes.financeContainer}>
          <Card raised className={classes.financeCard}>
            <CardMedia className={classes.mediaDiv}>
              <div className={classes.firstContainer}>
                <Typography className={classes.title}>Finance</Typography>
                <Typography className={classes.description}>
                  Start building your success story today with HFS convenient
                  financing solutions.
                </Typography>
                <div className={classes.serviceDiv}>
                  {services.map((serv) => {
                    return (
                      <div className={classes.row}>
                        <ArrowRightIcon style={{ color: Orange }} />
                        <Typography className={classes.text}>{serv}</Typography>
                      </div>
                    );
                  })}
                </div>
                <button
                  className={classes.hts_button}
                  onClick={() =>
                    window.open(
                      "https://www.hfsfinancial.net/promo/61c4d270533c2c0021523644"
                    )
                  }
                >
                  Get Financing
                </button>
              </div>
              <div className={classes.secondContainer}>
                <img
                  src={HFS_Logo}
                  className={classes.image}
                  alt="HFS financial"
                />
                <iframe
                  className={classes.frameVideo}
                  src="https://www.youtube.com/embed/39wtQLL8YF4?si=i0py-F84AGf7GRKz"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                ></iframe>
              </div>
            </CardMedia>
          </Card>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Finance;
