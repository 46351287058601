import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import Client from "../../model/client";
import EmailService from "../../service/EmailService";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import GmailIcon from "../../assets/admin/google-gmail-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { DarkGrey } from "../../shared/styles/colors";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const inputStyle = {
  width: "600px",
};

const textAreaStyle = {
  width: "600px",
  height: "200px",
};

interface Props {
  name: string | undefined;
  email: string | undefined;
}

const SendEmailModal = ({ name, email }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const handleMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSend = async () => {
    if (!name || !email || !title || !message) {
      alert(ToastMessages.MISSING_INFO);
      return;
    }

    const user: Client = {
      firstName: name,
      email: email,
    };

    await EmailService.sendCustomEmail(user, title, message)
      .then(() => {
        AlertService.succes(ToastMessages.EMAIL_SENT);
        handleClose();
      })
      .catch(() => {
        AlertService.error(ToastMessages.EMAIL_FAILED);
      });
  };

  const invalidInput = ():boolean => {
    return message === "" || email === "" || title === "";
  };

  return (
    <div>
      <Btn className={classes.button} onClick={handleOpen}>
        <img src={GmailIcon} />
        <Typography style={{ marginLeft: "5%" }}> Email costumer</Typography>
      </Btn>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Send custom email</Typography>
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.form}>
            <Typography className={classes.label}>Title</Typography>
            <input
              type="text"
              className={classes.inputBordered}
              placeholder="Add subject..."
              onChange={handleTitle}
              value={title}
              style={inputStyle}
            />
            <Typography className={classes.label}>Message</Typography>
            <textarea
              className={classes.textareaBordered}
              placeholder="Add content..."
              onChange={handleMessage}
              value={message}
              style={textAreaStyle}
            />
          </div>
          <div>
            <Typography>Do you want to send the email to: </Typography>
            <Typography className={classes.label}>{email} ?</Typography>
          </div>
          <div className={classes.buttonGroup}>
            <Button color="success" onClick={handleSend} disabled={invalidInput()}>
              Yes
            </Button>
            <Button sx={{color: DarkGrey}} onClick={handleClose}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SendEmailModal;
