import { makeStyles } from "@material-ui/core/styles";

import { DarkGrey, PrimaryColor, LightPrimaryColor, BackgroundBlue, White } from "../../../styles/colors";

export default makeStyles(() => ({
    budget_title: {
        color: PrimaryColor,
        fontSize: '1.2rem',
        textAlign: 'start',
        width: '100%',
        marginTop: '1rem'
    },
    categories: {
        width: '100%',
        marginTop: '5%',
        textAlign: 'left',
        padding: '0.3rem',
        fontSize: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 640px)': {
            flexDirection: 'row'
        }
    },
    percentage: {
        color: PrimaryColor,
        textAlign: 'start',
        marginLeft: '10%'
    },
    name: {
        color: DarkGrey,
        fontWeight: 'lighter',
        width: '70%',
        '@media (max-width: 640px)': {
            width: '30%',
        }
    },
    value: {
        color: DarkGrey,
        fontWeight: '700',
        textAlign: 'right',
        '@media (max-width: 640px)': {
            width: '50%',
            textAlign: 'center',
        }
    },
    totalValue: {
        color: PrimaryColor,
        fontSize: '1.2rem',
        fontWeight: '700',
        textAlign: 'right',
        '@media (max-width: 640px)': {
            width: '50%',
            textAlign: 'center',
        }
    },
    request_button: {
        borderRadius: '.5rem',
        width: '100%',
        border: 'none',
        padding: '10px',
        marginTop: '5%',
        background: PrimaryColor,
        color: White,
        cursor: 'pointer',
        '&:hover': {
            background: LightPrimaryColor,
        },
        '@media (max-width: 640px)': {
            fontSize: '0.7rem',
        },
    },
    request_button2: {
        borderRadius: '.5rem',
        width: '100%',
        border: '5px solid',
        padding: '10px',
        backgroundColor: White,
        color: PrimaryColor,
        cursor: 'pointer',
        '&:hover': {
            background: LightPrimaryColor,
            color: White
        },
        '@media (max-width: 640px)': {
            fontSize: '0.7rem',
        },
    },
}));