import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  InputColor,
  LightGrey,
  DarkGrey,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  container: {
    display: "grid",
    gap: "10px",
    padding: "10px 40px 0px 40px",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  },
  addItemGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    //height: "280px",
    border: "1px dashed #999999",
    borderRadius: "5.85px",
    "@media (max-width: 640px)": {
      width: "80%"
    }
},
itemGrid: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    //height: "280px",
    border: "1px solid #999999",
    borderRadius: "5.85px",
    padding: "10px",
    "@media (max-width: 640px)": {
      width: "80%"
    }
  },
  itemHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  img: {
    width: "40px",
  },
  priceText: {
    color: PrimaryColor,
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  itemBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    marginTop: "10px"
  },
  title: {
    color: "#333333",
    fontWeight: 700,
  },
  description: {
    fontSize: ".9rem",
    textAlign: "start"
  },
  icon: {
    width: "20px"
  },
  time: {
    fontSize: ".8rem",
  },
  button: {
    marginTop: "10px",
    background: PrimaryColor,
    color: White,
    "&:hover": {
        color: LightGrey
    }
  }
}));
