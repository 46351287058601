import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  LightPrimaryColor,
  White,
  InputColor,
  Black,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  //used for css on post to gallery and to review
  reviewContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  reviewSelect: {
    marginTop: "5%",
  },
  //New layout
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    alignItems: "start",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "50% !important",
    alignItems: "start",
    marginTop: "5%",
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    padding: "10px",
    "@media (max-width: 640px)": {
      width: "100% !important",
      fontSize: "1rem",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textarea: {
    width: "95% !important",
    height: "14rem",
    borderRadius: "5px",
    fontSize: "1rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    padding: "10px",
    marginTop: "5%",
    "@media (max-width: 640px)": {
      height: "5rem",
      width: "100% !important",
      marginTop: "5%",
      margin: 0,
      padding: 0,
    },
  },
  textareaReview: {
    width: "95% !important",
    height: "4rem",
    borderRadius: "5px",
    fontSize: "1rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    padding: "10px",
    marginTop: "5%",
    "@media (max-width: 640px)": {
      height: "5rem",
      width: "100% !important",
      marginTop: "5%",
      margin: 0,
      padding: 0,
    },
  },
  uploadInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "17.5rem",
    border: "2px #818181 dashed",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      height: "8rem"
    },
  },
  uploadInputReview: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "32rem",
    border: "2px #818181 dashed",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      height: "10rem"
    },
  },
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  image: {
    width: "30px",
    height: "30px",
  },
  compressionDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "100%",
  },
  headerButton: {
    color: White,
    background: PrimaryColor,
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  calendarContainer: {
    width: "100%",
    marginTop: "5%",
    "@media (max-width: 640px)": {
      //   width: "100%",
      //   marginBottom: "5%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    "& div, button": {
      borderRadius: "20px",
      color: PrimaryColor,
      "&:disabled": {
        color: Black,
      },
      // '&:active': {
      //     background: PrimaryColor,
      //     color: White
      // }
    },
    "@media (max-width: 640px)": {
      padding: 0,
    },
  },
}));
