export default class US_States {
  //State, abbreviation, capital, range, cities
  us_states = [
    {
      name: "Alabama",
      abbreviation: "AL",
      capital: "Montgomery",
      minRange: 35004,
      maxRange: 36925,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Alaska",
      abbreviation: "AK",
      capital: "Juneau",
      minRange: 99501,
      maxRange: 99950,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
      capital: "Phoenix",
      minRange: 85001,
      maxRange: 86556,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
      capital: "Little Rock",
      minRange: 71601,
      maxRange: 72959,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "California",
      abbreviation: "CA",
      capital: "Sacramento",
      minRange: 90001,
      maxRange: 96162,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Colorado",
      abbreviation: "CO",
      capital: "Denver",
      minRange: 80001,
      maxRange: 81658,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
      capital: "Hartford",
      minRange: 6001,
      maxRange: 6389,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Delaware",
      abbreviation: "DE",
      capital: "Dover",
      minRange: 19701,
      maxRange: 19980,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Florida",
      abbreviation: "FL",
      capital: "Tallahassee",
      minRange: 32004,
      maxRange: 34997,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Georgia",
      abbreviation: "GA",
      capital: "Atlanta",
      minRange: 30001,
      maxRange: 31999,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
      capital: "Honolulu",
      minRange: 96701,
      maxRange: 96898,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Idaho",
      abbreviation: "ID",
      capital: "Boise",
      minRange: 83201,
      maxRange: 83876,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Illinois",
      abbreviation: "IL",
      capital: "Springfield",
      minRange: 60001,
      maxRange: 62999,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Indiana",
      abbreviation: "IN",
      capital: "Indianapolis",
      minRange: 46001,
      maxRange: 47997,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Iowa",
      abbreviation: "IA",
      capital: "Des Moines",
      minRange: 50001,
      maxRange: 52809,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Kansas",
      abbreviation: "KS",
      capital: "Topeka",
      minRange: 66002,
      maxRange: 67954,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
      capital: "Frankfort",
      minRange: 40003,
      maxRange: 42788,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
      capital: "Baton Rouge",
      minRange: 70001,
      maxRange: 71232,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Maine",
      abbreviation: "ME",
      capital: "Augusta",
      minRange: 3901,
      maxRange: 4992,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Maryland",
      abbreviation: "MD",
      capital: "Annapolis",
      minRange: 20331,
      maxRange: 21930,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
      capital: "Boston",
      minRange: 1001,
      maxRange: 2791,
      cities: [
        {
          zip: 1001,
          name: "Agawam"
        },
        {
          zip: 1002,
          name: "Amherst"
        },
        {
          zip: 1003,
          name: "Amherst"
        },
        {
          zip: 1005,
          name: "Barre"
        },
        {
          zip: 1007,
          name: "Belchertown"
        },
        {
          zip: 1008,
          name: "Blandford"
        },
        {
          zip: 1009,
          name: "Bondsville"
        },
        {
          zip: 1010,
          name: "Brimfield"
        },
        {
          zip: 1011,
          name: "Chester"
        },
        {
          zip: 1012,
          name: "Chesterfield"
        },
        {
          zip: 1013,
          name: "Chicopee"
        },
        {
          zip: 1020,
          name: "Chicopee"
        },
        {
          zip: 1022,
          name: "Chicopee"
        },
        {
          zip: 1026,
          name: "Cummington"
        },
        {
          zip: 1027,
          name: "Easthampton"
        },
        {
          zip: 1028,
          name: "East Longmeadow"
        },
        {
          zip: 1029,
          name: "East Otis"
        },
        {
          zip: 1030,
          name: "Feeding Hills"
        },
        {
          zip: 1031,
          name: "Gilbertville"
        },
        {
          zip: 1032,
          name: "Goshen"
        },
        {
          zip: 1033,
          name: "Granby"
        },
        {
          zip: 1034,
          name: "Granville"
        },
        {
          zip: 1035,
          name: "Hadley"
        },
        {
          zip: 1036,
          name: "Hampden"
        },
        {
          zip: 1037,
          name: "Hardwick"
        },
        {
          zip: 1038,
          name: "Hatfield"
        },
        {
          zip: 1039,
          name: "Haydenville"
        },
        {
          zip: 1040,
          name: "Holyoke"
        },
        {
          zip: 1050,
          name: "Huntington"
        },
        {
          zip: 1053,
          name: "Leeds"
        },
        {
          zip: 1054,
          name: "Leverett"
        },
        {
          zip: 1056,
          name: "Ludlow"
        },
        {
          zip: 1057,
          name: "Monson"
        },
        {
          zip: 1060,
          name: "Northampton"
        },
        {
          zip: 1062,
          name: "Florence"
        },
        {
          zip: 1063,
          name: "Northampton"
        },
        {
          zip: 1068,
          name: "Oakham"
        },
        {
          zip: 1069,
          name: "Palmer"
        },
        {
          zip: 1070,
          name: "Plainfield"
        },
        {
          zip: 1071,
          name: "Russell"
        },
        {
          zip: 1072,
          name: "Shutesbury"
        },
        {
          zip: 1073,
          name: "Southampton"
        },
        {
          zip: 1074,
          name: "South Barre"
        },
        {
          zip: 1075,
          name: "South Hadley"
        },
        {
          zip: 1077,
          name: "Southwick"
        },
        {
          zip: 1079,
          name: "Thorndike"
        },
        {
          zip: 1080,
          name: "Three Rivers"
        },
        {
          zip: 1081,
          name: "Wales"
        },
        {
          zip: 1082,
          name: "Ware"
        },
        {
          zip: 1083,
          name: "Warren"
        },
        {
          zip: 1085,
          name: "Westfield"
        },
        {
          zip: 1086,
          name: "Westfield"
        },
        {
          zip: 1088,
          name: "West Hatfield"
        },
        {
          zip: 1089,
          name: "West Springfield"
        },
        {
          zip: 1092,
          name: "West Warren"
        },
        {
          zip: 1093,
          name: "Whately"
        },
        {
          zip: 1094,
          name: "Wheelwright"
        },
        {
          zip: 1095,
          name: "Wilbraham"
        },
        {
          zip: 1096,
          name: "Williamsburg"
        },
        {
          zip: 1098,
          name: "Worthington"
        },
        {
          zip: 1103,
          name: "Springfield"
        },
        {
          zip: 1104,
          name: "Springfield"
        },
        {
          zip: 1105,
          name: "Springfield"
        },
        {
          zip: 1106,
          name: "Longmeadow"
        },
        {
          zip: 1107,
          name: "Springfield"
        },
        {
          zip: 1108,
          name: "Springfield"
        },
        {
          zip: 1109,
          name: "Springfield"
        },
        {
          zip: 1118,
          name: "Springfield"
        },
        {
          zip: 1119,
          name: "Springfield"
        },
        {
          zip: 1128,
          name: "Springfield"
        },
        {
          zip: 1129,
          name: "Springfield"
        },
        {
          zip: 1151,
          name: "Indian Orchard"
        },
        {
          zip: 1201,
          name: "Pittsfield"
        },
        {
          zip: 1220,
          name: "Adams"
        },
        {
          zip: 1222,
          name: "Ashley Falls"
        },
        {
          zip: 1223,
          name: "Becket"
        },
        {
          zip: 1225,
          name: "Cheshire"
        },
        {
          zip: 1226,
          name: "Dalton"
        },
        {
          zip: 1230,
          name: "Great Barrington"
        },
        {
          zip: 1235,
          name: "Hinsdale"
        },
        {
          zip: 1236,
          name: "Housatonic"
        },
        {
          zip: 1237,
          name: "Lanesboro"
        },
        {
          zip: 1238,
          name: "Lee"
        },
        {
          zip: 1240,
          name: "Lenox"
        },
        {
          zip: 1242,
          name: "Lenox Dale"
        },
        {
          zip: 1243,
          name: "Middlefield"
        },
        {
          zip: 1245,
          name: "Monterey"
        },
        {
          zip: 1247,
          name: "North Adams"
        },
        {
          zip: 1253,
          name: "Otis"
        },
        {
          zip: 1254,
          name: "Richmond"
        },
        {
          zip: 1255,
          name: "Sandisfield"
        },
        {
          zip: 1256,
          name: "Savoy"
        },
        {
          zip: 1257,
          name: "Sheffield"
        },
        {
          zip: 1258,
          name: "South Egremont"
        },
        {
          zip: 1259,
          name: "Southfield"
        },
        {
          zip: 1262,
          name: "Stockbridge"
        },
        {
          zip: 1264,
          name: "Tyringham"
        },
        {
          zip: 1266,
          name: "West Stockbridge"
        },
        {
          zip: 1267,
          name: "Williamstown"
        },
        {
          zip: 1270,
          name: "Windsor"
        },
        {
          zip: 1301,
          name: "Greenfield"
        },
        {
          zip: 1330,
          name: "Ashfield"
        },
        {
          zip: 1331,
          name: "Athol"
        },
        {
          zip: 1337,
          name: "Bernardston"
        },
        {
          zip: 1339,
          name: "Charlemont"
        },
        {
          zip: 1340,
          name: "Colrain"
        },
        {
          zip: 1341,
          name: "Conway"
        },
        {
          zip: 1342,
          name: "Deerfield"
        },
        {
          zip: 1344,
          name: "Erving"
        },
        {
          zip: 1346,
          name: "Heath"
        },
        {
          zip: 1349,
          name: "Turners Falls"
        },
        {
          zip: 1351,
          name: "Montague"
        },
        {
          zip: 1354,
          name: "Northfield"
        },
        {
          zip: 1355,
          name: "New Salem"
        },
        {
          zip: 1360,
          name: "Northfield"
        },
        {
          zip: 1364,
          name: "Orange"
        },
        {
          zip: 1366,
          name: "Petersham"
        },
        {
          zip: 1367,
          name: "Rowe"
        },
        {
          zip: 1368,
          name: "Royalston"
        },
        {
          zip: 1370,
          name: "Shelburne Falls"
        },
        {
          zip: 1373,
          name: "South Deerfield"
        },
        {
          zip: 1375,
          name: "Sunderland"
        },
        {
          zip: 1376,
          name: "Turners Falls"
        },
        {
          zip: 1378,
          name: "Warwick"
        },
        {
          zip: 1379,
          name: "Wendell"
        },
        {
          zip: 1420,
          name: "Fitchburg"
        },
        {
          zip: 1430,
          name: "Ashburnham"
        },
        {
          zip: 1431,
          name: "Ashby"
        },
        {
          zip: 1432,
          name: "Ayer"
        },
        {
          zip: 1436,
          name: "Baldwinville"
        },
        {
          zip: 1438,
          name: "East Templeton"
        },
        {
          zip: 1440,
          name: "Gardner"
        },
        {
          zip: 1450,
          name: "Groton"
        },
        {
          zip: 1451,
          name: "Harvard"
        },
        {
          zip: 1452,
          name: "Hubbardston"
        },
        {
          zip: 1453,
          name: "Leominster"
        },
        {
          zip: 1460,
          name: "Littleton"
        },
        {
          zip: 1462,
          name: "Lunenburg"
        },
        {
          zip: 1463,
          name: "Pepperell"
        },
        {
          zip: 1464,
          name: "Shirley"
        },
        {
          zip: 1467,
          name: "Still River"
        },
        {
          zip: 1468,
          name: "Templeton"
        },
        {
          zip: 1469,
          name: "Townsend"
        },
        {
          zip: 1473,
          name: "Westminster"
        },
        {
          zip: 1474,
          name: "West Townsend"
        },
        {
          zip: 1475,
          name: "Winchendon"
        },
        {
          zip: 1501,
          name: "Auburn"
        },
        {
          zip: 1503,
          name: "Berlin"
        },
        {
          zip: 1504,
          name: "Blackstone"
        },
        {
          zip: 1505,
          name: "Boylston"
        },
        {
          zip: 1506,
          name: "Brookfield"
        },
        {
          zip: 1507,
          name: "Charlton"
        },
        {
          zip: 1510,
          name: "Clinton"
        },
        {
          zip: 1515,
          name: "East Brookfield"
        },
        {
          zip: 1516,
          name: "Douglas"
        },
        {
          zip: 1518,
          name: "Fiskdale"
        },
        {
          zip: 1519,
          name: "Grafton"
        },
        {
          zip: 1520,
          name: "Holden"
        },
        {
          zip: 1521,
          name: "Holland"
        },
        {
          zip: 1522,
          name: "Jefferson"
        },
        {
          zip: 1523,
          name: "Lancaster"
        },
        {
          zip: 1524,
          name: "Leicester"
        },
        {
          zip: 1525,
          name: "Linwood"
        },
        {
          zip: 1527,
          name: "Millbury"
        },
        {
          zip: 1529,
          name: "Millville"
        },
        {
          zip: 1531,
          name: "New Braintree"
        },
        {
          zip: 1532,
          name: "Northborough"
        },
        {
          zip: 1534,
          name: "Northbridge"
        },
        {
          zip: 1535,
          name: "North Brookfield"
        },
        {
          zip: 1536,
          name: "North Grafton"
        },
        {
          zip: 1537,
          name: "North Oxford"
        },
        {
          zip: 1540,
          name: "Oxford"
        },
        {
          zip: 1541,
          name: "Princeton"
        },
        {
          zip: 1542,
          name: "Rochdale"
        },
        {
          zip: 1543,
          name: "Rutland"
        },
        {
          zip: 1545,
          name: "Shrewsbury"
        },
        {
          zip: 1550,
          name: "Southbridge"
        },
        {
          zip: 1560,
          name: "South Grafton"
        },
        {
          zip: 1562,
          name: "Spencer"
        },
        {
          zip: 1564,
          name: "Sterling"
        },
        {
          zip: 1566,
          name: "Sturbridge"
        },
        {
          zip: 1568,
          name: "Upton"
        },
        {
          zip: 1569,
          name: "Uxbridge"
        },
        {
          zip: 1570,
          name: "Webster"
        },
        {
          zip: 1571,
          name: "Dudley"
        },
        {
          zip: 1581,
          name: "Westborough"
        },
        {
          zip: 1583,
          name: "West Boylston"
        },
        {
          zip: 1585,
          name: "West Brookfield"
        },
        {
          zip: 1588,
          name: "Whitinsville"
        },
        {
          zip: 1590,
          name: "Sutton"
        },
        {
          zip: 1602,
          name: "Worcester"
        },
        {
          zip: 1603,
          name: "Worcester"
        },
        {
          zip: 1604,
          name: "Worcester"
        },
        {
          zip: 1605,
          name: "Worcester"
        },
        {
          zip: 1606,
          name: "Worcester"
        },
        {
          zip: 1607,
          name: "Worcester"
        },
        {
          zip: 1608,
          name: "Worcester"
        },
        {
          zip: 1609,
          name: "Worcester"
        },
        {
          zip: 1610,
          name: "Worcester"
        },
        {
          zip: 1611,
          name: "Cherry Valley"
        },
        {
          zip: 1612,
          name: "Paxton"
        },
        {
          zip: 1701,
          name: "Framingham"
        },
        {
          zip: 1702,
          name: "Framingham"
        },
        {
          zip: 1718,
          name: "Village Of Nagog Woods"
        },
        {
          zip: 1719,
          name: "Boxborough"
        },
        {
          zip: 1720,
          name: "Acton"
        },
        {
          zip: 1721,
          name: "Ashland"
        },
        {
          zip: 1730,
          name: "Bedford"
        },
        {
          zip: 1731,
          name: "Hanscom Afb"
        },
        {
          zip: 1740,
          name: "Bolton"
        },
        {
          zip: 1741,
          name: "Carlisle"
        },
        {
          zip: 1742,
          name: "Concord"
        },
        {
          zip: 1745,
          name: "Fayville"
        },
        {
          zip: 1746,
          name: "Holliston"
        },
        {
          zip: 1747,
          name: "Hopedale"
        },
        {
          zip: 1748,
          name: "Hopkinton"
        },
        {
          zip: 1749,
          name: "Hudson"
        },
        {
          zip: 1752,
          name: "Marlborough"
        },
        {
          zip: 1754,
          name: "Maynard"
        },
        {
          zip: 1756,
          name: "Mendon"
        },
        {
          zip: 1757,
          name: "Milford"
        },
        {
          zip: 1760,
          name: "Natick"
        },
        {
          zip: 1770,
          name: "Sherborn"
        },
        {
          zip: 1772,
          name: "Southborough"
        },
        {
          zip: 1773,
          name: "Lincoln"
        },
        {
          zip: 1775,
          name: "Stow"
        },
        {
          zip: 1776,
          name: "Sudbury"
        },
        {
          zip: 1778,
          name: "Wayland"
        },
        {
          zip: 1801,
          name: "Woburn"
        },
        {
          zip: 1803,
          name: "Burlington"
        },
        {
          zip: 1810,
          name: "Andover"
        },
        {
          zip: 1821,
          name: "Billerica"
        },
        {
          zip: 1824,
          name: "Chelmsford"
        },
        {
          zip: 1826,
          name: "Dracut"
        },
        {
          zip: 1827,
          name: "Dunstable"
        },
        {
          zip: 1830,
          name: "Haverhill"
        },
        {
          zip: 1832,
          name: "Haverhill"
        },
        {
          zip: 1833,
          name: "Georgetown"
        },
        {
          zip: 1834,
          name: "Groveland"
        },
        {
          zip: 1835,
          name: "Haverhill"
        },
        {
          zip: 1840,
          name: "Lawrence"
        },
        {
          zip: 1841,
          name: "Lawrence"
        },
        {
          zip: 1843,
          name: "Lawrence"
        },
        {
          zip: 1844,
          name: "Methuen"
        },
        {
          zip: 1845,
          name: "North Andover"
        },
        {
          zip: 1850,
          name: "Lowell"
        },
        {
          zip: 1851,
          name: "Lowell"
        },
        {
          zip: 1852,
          name: "Lowell"
        },
        {
          zip: 1854,
          name: "Lowell"
        },
        {
          zip: 1860,
          name: "Merrimac"
        },
        {
          zip: 1862,
          name: "North Billerica"
        },
        {
          zip: 1863,
          name: "North Chelmsford"
        },
        {
          zip: 1864,
          name: "North Reading"
        },
        {
          zip: 1867,
          name: "Reading"
        },
        {
          zip: 1876,
          name: "Tewksbury"
        },
        {
          zip: 1879,
          name: "Tyngsboro"
        },
        {
          zip: 1880,
          name: "Wakefield"
        },
        {
          zip: 1886,
          name: "Westford"
        },
        {
          zip: 1887,
          name: "Wilmington"
        },
        {
          zip: 1890,
          name: "Winchester"
        },
        {
          zip: 1901,
          name: "Lynn"
        },
        {
          zip: 1902,
          name: "Lynn"
        },
        {
          zip: 1904,
          name: "Lynn"
        },
        {
          zip: 1905,
          name: "Lynn"
        },
        {
          zip: 1906,
          name: "Saugus"
        },
        {
          zip: 1907,
          name: "Swampscott"
        },
        {
          zip: 1908,
          name: "Nahant"
        },
        {
          zip: 1913,
          name: "Amesbury"
        },
        {
          zip: 1915,
          name: "Beverly"
        },
        {
          zip: 1921,
          name: "Boxford"
        },
        {
          zip: 1922,
          name: "Byfield"
        },
        {
          zip: 1923,
          name: "Danvers"
        },
        {
          zip: 1929,
          name: "Essex"
        },
        {
          zip: 1930,
          name: "Gloucester"
        },
        {
          zip: 1938,
          name: "Ipswich"
        },
        {
          zip: 1940,
          name: "Lynnfield"
        },
        {
          zip: 1944,
          name: "Manchester"
        },
        {
          zip: 1945,
          name: "Marblehead"
        },
        {
          zip: 1949,
          name: "Middleton"
        },
        {
          zip: 1950,
          name: "Newburyport"
        },
        {
          zip: 1951,
          name: "Newbury"
        },
        {
          zip: 1952,
          name: "Salisbury"
        },
        {
          zip: 1960,
          name: "Peabody"
        },
        {
          zip: 1966,
          name: "Rockport"
        },
        {
          zip: 1969,
          name: "Rowley"
        },
        {
          zip: 1970,
          name: "Salem"
        },
        {
          zip: 1982,
          name: "South Hamilton"
        },
        {
          zip: 1983,
          name: "Topsfield"
        },
        {
          zip: 1984,
          name: "Wenham"
        },
        {
          zip: 1985,
          name: "West Newbury"
        },
        {
          zip: 2019,
          name: "Bellingham"
        },
        {
          zip: 2021,
          name: "Canton"
        },
        {
          zip: 2025,
          name: "Cohasset"
        },
        {
          zip: 2026,
          name: "Dedham"
        },
        {
          zip: 2030,
          name: "Dover"
        },
        {
          zip: 2032,
          name: "East Walpole"
        },
        {
          zip: 2035,
          name: "Foxboro"
        },
        {
          zip: 2038,
          name: "Franklin"
        },
        {
          zip: 2043,
          name: "Hingham"
        },
        {
          zip: 2045,
          name: "Hull"
        },
        {
          zip: 2048,
          name: "Mansfield"
        },
        {
          zip: 2050,
          name: "Marshfield"
        },
        {
          zip: 2052,
          name: "Medfield"
        },
        {
          zip: 2053,
          name: "Medway"
        },
        {
          zip: 2054,
          name: "Millis"
        },
        {
          zip: 2056,
          name: "Norfolk"
        },
        {
          zip: 2061,
          name: "Norwell"
        },
        {
          zip: 2062,
          name: "Norwood"
        },
        {
          zip: 2066,
          name: "Scituate"
        },
        {
          zip: 2067,
          name: "Sharon"
        },
        {
          zip: 2071,
          name: "South Walpole"
        },
        {
          zip: 2072,
          name: "Stoughton"
        },
        {
          zip: 2081,
          name: "Walpole"
        },
        {
          zip: 2090,
          name: "Westwood"
        },
        {
          zip: 2093,
          name: "Wrentham"
        },
        {
          zip: 2108,
          name: "Boston"
        },
        {
          zip: 2109,
          name: "Boston"
        },
        {
          zip: 2110,
          name: "Boston"
        },
        {
          zip: 2111,
          name: "Boston"
        },
        {
          zip: 2113,
          name: "Boston"
        },
        {
          zip: 2114,
          name: "Boston"
        },
        {
          zip: 2115,
          name: "Boston"
        },
        {
          zip: 2116,
          name: "Boston"
        },
        {
          zip: 2118,
          name: "Boston"
        },
        {
          zip: 2119,
          name: "Boston"
        },
        {
          zip: 2120,
          name: "Boston"
        },
        {
          zip: 2121,
          name: "Boston"
        },
        {
          zip: 2122,
          name: "Boston"
        },
        {
          zip: 2124,
          name: "Boston"
        },
        {
          zip: 2125,
          name: "Boston"
        },
        {
          zip: 2126,
          name: "Mattapan"
        },
        {
          zip: 2127,
          name: "Boston"
        },
        {
          zip: 2128,
          name: "Boston"
        },
        {
          zip: 2129,
          name: "Charlestown"
        },
        {
          zip: 2130,
          name: "Jamaica Plain"
        },
        {
          zip: 2131,
          name: "Roslindale"
        },
        {
          zip: 2132,
          name: "West Roxbury"
        },
        {
          zip: 2134,
          name: "Allston"
        },
        {
          zip: 2135,
          name: "Brighton"
        },
        {
          zip: 2136,
          name: "Hyde Park"
        },
        {
          zip: 2138,
          name: "Cambridge"
        },
        {
          zip: 2139,
          name: "Cambridge"
        },
        {
          zip: 2140,
          name: "Cambridge"
        },
        {
          zip: 2141,
          name: "Cambridge"
        },
        {
          zip: 2142,
          name: "Cambridge"
        },
        {
          zip: 2143,
          name: "Somerville"
        },
        {
          zip: 2144,
          name: "Somerville"
        },
        {
          zip: 2145,
          name: "Somerville"
        },
        {
          zip: 2148,
          name: "Malden"
        },
        {
          zip: 2149,
          name: "Everett"
        },
        {
          zip: 2150,
          name: "Chelsea"
        },
        {
          zip: 2151,
          name: "Revere"
        },
        {
          zip: 2152,
          name: "Winthrop"
        },
        {
          zip: 2155,
          name: "Medford"
        },
        {
          zip: 2163,
          name: "Boston"
        },
        {
          zip: 2169,
          name: "Quincy"
        },
        {
          zip: 2170,
          name: "Quincy"
        },
        {
          zip: 2171,
          name: "Quincy"
        },
        {
          zip: 2176,
          name: "Melrose"
        },
        {
          zip: 2180,
          name: "Stoneham"
        },
        {
          zip: 2184,
          name: "Braintree"
        },
        {
          zip: 2186,
          name: "Milton"
        },
        {
          zip: 2188,
          name: "Weymouth"
        },
        {
          zip: 2189,
          name: "Weymouth"
        },
        {
          zip: 2190,
          name: "Weymouth"
        },
        {
          zip: 2191,
          name: "Weymouth"
        },
        {
          zip: 2199,
          name: "Boston"
        },
        {
          zip: 2210,
          name: "Boston"
        },
        {
          zip: 2215,
          name: "Boston"
        },
        {
          zip: 2301,
          name: "Brockton"
        },
        {
          zip: 2302,
          name: "Brockton"
        },
        {
          zip: 2322,
          name: "Avon"
        },
        {
          zip: 2324,
          name: "Bridgewater"
        },
        {
          zip: 2330,
          name: "Carver"
        },
        {
          zip: 2332,
          name: "Duxbury"
        },
        {
          zip: 2333,
          name: "East Bridgewater"
        },
        {
          zip: 2338,
          name: "Halifax"
        },
        {
          zip: 2339,
          name: "Hanover"
        },
        {
          zip: 2341,
          name: "Hanson"
        },
        {
          zip: 2343,
          name: "Holbrook"
        },
        {
          zip: 2346,
          name: "Middleboro"
        },
        {
          zip: 2347,
          name: "Lakeville"
        },
        {
          zip: 2351,
          name: "Abington"
        },
        {
          zip: 2356,
          name: "North Easton"
        },
        {
          zip: 2357,
          name: "North Easton"
        },
        {
          zip: 2359,
          name: "Pembroke"
        },
        {
          zip: 2360,
          name: "Plymouth"
        },
        {
          zip: 2364,
          name: "Kingston"
        },
        {
          zip: 2367,
          name: "Plympton"
        },
        {
          zip: 2368,
          name: "Randolph"
        },
        {
          zip: 2370,
          name: "Rockland"
        },
        {
          zip: 2375,
          name: "South Easton"
        },
        {
          zip: 2379,
          name: "West Bridgewater"
        },
        {
          zip: 2382,
          name: "Whitman"
        },
        {
          zip: 2420,
          name: "Lexington"
        },
        {
          zip: 2421,
          name: "Lexington"
        },
        {
          zip: 2445,
          name: "Brookline"
        },
        {
          zip: 2446,
          name: "Brookline"
        },
        {
          zip: 2451,
          name: "Waltham"
        },
        {
          zip: 2452,
          name: "Waltham"
        },
        {
          zip: 2453,
          name: "Waltham"
        },
        {
          zip: 2457,
          name: "Babson Park"
        },
        {
          zip: 2458,
          name: "Newton"
        },
        {
          zip: 2459,
          name: "Newton Center"
        },
        {
          zip: 2460,
          name: "Newtonville"
        },
        {
          zip: 2461,
          name: "Newton Highlands"
        },
        {
          zip: 2462,
          name: "Newton Lower Falls"
        },
        {
          zip: 2464,
          name: "Newton Upper Falls"
        },
        {
          zip: 2465,
          name: "West Newton"
        },
        {
          zip: 2466,
          name: "Auburndale"
        },
        {
          zip: 2467,
          name: "Chestnut Hill"
        },
        {
          zip: 2468,
          name: "Waban"
        },
        {
          zip: 2472,
          name: "Watertown"
        },
        {
          zip: 2474,
          name: "Arlington"
        },
        {
          zip: 2476,
          name: "Arlington"
        },
        {
          zip: 2478,
          name: "Belmont"
        },
        {
          zip: 2481,
          name: "Wellesley Hills"
        },
        {
          zip: 2482,
          name: "Wellesley"
        },
        {
          zip: 2492,
          name: "Needham"
        },
        {
          zip: 2493,
          name: "Weston"
        },
        {
          zip: 2494,
          name: "Needham Heights"
        },
        {
          zip: 2532,
          name: "Buzzards Bay"
        },
        {
          zip: 2534,
          name: "Cataumet"
        },
        {
          zip: 2535,
          name: "Chilmark"
        },
        {
          zip: 2536,
          name: "East Falmouth"
        },
        {
          zip: 2537,
          name: "East Sandwich"
        },
        {
          zip: 2538,
          name: "East Wareham"
        },
        {
          zip: 2539,
          name: "Edgartown"
        },
        {
          zip: 2540,
          name: "Falmouth"
        },
        {
          zip: 2542,
          name: "Buzzards Bay"
        },
        {
          zip: 2543,
          name: "Woods Hole"
        },
        {
          zip: 2554,
          name: "Nantucket"
        },
        {
          zip: 2556,
          name: "North Falmouth"
        },
        {
          zip: 2557,
          name: "Oak Bluffs"
        },
        {
          zip: 2558,
          name: "Onset"
        },
        {
          zip: 2559,
          name: "Pocasset"
        },
        {
          zip: 2561,
          name: "Sagamore"
        },
        {
          zip: 2562,
          name: "Sagamore Beach"
        },
        {
          zip: 2563,
          name: "Sandwich"
        },
        {
          zip: 2568,
          name: "Vineyard Haven"
        },
        {
          zip: 2571,
          name: "Wareham"
        },
        {
          zip: 2575,
          name: "West Tisbury"
        },
        {
          zip: 2576,
          name: "West Wareham"
        },
        {
          zip: 2601,
          name: "Hyannis"
        },
        {
          zip: 2630,
          name: "Barnstable"
        },
        {
          zip: 2631,
          name: "Brewster"
        },
        {
          zip: 2632,
          name: "Centerville"
        },
        {
          zip: 2633,
          name: "Chatham"
        },
        {
          zip: 2635,
          name: "Cotuit"
        },
        {
          zip: 2638,
          name: "Dennis"
        },
        {
          zip: 2639,
          name: "Dennis Port"
        },
        {
          zip: 2641,
          name: "East Dennis"
        },
        {
          zip: 2642,
          name: "Eastham"
        },
        {
          zip: 2644,
          name: "Forestdale"
        },
        {
          zip: 2645,
          name: "Harwich"
        },
        {
          zip: 2646,
          name: "Harwich Port"
        },
        {
          zip: 2648,
          name: "Marstons Mills"
        },
        {
          zip: 2649,
          name: "Mashpee"
        },
        {
          zip: 2650,
          name: "North Chatham"
        },
        {
          zip: 2652,
          name: "North Truro"
        },
        {
          zip: 2653,
          name: "Orleans"
        },
        {
          zip: 2655,
          name: "Osterville"
        },
        {
          zip: 2657,
          name: "Provincetown"
        },
        {
          zip: 2659,
          name: "South Chatham"
        },
        {
          zip: 2660,
          name: "South Dennis"
        },
        {
          zip: 2664,
          name: "South Yarmouth"
        },
        {
          zip: 2666,
          name: "Truro"
        },
        {
          zip: 2667,
          name: "Wellfleet"
        },
        {
          zip: 2668,
          name: "West Barnstable"
        },
        {
          zip: 2670,
          name: "West Dennis"
        },
        {
          zip: 2671,
          name: "West Harwich"
        },
        {
          zip: 2672,
          name: "West Hyannisport"
        },
        {
          zip: 2673,
          name: "West Yarmouth"
        },
        {
          zip: 2675,
          name: "Yarmouth Port"
        },
        {
          zip: 2702,
          name: "Assonet"
        },
        {
          zip: 2703,
          name: "Attleboro"
        },
        {
          zip: 2715,
          name: "Dighton"
        },
        {
          zip: 2717,
          name: "East Freetown"
        },
        {
          zip: 2718,
          name: "East Taunton"
        },
        {
          zip: 2719,
          name: "Fairhaven"
        },
        {
          zip: 2720,
          name: "Fall River"
        },
        {
          zip: 2721,
          name: "Fall River"
        },
        {
          zip: 2723,
          name: "Fall River"
        },
        {
          zip: 2724,
          name: "Fall River"
        },
        {
          zip: 2725,
          name: "Somerset"
        },
        {
          zip: 2726,
          name: "Somerset"
        },
        {
          zip: 2738,
          name: "Marion"
        },
        {
          zip: 2739,
          name: "Mattapoisett"
        },
        {
          zip: 2740,
          name: "New Bedford"
        },
        {
          zip: 2743,
          name: "Acushnet"
        },
        {
          zip: 2744,
          name: "New Bedford"
        },
        {
          zip: 2745,
          name: "New Bedford"
        },
        {
          zip: 2746,
          name: "New Bedford"
        },
        {
          zip: 2747,
          name: "North Dartmouth"
        },
        {
          zip: 2748,
          name: "South Dartmouth"
        },
        {
          zip: 2760,
          name: "North Attleboro"
        },
        {
          zip: 2762,
          name: "Plainville"
        },
        {
          zip: 2763,
          name: "Attleboro Falls"
        },
        {
          zip: 2764,
          name: "North Dighton"
        },
        {
          zip: 2766,
          name: "Norton"
        },
        {
          zip: 2767,
          name: "Raynham"
        },
        {
          zip: 2769,
          name: "Rehoboth"
        },
        {
          zip: 2770,
          name: "Rochester"
        },
        {
          zip: 2771,
          name: "Seekonk"
        },
        {
          zip: 2777,
          name: "Swansea"
        },
        {
          zip: 2779,
          name: "Berkley"
        },
        {
          zip: 2780,
          name: "Taunton"
        },
        {
          zip: 2790,
          name: "Westport"
        },
        {
          zip: 2791,
          name: "Westport Point"
        }
      ],
    },
    {
      name: "Michigan",
      abbreviation: "MI",
      capital: "Lansing",
      minRange: 48001,
      maxRange: 49971,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
      capital: "Saint Paul",
      minRange: 55001,
      maxRange: 56763,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
      capital: "Jackson",
      minRange: 38601,
      maxRange: 39776,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Missouri",
      abbreviation: "MO",
      capital: "Jefferson City",
      minRange: 63001,
      maxRange: 65899,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Montana",
      abbreviation: "MT",
      capital: "Helena",
      minRange: 59001,
      maxRange: 59937,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
      capital: "Lincoln",
      minRange: 68001,
      maxRange: 69367,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Nevada",
      abbreviation: "NV",
      capital: "Carson City",
      minRange: 88901,
      maxRange: 89883,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
      capital: "Concord",
      minRange: 3031,
      maxRange: 3897,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
      capital: "Trenton",
      minRange: 7001,
      maxRange: 8989,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
      capital: "Santa Fe",
      minRange: 87001,
      maxRange: 88441,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "New York",
      abbreviation: "NY",
      capital: "Albany",
      minRange: 10001,
      maxRange: 14975,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
      capital: "Raleigh",
      minRange: 27006,
      maxRange: 28909,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
      capital: "Bismarck",
      minRange: 58001,
      maxRange: 58856,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Ohio",
      abbreviation: "OH",
      capital: "Columbus",
      minRange: 43001,
      maxRange: 45999,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
      capital: "Oklahoma City",
      minRange: 73001,
      maxRange: 73199,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Oregon",
      abbreviation: "OR",
      capital: "Salem",
      minRange: 97001,
      maxRange: 97920,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
      capital: "Harrisburg",
      minRange: 15001,
      maxRange: 19640,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
      capital: "Providence",
      minRange: 2801,
      maxRange: 2940,
      cities: [
        {
          zip: 2802,
          name: "Albion"
        },
        {
          zip: 2804,
          name: "Ashaway"
        },
        {
          zip: 2806,
          name: "Barrington"
        },
        {
          zip: 2807,
          name: "Block Island"
        },
        {
          zip: 2808,
          name: "Bradford"
        },
        {
          zip: 2809,
          name: "Bristol"
        },
        {
          zip: 2812,
          name: "Carolina"
        },
        {
          zip: 2813,
          name: "Charlestown"
        },
        {
          zip: 2814,
          name: "Chepachet"
        },
        {
          zip: 2816,
          name: "Coventry"
        },
        {
          zip: 2817,
          name: "West Greenwich"
        },
        {
          zip: 2818,
          name: "East Greenwich"
        },
        {
          zip: 2822,
          name: "Exeter"
        },
        {
          zip: 2825,
          name: "Foster"
        },
        {
          zip: 2826,
          name: "Glendale"
        },
        {
          zip: 2827,
          name: "Greene"
        },
        {
          zip: 2828,
          name: "Greenville"
        },
        {
          zip: 2830,
          name: "Harrisville"
        },
        {
          zip: 2831,
          name: "Hope"
        },
        {
          zip: 2832,
          name: "Hope Valley"
        },
        {
          zip: 2833,
          name: "Hopkinton"
        },
        {
          zip: 2835,
          name: "Jamestown"
        },
        {
          zip: 2837,
          name: "Little Compton"
        },
        {
          zip: 2838,
          name: "Manville"
        },
        {
          zip: 2839,
          name: "Mapleville"
        },
        {
          zip: 2840,
          name: "Newport"
        },
        {
          zip: 2841,
          name: "Newport"
        },
        {
          zip: 2842,
          name: "Middletown"
        },
        {
          zip: 2852,
          name: "North Kingstown"
        },
        {
          zip: 2857,
          name: "North Scituate"
        },
        {
          zip: 2858,
          name: "Oakland"
        },
        {
          zip: 2859,
          name: "Pascoag"
        },
        {
          zip: 2860,
          name: "Pawtucket"
        },
        {
          zip: 2861,
          name: "Pawtucket"
        },
        {
          zip: 2863,
          name: "Central Falls"
        },
        {
          zip: 2864,
          name: "Cumberland"
        },
        {
          zip: 2865,
          name: "Lincoln"
        },
        {
          zip: 2871,
          name: "Portsmouth"
        },
        {
          zip: 2872,
          name: "Prudence Island"
        },
        {
          zip: 2874,
          name: "Saunderstown"
        },
        {
          zip: 2875,
          name: "Shannock"
        },
        {
          zip: 2876,
          name: "Slatersville"
        },
        {
          zip: 2878,
          name: "Tiverton"
        },
        {
          zip: 2879,
          name: "Wakefield"
        },
        {
          zip: 2881,
          name: "Kingston"
        },
        {
          zip: 2882,
          name: "Narragansett"
        },
        {
          zip: 2885,
          name: "Warren"
        },
        {
          zip: 2886,
          name: "Warwick"
        },
        {
          zip: 2888,
          name: "Warwick"
        },
        {
          zip: 2889,
          name: "Warwick"
        },
        {
          zip: 2891,
          name: "Westerly"
        },
        {
          zip: 2892,
          name: "West Kingston"
        },
        {
          zip: 2893,
          name: "West Warwick"
        },
        {
          zip: 2894,
          name: "Wood River Junction"
        },
        {
          zip: 2895,
          name: "Woonsocket"
        },
        {
          zip: 2896,
          name: "North Smithfield"
        },
        {
          zip: 2898,
          name: "Wyoming"
        },
        {
          zip: 2903,
          name: "Providence"
        },
        {
          zip: 2904,
          name: "Providence"
        },
        {
          zip: 2905,
          name: "Providence"
        },
        {
          zip: 2906,
          name: "Providence"
        },
        {
          zip: 2907,
          name: "Providence"
        },
        {
          zip: 2908,
          name: "Providence"
        },
        {
          zip: 2909,
          name: "Providence"
        },
        {
          zip: 2910,
          name: "Cranston"
        },
        {
          zip: 2911,
          name: "North Providence"
        },
        {
          zip: 2912,
          name: "Providence"
        },
        {
          zip: 2914,
          name: "East Providence"
        },
        {
          zip: 2915,
          name: "Riverside"
        },
        {
          zip: 2916,
          name: "Rumford"
        },
        {
          zip: 2917,
          name: "Smithfield"
        },
        {
          zip: 2919,
          name: "Johnston"
        },
        {
          zip: 2920,
          name: "Cranston"
        },
        {
          zip: 2921,
          name: "Cranston"
        }
      ],
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
      capital: "Columbia",
      minRange: 29001,
      maxRange: 29948,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
      capital: "Pierre",
      minRange: 57001,
      maxRange: 57799,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
      capital: "Nashville",
      minRange: 37010,
      maxRange: 38589,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Texas",
      abbreviation: "TX",
      capital: "Austin",
      minRange: 73301,
      maxRange: 73301,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Utah",
      abbreviation: "UT",
      capital: "Salt Lake City",
      minRange: 84001,
      maxRange: 84784,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Vermont",
      abbreviation: "VT",
      capital: "Montpelier",
      minRange: 5001,
      maxRange: 5495,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Virginia",
      abbreviation: "VA",
      capital: "Richmond",
      minRange: 20040,
      maxRange: 20041,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Washington",
      abbreviation: "WA",
      capital: "Olympia",
      minRange: 98501,
      maxRange: 99403,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
      capital: "Charleston",
      minRange: 24701,
      maxRange: 26886,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
      capital: "Madison",
      minRange: 53001,
      maxRange: 54990,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
      capital: "Cheyenne",
      minRange: 82001,
      maxRange: 83128,
      cities: [
        {
          name: "",
          zip: 0,
        },
      ],
    },
  ];

  getStateName(zip: number): string {
    for (var i = 0; i < this.us_states.length; i++) {
      if (
        zip >= this.us_states[i].minRange &&
        zip <= this.us_states[i].maxRange
      ) {
        return this.us_states[i].name;
      }
    }
    return "";
  }

  getStateAbbreviation(zip: number): string {
    for (var i = 0; i < this.us_states.length; i++) {
      if (
        zip >= this.us_states[i].minRange &&
        zip <= this.us_states[i].maxRange
      ) {
        return this.us_states[i].abbreviation;
      }
    }
    return "";
  }

  getStateCity(zip: number): string {
    for (var i = 0; i < this.us_states.length; i++) {
      for (var j = 0; j < this.us_states[i].cities.length; j++) {
        if (
          zip == this.us_states[i].cities[j].zip
        ) {
          return this.us_states[i].cities[j].name;
        }
      }
    }
    return "";
  }

  isZipValid(zip: number): boolean {
    for (var i = 0; i < this.us_states.length; i++) {
      if (
        zip >= this.us_states[i].minRange &&
        zip <= this.us_states[i].maxRange
      ) {
        return true;
      }
    }
    return false;
  }
}
