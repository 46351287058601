import React, { useState } from "react";
import { MobileStepper } from "@mui/material";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { Step1, Step2, Step3, BreadCrumb } from "./steps/index";
import ClientModel from "../../model/client";
import ClientServices from "../../model/enum/ClientServices";
import ToastMessages from "../../model/enum/ToastMessages";
import ThanksPage from "../../shared/components/greetings/ThanksPage";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import RequestModel from "../../model/request";
import EmailService from "../../service/EmailService";
import AlertService from "../../service/AlertService";
import SmsService from "../../service/SmsService";
import ApiService from "../../service/ApiService";
import { Helmet } from "react-helmet-async";
import { gtag_report_conversion } from "../../utils/GoogleReport";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import US_States from "../../model/enum/US_States";
import useStyles from "./styles";

const Request = () => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [projects, setProjects] = useState(0);
  const [typeOfProjects, setTypeOfProjects] = useState(0);
  const [projectDescription, setProjectDescription] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const US_State = new US_States();

  const location = useLocation();

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleTypeOfProjects = (e: any) => {
    setTypeOfProjects(e.target.value);
    console.log(typeOfProjects)
  };

  const handleProjects = (e: any) => {
    setProjects(e.target.value);
    console.log(projects);
  };

  const handleProjectDescription = (e: any) => {
    setProjectDescription(e.target.value);
  };

  const handleStreetAddress = (e: any) => {
    setStreetAddress(e.target.value);
  };

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const nextStepDisabled = () => {
    switch (activeStep) {
      case 0:
        return !zipCode;
      case 1:
        return (
          typeOfProjects === 0 || projects === 0 || projectDescription === ""
        );
      case 3:
        return (
          streetAddress === "" ||
          firstName === "" ||
          lastName === "" ||
          email === "" ||
          phone === ""
        );
      default:
        return false;
    }
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      email.indexOf("@") === -1 ||
      email.indexOf(".") === -1 ||
      phone === "" ||
      streetAddress === "" ||
      state === "" ||
      !zipCode ||
      typeOfProjects === 0 ||
      projects === 0 ||
      projectDescription === "" ||
      toggleSpinner
    );
  };

  const handleSubmit = async () => {
    if (!invalidInput()) {
      setToggleSpinner(true);

      const client: ClientModel = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        zip: zipCode,
        city: city,
        state: state,
        street: streetAddress,
        service: ClientServices.APPOINTMENT,
      };

      var clientId = 0;

      await ApiService.post<any>("frontend/client", client).then((cl) => {
        if (cl.error) clientId = cl.existingClient.id;
        else clientId = cl.id;
      });

      const payload: RequestModel = {
        serviceId: projects,
        serviceTypeId: typeOfProjects,
        description: projectDescription,
        clientId: clientId,
      };
      await ApiService.post<any>("frontend/request", payload)
        .then(async () => {
          AlertService.succes(ToastMessages.REQUEST_SENT);
          await SmsService.send(phone, "Service Request");
          await EmailService.sendEmailToUser(
            firstName,
            email,
            "Service Request"
          ).then(() => {
            setZipCode("");
            setCity("");
            setState("");
            setTypeOfProjects(0);
            setProjects(0);
            setProjectDescription("");
            setStreetAddress("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setActiveStep(0);
          });
          setSubmitted(true);
          setTimeout(() => {
            gtag_report_conversion(location.pathname + location.search);
          }, 2000);
        })
        .catch(() => {
          AlertService.error(ToastMessages.REQUEST_FAILED);
        });
      setToggleSpinner(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Request Service - Setup Remodeling</title>
        <meta
          name="description"
          content="Get a free quote today with our experts and find the best solution for your dream house. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/quote" />
      </Helmet>
      <main className={classes.request}>
        {!submitted ? (
          <Card className={classes.card} raised={true}>
            <BreadCrumb step={activeStep} />
            <hr style={{ width: "200%", marginTop: "5%" }} />
            <CardContent>
              {activeStep === 0 ? (
                <Step1
                  entryZipCode={handleZipCode}
                  zipCode={zipCode}
                  city={city}
                  state={state}
                />
              ) : activeStep === 1 ? (
                <Step2
                  entryTypeOfProjects={handleTypeOfProjects}
                  typeOfProjects={typeOfProjects}
                  entryProjects={handleProjects}
                  projects={projects}
                  entryProjectDescription={handleProjectDescription}
                  projectDescription={projectDescription}
                />
              ) : activeStep === 2 ? (
                <Step3
                  entryStreetAddress={handleStreetAddress}
                  streetAddress={streetAddress}
                  entryFirstName={handleFirstName}
                  firstName={firstName}
                  entryLastName={handleLastName}
                  lastName={lastName}
                  entryEmail={handleEmail}
                  email={email}
                  entryPhone={handlePhone}
                  phone={phone}
                />
              ) : null}
            </CardContent>
            <CardActions className="d-flex row justify-content-center">
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                sx={{
                  minWidth: 900,
                  flexGrow: 1,
                  "@media (max-width: 640px)": { minWidth: 300 },
                }}
                nextButton={
                  <Button
                    size="medium"
                    onClick={handleNextStep}
                    disabled={activeStep === 2 || nextStepDisabled()}
                  >
                    <ArrowCircleRightIcon
                      className={
                        activeStep < 2 && !nextStepDisabled()
                          ? classes.breadcrumbCircular
                          : classes.breadcrumbCircularDisabled
                      }
                    />
                  </Button>
                }
                backButton={
                  <Button
                    size="medium"
                    onClick={handlePreviousStep}
                    disabled={activeStep === 0}
                  >
                    <ArrowCircleLeftIcon
                      className={
                        activeStep > 0
                          ? classes.breadcrumbCircular
                          : classes.breadcrumbCircularDisabled
                      }
                    />
                  </Button>
                }
              />
              <Button
                className={classes.button}
                disabled={invalidInput()}
                onClick={handleSubmit}
                hidden={activeStep !== 2}
              >
                Submit
              </Button>
            </CardActions>
          </Card>
        ) : (
          <ThanksPage service="request" />
        )}
        <ToastContainer />
      </main>
    </>
  );
};

export default Request;
