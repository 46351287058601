import React, { useState } from "react";
import { Button as Btn, Typography } from "@material-ui/core";
import { Button, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const DeleteUserModal = ({ handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Btn className={classes.delete} onClick={handleOpen}>
        Delete account
      </Btn>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Delete account</Typography>
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <Typography className={classes.textDelete}>
            If confirmed, the account will be permanently deleted in the
            upcoming days.
          </Typography>
          <div className={classes.buttonGroupModal}>
            <Button color="error" onClick={handleDelete}>
              Delete
            </Button>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteUserModal;
