import React from "react";
import Geocode from "react-geocode";

const apikey = process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY;

Geocode.setApiKey(apikey ? apikey : "");
Geocode.setLanguage("en");
Geocode.setRegion("us");
// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export default class GeocodeService {
  /**
   * 
   * @param location 
   * @returns 
   */
  static async fromAddressToLatLng(location: string) {
    return await Geocode.fromAddress(location);
  }

  /**
   * 
   * @param lat 
   * @param lng 
   * @returns 
   */
  static async fromLatLngtoAddress(lat: string, lng: string) {
    return await Geocode.fromLatLng(lat, lng);
  }
}