import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  InputColor,
  LightGrey,
  DarkGrey,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    marginTop: "5%",
  },
  label: {
    fontWeight: "600",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50% !important",
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "99% !important",
    alignItems: "center",
    padding: 0,
    "@media (max-width: 640px)": {
      flexDirection: "column",
      width: "100% !important",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textarea: {
    width: "95% !important",
    height: "5rem",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "6rem",
    },
  },
  checkbox: {
    accentColor: PrimaryColor,
    marginRight: "2%",
    marginLeft: "2%",
  },
  checkboxtext: {
    color: InputColor,
  },
  calendarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    marginLeft: "2%",
    "@media (max-width: 640px)": {
      width: "100%",
      marginTop: "5%",
      marginBottom: "5%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    border: "none",
    "& div, button": {
      borderRadius: "20px",
      color: PrimaryColor,
      "&:disabled": {
        color: DarkGrey,
      },
    },
    "@media (max-width: 640px)": {
      padding: 0,
    },
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "5%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  //Details
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textareaDetail: {
    width: "95% !important",
    height: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "6rem",
    },
  },
  buttonDetail: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: DarkGrey,
    background: White,
    border: "1px solid grey",
    borderRadius: "10px",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
}));
