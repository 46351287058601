import ResponseCodes from "../model/enum/ResponseCodes";
import Pathnames from "../model/enum/Pathnames";
import AlertService from "./AlertService";

// apiService.ts
export default class ApiService {
  private static baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://supple-moon-377718.uc.r.appspot.com/api"
      : "http://localhost:5001/api"; // Replace with your backend URL
  private static token = localStorage.getItem("accessToken");

  // Generic GET request
  static async get<T>(endpoint: string, token?: string): Promise<T> {
    const headers = {
      "Content-Type": "application/json",
      ...{ Authorization: `Bearer ${token ? token : this.token}` },
    };

    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: "GET",
      headers,
    });

    if (response.status === ResponseCodes.UNAUTHORIZED) {
      AlertService.error("Session expired");
      window.open(Pathnames.LOGIN, "_self");
    }

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.json();
  }

  // Generic POST request
  static async post<T>(
    endpoint: string,
    data: unknown,
    token?: string
  ): Promise<T> {
    const headers = {
      "Content-Type": "application/json",
      ...{ Authorization: `Bearer ${token ? token : this.token}` },
    };

    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify(data),
    });

    if (response.status === ResponseCodes.UNAUTHORIZED) {
      AlertService.error("Session expired");
      window.open(Pathnames.LOGIN, "_self");
    }

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.json();
  }

  // Generic PUT request
  static async put<T>(
    endpoint: string,
    data: unknown,
    token?: string
  ): Promise<T> {
    const headers = {
      "Content-Type": "application/json",
      ...{ Authorization: `Bearer ${token ? token : this.token}` },
    };

    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: "PUT",
      credentials: "include",
      headers,
      body: JSON.stringify(data),
    });

    if (response.status === ResponseCodes.UNAUTHORIZED) {
      AlertService.error("Session expired");
      window.open(Pathnames.LOGIN, "_self");
    }

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.json();
  }

  // Generic DELETE request
  static async delete<T>(endpoint: string, token?: string): Promise<T> {
    const headers = {
      "Content-Type": "application/json",
      ...{ Authorization: `Bearer ${token ? token : this.token}` },
    };

    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: "DELETE",
      credentials: "include",
      headers,
    });

    if (response.status === ResponseCodes.UNAUTHORIZED) {
      AlertService.error("Session expired");
      window.open(Pathnames.LOGIN, "_self");
    }

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.json();
  }
}
