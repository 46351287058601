import Emailjs from "@emailjs/browser";
import Client from "../model/client";
import TransformDate from "../utils/TransformDate";

const serviceId = process.env.REACT_APP_EMAIL_JS_SERVICE;
const template1Id = process.env.REACT_APP_EMAIL_JS_TEMPLATE1;
const template2Id = process.env.REACT_APP_EMAIL_JS_TEMPLATE2;
const clientId = process.env.REACT_APP_EMAIL_JS_CLIENT;

export default class EmailService {
  /**
   *
   * @param name
   * @param email
   * @param type
   * @param date
   * @param startingTime
   * @param endingTime
   * @param note
   */
  static sendEmailToUser = async (
    name: string,
    email: string,
    type: string,
    date?: Date,
    startingTime?: string,
    endingTime?: string,
    note?: string
  ) => {
    let message = mapResponseMessage(
      type,
      date,
      startingTime,
      endingTime,
      note
    );

    serviceId &&
      template1Id &&
      (await Emailjs.send(
        serviceId,
        template1Id,
        {
          name: name,
          email: email,
          type: type,
          message: message,
        },
        clientId
      ).then(
        (result: any) => {
          console.log(result.text); //change
        },
        (error: any) => {
          console.log(error.text);
        }
      ));
  };

  /**
   *
   * @param user
   * @param title
   * @param message
   */
  static sendCustomEmail = async (
    user: Client,
    title: string,
    message: string
  ) => {
    serviceId &&
      template2Id &&
      (await Emailjs.send(
        serviceId,
        template2Id,
        {
          name: user.firstName,
          email: user.email,
          title: title,
          message: message,
        },
        clientId
      ).then(
        (result: any) => {
          console.log(result.text); //TODO: add to logs table
        },
        (error: any) => {
          console.log(error.text); //TODO: add to errors table
        }
      ));
  };
}

/**
 *
 * @param type
 * @param date
 * @param startingTime
 * @param endingTime
 * @param note
 * @returns
 */
const mapResponseMessage = (
  type: string,
  date?: Date,
  startingTime?: string,
  endingTime?: string,
  note?: string
): string => {
  let message: string = "";

  startingTime = TransformDate.convertTo12HourFormat(startingTime);
  endingTime = TransformDate.convertTo12HourFormat(endingTime);

  switch (type) {
    case "Appointment Schedule":
      if (date)
        message = `We're working on your request to schedule an appointment.<h3> On ${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()} from ${startingTime} to ${endingTime}.
          Note: ${note}.<h2> We will soon reply to you. </h2>`;
      break;
    case "Service Request":
      message =
        "We're working on your service request. We will soon reply to you.";
      break;
    case "Contact Message":
      message = "We will soon reply to you.";
      break;
    case "Appointment Confirmed":
      if (date)
        message = `Your appointment is confirmed for ${date.toLocaleString(
          "default",
          { month: "long" }
        )} ${date.getDate()}, ${date.getFullYear()}, from ${startingTime} to ${endingTime}. We will be at your location during this time.`;
      break;
    case "Appointment Denied":
      message =
        "Your appointment has been denied. There are no more openings for that day. What would be another suitable date for you?.";
      break;
    case "Contact Accepted":
      message = "Your message has been accepted. We will soon reply to you.";
      break;
    case "Contact Denied":
      message = "Your message has been denied.";
      break;
    case "Request Accepted":
      message =
        "Your request has been accepted. We're currently preparing your estimate and will be in touch shortly to discuss some details with you.";
      break;
    case "Request Denied":
      message =
        "Your request has been denied. We apologize for any inconvenience.";
      break;
    case "Service Completed":
      message =
        "Your valuable feedback means the world to us! Please give us a review! \n\n On https://setupremodeling.com/reviews"; //url https://setupremodeling.com/reviews/writeReview
      break;
    case "Activate Account":
      message = `We are pleased to welcome you to the SetupRemodeling administrative portal.
        As a valued member of our team, you now have access to a suite of tools and resources designed to enhance our operations and support our mission of delivering top-quality remodeling services.
        Please take a moment to familiarize yourself with the features and functionalities available on this platform. Should you have any questions or require assistance, do not hesitate to reach out to our support team.
        To activate your account, please click on the link below or enter the URL into your browser:
        <a href=https://setupremodeling.com/admin/login> here </a>
        . We are confident that your contributions will play a significant role in our continued success.
        The email to use for the login is the one you submited, the temporary password is [first name + 123@]. 
        Please pay attention you have to change it on the first login attempt.`;
      break; //Temporary password
    default:
      break;
  }

  return message;
};
