import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button as Btn,
  Typography,
} from "@material-ui/core";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import { Spinner } from "../../components";
import US_States from "../../model/enum/US_States";
import ProfileIcon from "../../assets/admin/profile-icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserModel from "../../model/user";
import DeleteUserModal from "./DeleteUserModal";
import AlertService from "../../service/AlertService";
import Pathnames from "../../model/enum/Pathnames";
import { useCookies } from "react-cookie";
import useStyles from "./styles";
import styles from "../styles/cardStyles";
import ApiService from "../../service/ApiService";

const UserProfile = () => {
  const classes = useStyles();
  const cardStyles = styles();
  const [cookies, setCookie, removeCookie] = useCookies(["session"]);

  const [user, setUser] = useState<UserModel>();
  const userEmail = localStorage.getItem("email");
  const [toggleSpinner, setToggleSpinner] = useState(true);

  var US_state = new US_States();

  //Get user info
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<UserModel>(`users/email/${userEmail}`).then(
        (data) => {
          setUser(data);
          setToggleSpinner(false);
        }
      );
    };

    if (!user && userEmail) fetchData();
  });

  const handleDeleteAccount = async () => {
    //TODO: disable account instead of deleting the account from firebase auth
    // const user = auth.currentUser;
    // if (user && user.email) {
    //   try {
    //     // Reauthenticate the user
    //     const credential = EmailAuthProvider.credential(user.email, "");
    //     await reauthenticateWithCredential(user, credential);
    //     // Delete the user
    //     await deleteUser(user);
    //     AlertService.succes("Account deleted successfully.");
    //   } catch (error: any) {
    //     AlertService.error(error.message);
    //   }
    // } else {
    //   AlertService.error("No authenticated user found.");
    // }
  };

  const handleDelete = async () => {
    if (user) {
      setToggleSpinner(true);
      await ApiService.delete<any>(`${DatabaseEntities.USERS}/${user.id}`)
        .then(async () => {
          //TODO: add strings to ToastMessages
          AlertService.succes("Account deleted successfully");
          removeCookie("session");
          setTimeout(() => {
            window.open(Pathnames.LOGIN, "_self");
            setToggleSpinner(false);
          }, 2000);
        })
        .catch(() => {
          AlertService.error("Error deleting account");
        });
    }
  };

  return (
    <>
      {!user || toggleSpinner ? (
        <Spinner />
      ) : (
        <Card className={cardStyles.card}>
          <div className={cardStyles.headerContainer}>
            <Typography className={classes.title}>My profile</Typography>
            <DeleteUserModal handleDelete={handleDelete} />
          </div>
          <CardContent>
            <div className={classes.summaryDiv}>
              <img src={ProfileIcon} className={classes.img} alt="profile" />
              <div className={classes.summarySubDiv}>
                <Typography className={classes.boldedTex}>
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant="subtitle2">
                  {user.city},{" "}
                  {US_state.getStateAbbreviation(parseInt(user.zip))}
                </Typography>
              </div>
            </div>
            <div className={classes.borderedDiv}>
              <div className={classes.headerDiv}>
                <Typography className={classes.boldedTex}>
                  Personal information
                </Typography>
                <Btn className={classes.moreVertIcon}>
                  <MoreVertIcon /> {/** TODO: enable to edit info */}
                </Btn>
              </div>
              <div className={classes.dividedContainer}>
                <div className={classes.subContainer}>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>
                      First name
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {user.firstName}
                    </Typography>
                  </div>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>Email</Typography>
                    <Typography className={classes.subTitle}>
                      {user.email}
                    </Typography>
                  </div>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>Role</Typography>
                    <Typography className={classes.subTitle}>
                      {user.Role?.name}
                    </Typography>
                  </div>
                </div>
                <div className={classes.subContainer}>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>
                      Last name
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {user.lastName}
                    </Typography>
                  </div>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>Phone</Typography>
                    <Typography className={classes.subTitle}>
                      {user.phone}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.borderedDiv}>
              <div className={classes.headerDiv}>
                <Typography className={classes.boldedTex}>Address</Typography>
                <Btn className={classes.moreVertIcon}>
                  <MoreVertIcon />
                </Btn>
              </div>
              <div className={classes.dividedContainer}>
                <div className={classes.subContainer}>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>
                      Country
                    </Typography>
                    <Typography className={classes.subTitle}>
                      United States
                    </Typography>
                  </div>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>
                      Zip code
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {user.zip}
                    </Typography>
                  </div>
                </div>
                <div className={classes.subContainer}>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>City</Typography>
                    <Typography className={classes.subTitle}>
                      {user.city}
                    </Typography>
                  </div>
                  <div className={classes.wrapedItems}>
                    <Typography className={classes.lightText}>State</Typography>
                    <Typography className={classes.subTitle}>
                      {user.state}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default UserProfile;
