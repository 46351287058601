import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import DeleteModal from "../../modal/DeleteModal";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../../assets/admin/edit-icon.svg";
import useStyles from "./styles";
import CategoryModel from "../../../model/category";
import ApiService from "../../../service/ApiService";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import AlertService from "../../../service/AlertService";
import ToastMessages from "../../../model/enum/ToastMessages";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const CategoryModal = ({
  category,
  externalService,
  resetList,
  setCategoryId,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = category != null;
  const [name, setName] = useState(edit ? category.name : "");

  const handleName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const submit = async () => {
    if (invalidInput()) return;

    let payload: CategoryModel = {
      id: edit ? category.id : 0,
      name: name,
    };

    if (edit) await handleUpdate(payload);
    else await handleCreate(payload);

    setName("");
    handleClose();
  };

  const invalidInput = (): boolean => {
    return name === "";
  };

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<CategoryModel>(DatabaseEntities.CATEGORIES, payload)
      .then((res) => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        setCategoryId(res.id);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : !externalService ? (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Category
        </Btn>
      ) : (
        <Btn className={classes.externalButton} onClick={handleOpen}>
          Add New
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <Typography className={classes.title}>
                <DeleteModal item={category} handleDelete={handleDelete} />
              </Typography>
            ) : (
              <Typography className={classes.title}>
                Add new category
              </Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          {edit && <hr />}
          <form className={classes.form}>
            <input
              className={classes.input}
              value={name}
              onChange={handleName}
              placeholder="Name"
            />
          </form>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={submit}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CategoryModal;
