import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  InputColor,
  LightGrey,
  LightPrimaryColor,
  Black,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    marginTop: "5%",
  },
  label: {
    fontWeight: "600",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
      fontSize: "1rem",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textarea: {
    width: "95% !important",
    height: "10rem",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "5rem",
      fontSize: "1rem",
      margin: 0,
      padding: 0,
    },
  },
  inputLink: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color: InputColor,
    background: LightGrey,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  image: {
    width: "50%",
    maxHeight: "555px",
    borderRadius: "8px",
    objectFit: "cover",
    cursor: "zoom-in",
    "@media (max-width: 640px)": {
      width: "100%",
      marginTop: "5%"
    },
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "5%",
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  //Details modal
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textareaDetail: {
    width: "95% !important",
    height: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "6rem",
      margin: 0,
      padding: 0,
    },
  },
}));
