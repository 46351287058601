import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  LightPrimaryColor,
  LightGrey,
  BackgroundBlue,
  InputColor,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  card: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    "@media (max-width: 640px)": {
      width: "90% !important",
      // marginTop: "30%",
      marginTop: "120px",
    },
    // "@media (min-width: 1536px)": {
    //   width: "60% !important",
    // },
  },
  cardContenDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  firstDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    width: "60%",
    padding: "3rem",
    "@media (max-width: 640px)": {
      padding: 0,
      width: "100%",
      alignItems: "center",
    },
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: PrimaryColor,
    textTransform: "uppercase",
  },
  description: {
    textAlign: "start",
    marginTop: "3%",
  },
  inputDiv: {
    marginTop: "5%",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  input: {
    width: "100% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color:InputColor,
    background: LightGrey,
    "@media (max-width: 640px)": {
      display: "flex",
      flexDirection: "row",
    },
    "& div, input, button": {
      //child
      border: "none",
      color: InputColor,
    },
  },
  textarea: {
    width: "100% !important",
    minHeight: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color: InputColor,
    background: LightGrey,
    "@media (max-width: 640px)": {
      minHeight: "10rem",
    },
  },
  button: {
    padding: "2% 3% 2% 3%",
    width: "10rem",
    marginTop: "5%",
    color: "white",
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
  },
  secondDiv: {
    width: "40%",
    "@media (max-width: 640px)": {
      width: "75%",
      marginTop: "5%",
    },
  },
  contactImg: {
    width: "100%",
    "@media (max-width: 640px)": {
      borderRadius: "10px",
    },
  },
}));
