import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CloseTime = 3000;

export default class AlertService {
  static succes = (message?: string) => {
    return toast.success(message, {
      autoClose: CloseTime,
      closeOnClick: true,
    });
  }

  static error = (message?: string) => {
    return toast.error(message, {
      autoClose: CloseTime,
      closeOnClick: true,
    });
  } 
  static info = (message?: string) => {
    return toast.info(message, {
      autoClose: CloseTime,
      closeOnClick: true,
    });
  } 

  static warning = (message?: string) => {
    return toast.warn(message, {
      autoClose: CloseTime,
      closeOnClick: true,
    });
  }
};