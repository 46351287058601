import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Typography } from "@material-ui/core";
import Bin from "../../assets/admin/bin-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const imgStyle = {
  width: "1.3rem",
  height: "1.3rem",
};

const DeleteModal = ({ item, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const deleteItem = async () => {
    await handleDelete(item);
    handleClose();
  };

  return (
    <div>
      <Button>
        {/* <img src={Bin} alt="bin" onClick={handleOpen} style={imgStyle} /> */}
        <span style={imgStyle} onClick={handleOpen}>🗑️</span>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Delete</Typography>
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.form}>
            <Typography>Do you want to delete this Item?</Typography>
            <Typography>
              Id: {item.Id}
              <br />
              It will be deleted{" "}
              <span style={{ textDecoration: "underline dotted red" }}>
                permanently
              </span>
            </Typography>
          </div>
          <br />
          <div className={classes.buttonGroup}>
            <Button color="success" onClick={deleteItem}>
              Yes
            </Button>
            <Button color="error" onClick={handleClose}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default DeleteModal;
