import React, { useEffect } from "react";
import { Button } from "@mui/material";
import AlertService from "./AlertService";
import ToastMessages from "../model/enum/ToastMessages";
import CalendarIcon from "../assets/admin/google-calendar-icon.svg";
import AppointmentModel from "../model/appointment";
import ClientModel from "../model/client";
import UserModel from "../model/user";

interface CalendarProps {
  payload: AppointmentModel;
  client: ClientModel | undefined;
  seller: UserModel | undefined;
  handleClose: any;
}

export default function CalendarService({
  payload,
  client,
  seller,
  handleClose,
}: CalendarProps) {
  const gapi = window.gapi;
  const google = window.google;
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/calendar";
  const accessToken = localStorage.getItem("access_token");
  const expiresIn = localStorage.getItem("expires_in");

  let gapiInited = false,
    gisInited = false,
    tokenClient: {
      callback: (resp: any) => Promise<void>;
      requestAccessToken: (arg0: { prompt: string }) => void;
    };

  useEffect(() => {
    // if (expiresIn != null) {
    //   const expiryTime = new Date().getTime() + parseInt(expiresIn) * 1000;
    //   if (expiryTime < new Date().getTime()) {
    //     localStorage.removeItem("access_token");
    //     localStorage.removeItem("expires_in");
    //   }
    // }

    gapiLoaded();
    gisLoaded();
  });

  function gapiLoaded() {
    gapi.load("client", initializeGapiClient);
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;

    if (accessToken && expiresIn) {
      gapi.client.setToken({
        access_token: accessToken,
        expires_in: expiresIn,
      });
    }
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: "", // defined later
    });

    gisInited = true;
  }

  //Enables user interaction after all libraries are loaded.
  function handleAuthClick() {
    AlertService.info("Connect your google account to add event to calendar");
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        throw resp;
      }
      //await listUpcomingEvents();
      const { access_token, expires_in } = gapi.client.getToken();
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("expires_in", expires_in);
    };

    if (!(accessToken && expiresIn)) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: "" });
    }
  }

  //Sign out the user upon button click.
  function handleSignoutClick() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
      localStorage.clear();
    }
  }

  async function listUpcomingEvents() {
    let response;
    try {
      const request = {
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      };
      response = await gapi.client.calendar.events.list(request);
    } catch (err: any) {
      console.log(err.message);
      return;
    }

    const events = response.result.items;
    if (!events || events.length === 0) {
      console.log("No events found.");
      return;
    }
    // Flatten to string to display
    const output = events.reduce(
      (
        str: any,
        event: { summary: any; start: { dateTime: any; date: any } }
      ) =>
        `${str}${event.summary} (${
          event.start.dateTime || event.start.date
        })\n`,
      "Events:\n"
    );

    return output;
  }

  function addManualEvent() {
    if (!payload || !client) return;

    let content: any = {
      Date: new Date(payload.scheduledAt),
      initialTime: payload.initialTime,
      finalTime: payload.finalTime,
    };

    let { start, end } = mapDates(
      content.Date,
      content.initialTime,
      content.finalTime
    );

    var event = {
      kind: "calendar#event",
      summary: "SetupRemodeling - appointment with " + client.firstName,
      location:
        client.street +
        ", " +
        client.city +
        ", " +
        client.state +
        " " +
        client.zip,
      description: payload.notes,
      start: {
        dateTime: start,
        timeZone: "America/New_York",
      },
      end: {
        dateTime: end,
        timeZone: "America/New_York",
      },
      recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
      attendees: [
        { email: client.email, responseStatus: "needsAction" },
        seller ? { email: seller.email } : "", // "TODO: include adm email",
      ],
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "email", minutes: 60 }, // 1 hour before
          { method: "popup", minutes: 10 }, // Notification 10 min before
        ],
      },
      guestsCanSeeOtherGuests: true,
    };

    console.log("request: ", event);

    var request = gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
      sendUpdates: "all",
    });
    request.execute(
      (body: any) => {
        console.log("response: ", body);
        if (body.htmlLink) {
          AlertService.succes(ToastMessages.ADDED_TO_CALENDAR);
          handleClose();
          window.open(body.htmlLink);
        } else if (body.code === 401) {
          AlertService.error(ToastMessages.GOOGLE_CALENDAR_FAILED);
          emptyStorage();
          handleAuthClick();
        }
      },
      (error: any) => {
        AlertService.error(error);
        console.error("response: ", error);
      }
    );
  }

  const mapDates = (date: Date, initialTime: string, finalTime: string) => {
    return {
      start:
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        initialTime +
        "-04:00",
      end:
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        finalTime +
        "-04:00",
    };
  };

  const loggedIn = (): boolean => {
    return (
      gapiInited &&
      accessToken !== null &&
      accessToken !== undefined &&
      accessToken !== "" &&
      expiresIn !== null &&
      expiresIn !== undefined &&
      expiresIn !== ""
    );
  };

  const emptyStorage = (): void => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
  };

  return (
    <>
      {!loggedIn ? (
        <Button id="authorize_button" onClick={handleAuthClick}>
          <img src={CalendarIcon} alt="calendar icon" />
        </Button>
      ) : (
        <Button onClick={addManualEvent}>
          <img src={CalendarIcon} alt="calendar icon" />
        </Button>
      )}
    </>
  );
}
