import { makeStyles } from "@material-ui/core";
import { PrimaryColor, BackgroundBlue } from "../../../../shared/styles/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
    "@media (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  categoryCard: {
    width: "800px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    padding: "1rem",
    "@media (max-width: 640px)": {
      width: "90% !important",
      marginBottom: "0px"
    },
  },
  title: {
    color: PrimaryColor,
    fontSize: "2rem",
    fontWeight: "700",
    textAlign: "start",
    textTransform: "uppercase",
    width: "100%",
    marginTop: "1rem",
    "@media (max-width: 640px)": {
      fontSize: "1.5rem",
    },
  },
  description: {
    textAlign: "start",
    marginTop: "2%",
    marginBottom: "7.5%",
  },
  carousel: {
    width: "100%",
    height: "50%",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  content: {
    maxWidth: "100%",
    maxHeight: "650px",
    objectFit: "contain",
    "@media (max-width: 640px)": {
      maxWidth: "100%",
      heigth: "300px",
      objectFit: "fill",
    },
  },
  img: {
    width: "80px",
    height: "40px",
    fill: PrimaryColor,
    stroke: PrimaryColor,
    color: PrimaryColor,
  },
}));
