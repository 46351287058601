import React from "react";
import useStyles from "./styles";
import Categories from "../../../../model/enum/Categories";
import { useNavigate } from "react-router-dom";
import Pathnames from "../../../../model/enum/Pathnames";

const BudgetCalculator = ({ budget, service }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  let categories: any[] = [];
  if (service === "Kitchen") {
    categories = Categories.kitchen;
  } else if (service === "Bathroom") {
    categories = Categories.bathroom;
  }

  const PercentageCalc = () => (
    <>
      {categories.map((type) => {
        return (
          <table className={classes.categories}>
            <tr>
              <td className={classes.percentage}>
                {" "}
                {Math.round(type.percentage * 100).toFixed(0)}%
              </td>
              <th className={classes.name}> {type.name}</th>
              <td className={classes.value}>
                {" "}
                ${Math.round(type.percentage * budget).toFixed(2)}
              </td>
            </tr>
          </table>
        );
      })}
      <hr style={{ border: "1px solid" }} />
      <table className={`mt-4 ${classes.categories}`}>
        <tr>
          <td className={classes.percentage}> 100%</td>
          <td className={classes.totalValue}> ${Math.round(budget).toFixed(2)}</td>
        </tr>
      </table>
    </>
  );

  return (
    <div>
      <hr style={{ border: "1px solid" }} />
      <label className={classes.budget_title}>COST SPLIT</label>
      <PercentageCalc />
      <div className="d-flex row mt-4 w-100">
        <button
          type="button"
          className={classes.request_button2}
          onClick={() => navigate(Pathnames.REQUEST)}
        >
          REQUEST A SERVICE
        </button>
        <button
          type="button"
          className={classes.request_button}
          onClick={() => navigate(Pathnames.SCHEDULE)}
        >
          SCHEDULE AN APPOINTMENT
        </button>
      </div>
    </div>
  );
};

export default BudgetCalculator;
