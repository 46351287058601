import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";
import ApiService from "../../../service/ApiService";
import Service from "../../../model/service";
import ServiceType from "../../../model/serviceType";

const Step2 = ({
  entryTypeOfProjects,
  typeOfProjects,
  entryProjects,
  projects,
  entryProjectDescription,
  projectDescription,
}: any) => {
  const classes = useStyles();
  const [services, setServices] = useState<Service[]>([]);
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<Service[]>("frontend/services").then((data) => {
        data.map((x: Service) => {
          if (x.available) setServices((data) => [...data, x]);
        });
      });
      await ApiService.get<ServiceType[]>("frontend/serviceTypes").then(
        (data) => {
          setServiceTypes(data);
        }
      );
    };

    if (services.length <= 0) fetchData();
  }, [services, serviceTypes]);

  return (
    <>
      <div className={classes.subtitleDiv}>
        <Typography className={classes.subtitle}>
          Please, provide the information about your project!
        </Typography>
      </div>
      <div>
        <div className={classes.inputDiv}>
          <select
            className={classes.input}
            onChange={entryProjects}
            value={projects}
          >
            <option value="0" disabled>
              Select a project...
            </option>
            {serviceTypes.map((type: ServiceType) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.inputDiv}>
          <select
            className={classes.input}
            onChange={entryTypeOfProjects}
            value={typeOfProjects}
          >
            <option value="0" disabled>
              Select a service...
            </option>
            {services.map((service: Service) => {
              return (
                <option key={service.id} value={service.id}>
                  {service.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.inputDiv}>
          <textarea
            className={classes.input}
            name="description"
            placeholder="Project Description..."
            onChange={entryProjectDescription}
            defaultValue={projectDescription}
          />
        </div>
      </div>
    </>
  );
};

export default Step2;
