import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  InputColor,
  DarkGrey,
  LightGrey,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      alignItems: "center",
      textAlign: "start",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 640px)": {
      alignItems: "center",
    },
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    marginTop: "5%",
  },
  label: {
    fontWeight: "600",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  addUserButton: {
    width: "30%",
    textTransform: "none",
    border: "1px solid #1e2e55",
    color: PrimaryColor,
    background: White,
    "&:hover": {
      color: White,
      background: PrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "99% !important",
    alignItems: "center",
    padding: 0,
    "@media (max-width: 640px)": {
      flexDirection: "column",
      width: "100% !important",
      margin: 0,
      padding: 0,
      border: "none",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textarea: {
    width: "95% !important",
    height: "5rem",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "10rem",
    },
  },
  checkbox: {
    accentColor: PrimaryColor,
    marginRight: "2%",
    marginLeft: "2%",
  },
  checkboxtext: {
    color: InputColor,
  },
  calendarContainer: {
    width: "50%",
    marginLeft: "2%",
    "@media (max-width: 640px)": {
      width: "100%",
      marginBottom: "5%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    border: "none",
    "& div, button": {
      borderRadius: "20px",
      color: PrimaryColor,
      "&:disabled": {
        color: Black,
      },
      //   '&:active': {
      //       background: PrimaryColor,
      //       color: White
      //   }
    },
    "@media (max-width: 640px)": {
      padding: 0,
    },
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "5%",
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  //User Profile
  delete: {
    color: "red",
  },
  summaryDiv: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid lightGrey",
    borderRadius: "8px",
    padding: "1rem",
    alignItems: "center",
  },
  moreVertIcon: {
    display: "flex",
    position: "relative",
  },
  img: {
    width: "40px",
    "@media (max-width: 640px)": {
      width: "130px",
    },
  },
  summarySubDiv: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginLeft: "2.5%",
    alignItems: "start",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  borderedDiv: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid lightGrey",
    borderRadius: "8px",
    alignItems: "start",
    padding: "1rem",
    marginTop: "2%",
    "@media (max-width: 640px)": {
      marginTop: "5%",
    },
  },
  boldedTex: {
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  lightText: {
    color: InputColor,
  },
  subTitle: {
    fontWeight: 600,
  },
  dividedContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "2%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  wrapedItems: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "start",
    left: 0,
    marginTop: "2%",
    marginBottom: "2%",
    "@media (max-width: 640px)": {
      textAlign: "start",
    },
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "start",
  },
  //Delete Modal
  textDelete: {
    width: "100%",
    marginTop: "10%",
    textAlign: "start",
  },
  buttonGroupModal: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    marginTop: "15%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  //Change modals
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  emptyInput: {
    width: "87%",
    border: "none",
    padding: "8px 16px 8px 16px",
  },
  //Details
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      color: InputColor,
      border: "none",
    },
  },
  textareaDetail: {
    width: "95% !important",
    height: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "10rem",
    },
  },
  buttonDetail: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: DarkGrey,
    background: White,
    border: "1px solid grey",
    borderRadius: "10px",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
}));
