import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import Faq from "../../model/faq";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet-async";
import ApiService from "../../service/ApiService";
import PaymentMethod from "../../model/paymentMethod";

const About = () => {
  const classes = useStyles();
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<Faq[]>("frontend/faqs").then((data) => {
        setFaqs(data);
      });
      await ApiService.get<PaymentMethod[]>("frontend/paymentMethods").then((data) => {
        setPaymentMethods(data);
      });
    };

    if (faqs.length <= 0) fetchData();
  });

  // TODO: fetch services from DB
  const services = [
    "Kitchen Remodeling",
    "Bathroom Remodeling",
    "Painting & Staining",
    "Windows & Doors",
    "Flooring",
    "Basement",
    "Porch & Deck",
    "Addition",
    "Carpentry",
  ];

  return (
    <>
      <Helmet>
        <title>About Us - Setup Remodeling</title>
        <meta
          name="description"
          content="Trust us to transform your space with
                  expertise and competitive pricing, ensuring you receive
                  unparalleled value. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className={classes.container}>
        <Card raised={true} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.firstSection}>
              <div>
                <Typography className={classes.title}>
                  ABOUT SETUP REMODELING
                </Typography>
                <Typography className={classes.description}>
                  At SETUP REMODELING, excellence and affordability go hand in
                  hand. Specializing in both repair and remodeling, we are
                  dedicated to providing top-quality service tailored to your
                  home improvement needs. Trust us to transform your space with
                  expertise and competitive pricing, ensuring you receive
                  unparalleled value. Choose SETUP REMODELING, where your home
                  is in expert hands.
                </Typography>
              </div>
              <div className={classes.div}>
                <Typography className={classes.title}>
                  FREQUENTLY ASKED QUESTIONS
                </Typography>
                {faqs.map((item: Faq) => {
                  return (
                    item.available && (
                      <>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className={classes.accortionTitle}
                          >
                            {item.question}
                          </AccordionSummary>
                          <AccordionDetails className={classes.description}>
                            {item.answer}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )
                  );
                })}
              </div>
            </div>
            <div className={classes.secondSection}>
              <div>
                <Typography className={classes.title}>SERVICES</Typography>
                {services.map((service: string) => (
                  <ul>
                    <li className={classes.description}>{service}</li>
                  </ul>
                ))}
              </div>
              <div className={classes.div}>
                <Typography className={classes.title}>
                  BUSINESS HOURS
                </Typography>
                <div className="d-flex col">
                  <ul className={`${classes.ul} ${classes.description}`}>
                    <li>Mon-Thu</li>
                    <li>Fri</li>
                    <li>Sat-Sun</li>
                  </ul>
                  <ul className={`${classes.accortionTitle} ${classes.ul}`}>
                    <span className={classes.span}>7am-5pm</span>
                    <span className={classes.span}>7am-4pm</span>
                    <span className={classes.span}>Closed</span>
                  </ul>
                </div>
              </div>
              <div className={classes.div}>
                <Typography className={classes.title}>
                  PAYMENT METHODS
                </Typography>
                {paymentMethods.map((method: PaymentMethod) => (
                  <ul>
                    <li className={classes.description}>{method.name}</li>
                  </ul>
                ))}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default About;
