import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullscreenImage from "../../shared/components/images/FullscreenImage";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import useStyles from "./styles";
import ReviewModal from "./ReviewModal";
import Review from "../../model/review";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  }
};

interface Props {
  item: Review,
  handleUpdate: any, 
  handleDelete: any
}

const ReviewDetailModal = ({ item, handleUpdate, handleDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {fullScreen ? (
        <FullscreenImage
          imageUrl={item.imageUrl}
          toggleFullScreen={toggleFullScreen}
        />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}>Details</Typography>
              <div className={classes.headerSubDiv}>
                <ReviewModal
                  item={item}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <form className={classes.form}>
              <div className={classes.container}>
                <div className="d-flex row">
                  <div className="mt-2">
                    <Typography className={classes.label}>Id</Typography>
                    <input
                      className={classes.inputDetail}
                      value={item.id}
                      disabled
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>Service</Typography>
                    <input
                      className={classes.inputDetail}
                      value={item?.Service?.name}
                      disabled
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>Location</Typography>
                    <input
                      className={classes.inputDetail}
                      value={item.location}
                      disabled
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>
                      Description
                    </Typography>
                    <input
                      className={classes.inputDetail}
                      value={item.description}
                      disabled
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>Link</Typography>
                    <textarea
                      className={classes.textareaDetail}
                      value={item.imageUrl}
                      disabled
                    />
                  </div>
                </div>
                {item.imageUrl ? (
                  <img
                    className={classes.image}
                    src={item.imageUrl}
                    alt="review img"
                    onClick={() => toggleFullScreen()}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={1000} height={500} />
                )}
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ReviewDetailModal;
