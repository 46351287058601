import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";

import useStyles from "./styles";
import BudgetCalculator from "./budgetCalculator/BudgetCalculator";

const Budget = ({ category }: any) => {
  const classes = useStyles();
  const [toggleBudget, setToggleBudget] = useState(false);
  const [budget, setBuget] = useState();

  const calculateBudget = () => {
    if (budget) setToggleBudget(true);
  };

  const inputBudget = (e: any) => {
    let bgt = e.target.value;
    if (!isNaN(bgt)) {
      setBuget(bgt);
    }
  };

  return (
    <div className={classes.container}>
      <Card raised={true} className={classes.budgetCard}>
        <CardContent className={classes.budget_content}>
          <label className={classes.budget_title}>YOUR BUDGET</label>
          <input
            type="text"
            min="100"
            className={classes.budget_input}
            placeholder="$3000.00"
            onChange={inputBudget}
            value={budget}
          />
          {!toggleBudget ? (
            <button
              type="button"
              className={classes.budget_button}
              onClick={calculateBudget}
              disabled={!budget}
            >
              Calculate
            </button>
          ) : (
            <BudgetCalculator budget={budget} service={category} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Budget;
