import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { NumberFormatter } from "react-number-formatter";
import ClientModel from "../../model/client";
import US_States from "../../model/enum/US_States";
import ClientServices from "../../model/enum/ClientServices";
import DeleteModal from "../modal/DeleteModal";
import EditIcon from "../../assets/admin/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ClientModal = ({
  client,
  externalService,
  resetList,
  setClient,
  handleUpdate,
  handleCreate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = client != null;
  const id = edit ? client.id : 0;
  const [firstName, setFirstName] = useState(edit ? client.firstName : "");
  const [lastName, setLastName] = useState(edit ? client.lastName : "");
  const [email, setEmail] = useState(edit ? client.email : "");
  const [phone, setPhone] = useState(edit ? client.phone : "");
  const [zipCode, setZipCode] = useState(edit && client.zip ? client?.zip : "");
  const [street, setStreet] = useState(
    edit && client.street ? client.street : ""
  );
  const [city, setCity] = useState(edit && client.city ? client.city : "");
  const [state, setState] = useState(edit && client.state ? client.state : "");

  const US_State = new US_States();

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleStreet = (e: any) => {
    e.preventDefault();
    setStreet(e.target.value);
  };

  const handleCity = (e: any) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const handleState = (e: any) => {
    e.preventDefault();
    setState(e.target.value);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: ClientModel = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      street: street,
      zip: zipCode,
      city: city,
      state: state,
      service: ClientServices.MANUAL,
    };

    if (!externalService) {
      if (edit) await handleUpdate(payload);
      else await handleCreate(payload);
    } else {
      await ApiService.post<ClientModel>(DatabaseEntities.CLIENTS, payload)
        .then((res) => {
          AlertService.succes(ToastMessages.CREATED);
          setClient(res);
          resetList();
        })
        .catch((error) => {
          AlertService.error(error);
        });
    }

    handleClose();
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      email === "" ||
      email?.indexOf("@") === -1 ||
      email?.indexOf(".") === -1 ||
      phone === ""
    );
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : !externalService ? (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Client
        </Btn>
      ) : (
        <Btn className={classes.externalButton} onClick={handleOpen}>
          Add New
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <DeleteModal item={client} handleDelete={handleDelete} />
            ) : (
              <Typography className={classes.title}>Add client</Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          {edit && <hr />}
          <div className={classes.form}>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={firstName}
                onChange={handleFirstName}
                placeholder="First Name"
              />
              <input
                className={classes.input}
                value={lastName}
                onChange={handleLastName}
                placeholder="Last Name"
              />
            </div>
            <input
              className={classes.input}
              value={email}
              type="email"
              onChange={handleEmail}
              placeholder="Email"
            />
            <div className={classes.input}>
              <NumberFormatter
                defaultCountry="USA"
                placeholder="Phone"
                value={phone}
                getValue={(n: string) => handlePhone(n)}
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={street}
                onChange={handleStreet}
                placeholder="Street"
              />
              <input
                className={classes.input}
                value={zipCode}
                onChange={handleZipCode}
                placeholder="ZIP code"
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                className={classes.input}
                value={city}
                onChange={handleCity}
                placeholder="City"
              />
              <input
                className={classes.input}
                value={state}
                onChange={handleState}
                placeholder="State"
              />
            </div>
          </div>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={() => register()}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ClientModal;
