export default class CountMonth {
    MonthAndCountArray = 
    [
        { month: 1, count: 0 },
        { month: 2, count: 0 },
        { month: 3, count: 0 },
        { month: 4, count: 0 },
        { month: 5, count: 0 },
        { month: 6, count: 0 },
        { month: 7, count: 0 },
        { month: 8, count: 0 },
        { month: 9, count: 0 },
        { month: 10, count: 0 },
        { month: 11, count: 0 },
        { month: 12, count: 0 },
    ];
}