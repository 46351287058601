import React, { useEffect } from "react";
import useStyles from "./styles";
import { ElfsightWidget } from "react-elfsight-widget";
import Maps from "./Maps";
import { Helmet } from "react-helmet-async";

const ID = "2bec8895-4ccb-4030-a12b-6d21462f7759";

const Review = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>Reviews - Setup Remodeling</title>
        <meta
          name="description"
          content="Review our service. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/reviews" />
      </Helmet>
      <div className={classes.review}>
        <Maps />
        <div className={classes.reviewsList}>
          <ElfsightWidget widgetId={ID} />
        </div>
      </div>
    </>
  );
};

export default Review;
