import React, { useEffect } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

import useStyles from "./styles";

const PrivacyPolicy = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Setup Remodeling | Privacy Policy";
  }, []);

  return (
    <div className={classes.container}>
      <Card raised={true} className={classes.card}>
        <CardContent>
          <label className={classes.title}>Privacy Policy</label>
          <Typography className={classes.description}>
            Last Update: Nov 14, 2022
          </Typography>
          <br />
          <Typography className={classes.description}>
            Thank you for visiting the Privacy Policy of Setup Remodeling. This
            Privacy Policy explains how Setup Remodeling (collectively, “Setup
            Remodeling”, “we”, “us”, or “our”) collects, uses, and shares
            information about you (“you”, “yours” or “user”) when you access or
            use our websites (“Services”). Users are responsible for any
            third-party data they provide or share through the Services and
            confirm that they have the third-party's consent to provide such
            data to us.
          </Typography>
          <label className={classes.title}>Information we collect</label>
          <Typography className={classes.description}>
            We may collect and combine information about you when you access or
            use our services, including
            <strong> Contact Information</strong>, such as: address (street
            address, city, state, zip code).
          </Typography>
          <Typography className={classes.description}>
            <strong>Log and Usage Information: </strong>suc h as browser type you
            use, hardware model, operating system, IP address, unique device
            identifiers, access times, pages viewed, links clicked, and browsing
            behavior such as time spent, what site you came from, what site you
            visit when you leave us, and browsing behavior.
          </Typography>
          <label className={classes.title}>How we use your information</label>
          <Typography className={classes.description}>
            We use information we collect about you to provide, maintain, and
            improve our Services and other interactions we have with you. For
            example, we use the information collected to:
          </Typography>
          <ul>
            <li>
              <Typography className={classes.description}>
                Facilitate and improve our online experience;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Provide and deliver products and services, perform
                authentication, process transactions and returns, and send you
                related information, including confirmations, receipts,
                invoices, customer experience surveys, and product or
                Services-related notices;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Process and deliver promotions;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Respond to your comments and questions and provide customer
                service;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If you have indicated to us that you wish to receive
                notifications or promotional messages;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Detect, investigate and prevent fraudulent transactions and
                other illegal activities and protect our rights and property and
                others;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Comply with our legal and financial obligations;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Monitor and analyze trends, usage, and activities;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Provide and allow our partners to provide advertising and
                marketing targeted toward your interests.
              </Typography>
            </li>
          </ul>
          <label className={classes.title}>How We May Share Information</label>
          <Typography className={classes.description}>
            We may share your Personal Information in the following situations:
          </Typography>
          <ul>
            <li>
              <Typography className={classes.description}>
                <strong>Third Party Services Providers. </strong>We may share
                data with service providers, vendors, contractors, or agents who
                complete transactions or perform services on our behalf, such as
                those that assist us with our business and internal operations
                like shipping and delivery, payment processing, fraud
                prevention, customer service, gift cards, experiences,
                personalization, marketing, and advertising;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                <strong>Change in Business. </strong>We may share data in
                connection with a corporate business transaction, such as a
                merger or acquisition of all or a portion of our business to
                another company, joint venture, corporate reorganization,
                insolvency or bankruptcy, financing or sale of company assets;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                <strong>To Comply with Law. </strong>We may share data to
                facilitate legal process from lawful requests by public
                authorities, including to meet national security or law
                enforcement demands as permitted by law.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                <strong>With Your Consent. </strong>We may share data with third
                parties when we have your consent.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                <strong>With Advertising and Analytics Partners. </strong>See
                the section entitled “Advertising and Analytics” below.
              </Typography>
            </li>
          </ul>
          <label className={classes.title}>Data Security</label>
          <Typography className={classes.description}>
            We implement commercially reasonable security measures designed to
            protect your information. Despite our best efforts, however, no
            security measures are completely impenetrable.
          </Typography>
          <label className={classes.title}>Data Retention</label>
          <Typography className={classes.description}>
            We store the information we collect about you for as long as
            necessary for the purpose(s) for which we collected it or for other
            legitimate business purposes, including to meet our legal,
            regulatory, or other compliance obligations.
          </Typography>
          <label className={classes.title}>Age Limitations</label>
          <Typography className={classes.description}>
            Our Service is intended for adults ages 18 years and above. We do
            not knowingly collect personally identifiable information from
            children. If you are a parent or legal guardian and think your child
            under 13 has given us information, please email or write to us at
            the address listed at the end of this Privacy Policy. Please mark
            your inquiries “COPPA Information Request.”
          </Typography>
          <label className={classes.title}>
            Changes to this Privacy Policy
          </label>
          <Typography className={classes.description}>
            Setup Remodeling may change this Privacy Policy from time to time.
            We encourage you to visit this page to stay informed. If the changes
            are material, we may provide you additional notice to your email
            address or through our Services. Your continued use of the Services
            indicates your acceptance of the modified Privacy Policy.
          </Typography>
          <label className={classes.title}>
            Storage of information in the United States
          </label>
          <Typography className={classes.description}>
            Information we maintain may be stored both within and outside of the
            United States. If you live outside of the United States, you
            understand and agree that we may transfer your information to the
            United States, and that U.S. laws may not afford the same level of
            protection as those in your country.
          </Typography>
          <label className={classes.title}>Contact us</label>
          <Typography className={classes.description}>
            If you have questions, comments, or concerns about this Privacy
            Policy, you may contact us at:
          </Typography>
          <ul>
            <li>
              <Typography className={classes.description}>
                setupremodeling@gmail.com
              </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;
