import React, { useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import frame from "../../../assets/Frame.svg";
import Pathnames from "../../../model/enum/Pathnames";

const ThanksPage = ({ service }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  var visitGallery: boolean = service === "appointment";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={classes.container}>
      <Card raised className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.text}>
            Thank you for your request. We will get back to you promptly
          </Typography>
          <img src={frame} alt="thank you" className={classes.image} />
          {visitGallery ? (
            <Button
              className={classes.button}
              onClick={() => navigate(Pathnames.GALLERY)}
            >
              Visit Gallery
            </Button>
          ) : (
            <Button
              className={classes.button}
              onClick={() => navigate(Pathnames.SCHEDULE)}
            >
              Schedule Appointment
            </Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ThanksPage;
