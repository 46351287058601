import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  LightGrey,
  InputColor,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    marginTop: "5%",
  },
  label: {
    fontWeight: "600",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50% !important",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "99% !important",
    alignItems: "center",
    padding: 0,
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "5%",
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  //modal
  permissionDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "5%",
  },
  subTitle: {
    color: Black,
    fontSize: "1.1rem",
    fontWeight: "600",
  },
  //Details
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
}));
