import { makeStyles } from "@material-ui/core/styles";
import { PrimaryColor, BackgroundBlue } from "../../shared/styles/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  card: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    padding: "5rem",
    "@media (max-width: 640px)": {
      width: "90% !important",
      //marginTop: "30%",
      marginTop: "120px",
      padding: 0,
    },
    "@media (min-width: 1536px)": {
      width: "60% !important",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: PrimaryColor,
    textAlign: "start",
    marginBottom: "2rem",
    "@media (max-width: 640px)": {
      fontSize: "1.4rem",
      marginTop: "5%",
      width: "100%",
    },
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignItems: "start",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  secondSection: {
    display: "flex",
    flexDirection: "column",
    width: "35%",
    marginLeft: "5%",
    alignItems: "start",
    "@media (max-width: 640px)": {
      margin: "0%",
      width: "100%",
    },
  },
  div: {
    marginTop: "10%",
  },
  description: {
    textAlign: "start",
    "@media (max-width: 640px)": {
      marginBottom: "5%",
    },
  },
  accortionTitle: {
    fontWeight: "bold",
    textAlign: "start",
    "@media (max-width: 640px)": {
      marginBottom: "5%",
    },
  },
  ul: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    textAlign: "start",
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
  span: {
    fontSize: ".9rem",
  },
}));
