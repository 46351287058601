import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import cardStyles from "../styles/cardStyles";
import useStyles from "./styles";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import Calendar from "react-calendar";
import TodayIcon from "@mui/icons-material/Today";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppointmentModel from "../../model/appointment";
import TransformDate from "../../utils/TransformDate";
import US_States from "../../model/enum/US_States";
import AppointmentDetailModal from "../appointment/AppointmentDetailModal";
import Pathnames from "../../model/enum/Pathnames";
import styled from "styled-components";

// Customize the button styles here
export const CalendarWrapper = styled.div`
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: white; /* Custom color */
    color: black; /* Button text color */
    border: none; /* Remove default border */
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow:hover,
  .react-calendar__navigation__label:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  .react-calendar__tile--active {
    color: white; /* Text color for active date */
    background: #1e2e55;
    border-radius: 20px; /* Optional: make the button rounded */
  }

  .react-calendar__tile--active:hover {
    background-color: #1e2e5580; /* Darker color on hover */
  }
`;

const UpcomingMeetings = () => {
  const classes = useStyles();
  const styles = cardStyles();
  const [appointments, setAppointments] = useState<AppointmentModel[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const abbv = new US_States();

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<AppointmentModel[]>(
        `${DatabaseEntities.APPOINTMENTS}?upcomings=true`
      ).then((data: AppointmentModel[]) => {
        setAppointments(data);
        setLoading(false);
      });
    };

    if (loading && appointments.length <= 0) fetchData();
  }, [appointments]);

  const Loading = () => {
    return <>Loading...</>;
  };

  return (
    <>
      <Card raised className={styles.card}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.headerContainer}>
              <Typography className={styles.title}>
                Upcoming Meetings
              </Typography>
            </div>
            <CardContent className={classes.container}>
              <div className={classes.subContainer}>
                {appointments.length > 0 ? (
                  appointments.map((appointment: AppointmentModel, index) => {
                    return (
                      <>
                        <div className={classes.name} key={index}>
                          {appointment.Client?.firstName}{" "}
                          {appointment.Client?.lastName}
                        </div>
                        <div className={classes.item}>
                          <div className={classes.content}>
                            <TodayIcon />{" "}
                            {TransformDate.toFullDate(appointment.scheduledAt)}
                            {" at "}
                            {TransformDate.convertTo12HourFormat(
                              appointment.initialTime
                            )}
                          </div>
                          <div style={{ width: "10%" }}>|</div>
                          <div className={classes.content}>
                            <LocationOnIcon />{" "}
                            {appointment.Client?.street}
                            {", "}
                            {appointment.Client?.city}
                            {", "}
                            {appointment.Client?.zip &&
                              abbv.getStateAbbreviation(
                                parseInt(appointment.Client.zip)
                              )}
                          </div>
                          <AppointmentDetailModal
                            appointment={appointment}
                            handleUpdate={null}
                            handleDelete={null}
                          />
                        </div>
                        {index < appointments.length - 1 && (
                          <hr style={{ width: "100%" }} />
                        )}
                      </>
                    );
                  })
                ) : (
                  <>No appointments for the upcoming days.</>
                )}
              </div>
              <div
                className={`calendar-container ${classes.calendarContainer}`}
              >
                <CalendarWrapper>
                  <Calendar
                    className={classes.calendar}
                    calendarType={"US"}
                    value={new Date()}
                  />
                </CalendarWrapper>
                <Button
                  className={classes.button}
                  onClick={() => navigate(Pathnames.APPOINTMENT_LIST)}
                >
                  View Appointments
                </Button>
              </div>
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
};

export default UpcomingMeetings;
