import { makeStyles } from "@material-ui/core/styles";
import { LightPrimaryColor, PrimaryColor, White } from "../../styles/colors";

export default makeStyles(() => ({
  //Cookies
  container: {
    display: "flex",
    position: "fixed",
    maxWidth: "50rem",
    height: "100px",
    padding: "20px",
    left: 20,
    bottom: 20,
    borderRadius: "25px",
    background: White,
    zIndex: 2,
    "@media (max-width: 640px)": {
      height: "auto",
      left: 0,
      bottom: 120,
      width: "100%",
    },
  },
  logoDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70px",
    borderRadius: "10px",
    background: "#D9D9D9",
  },
  logo: {
    width: "35px",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    width: "400px",
  },
  text: {
    textAlign: "start",
  },
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cookiesSettings: {
    textTransform: "none",
    textDecoration: "underline",
    color: PrimaryColor,
    borderRadius: "9px",
  },
  acceptButton: {
    textTransform: "none",
    background: PrimaryColor,
    color: White,
    borderRadius: "9px",
    "&:hover": {
      color: LightPrimaryColor,
    },
  },
  //Settings
  middleContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "15px",
    borderRadius: "25px",
    textAlign: "start",
    background: White,
    zIndex: 2,
  },
  title: {
    color: "#333333",
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: "44.64px",
    wordWrap: "break-word",
  },
  textSettings: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "29.76px",
    wordWrap: "break-word",
  },
  checklist: {
    marginTop: "20px"
  },
  cookieName: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    lineHeight: "29.76px",
    wordWrap: "break-word",
    textTransform: "uppercase"
  },
  toggleDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center"
  },
  buttonSettingDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: "40px"
  },
  declineButton: {
    textTransform: "none",
    background: White,
    color: PrimaryColor,
    borderRadius: "9px",
    border: "1px solid #1e2e5580",
    "&:hover": {
      color: LightPrimaryColor,
    },
  },
}));
