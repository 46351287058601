import React from "react";
import { Link } from "react-router-dom";
import {
  Kitchen,
  Bathroom,
  Painting,
  Window,
  Flooring,
  Basement,
  Deck,
  Framing,
  Carpentry
} from "./index";
import Pathnames from "../../model/enum/Pathnames";
import ServiceClass from "../../model/enum/ServiceClass";
import useStyles from "./styles";

const ListServices = () => {
  const classes = useStyles();

  const Services = new ServiceClass();

  return (
    <>
      <label className={classes.title}>Our Services</label>
      <div className={classes.listServices}>
        <div className={classes.rows}>
          <Link to={Pathnames.KITCHEN_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Kitchen}
              alt={Services.kitchen}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.kitchen}</label>
          </Link>
          <Link to={Pathnames.BATHROOM_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Bathroom}
              alt={Services.bathroom}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.bathroom}</label>
          </Link>
          <Link
            to={Pathnames.PAINTING_AND_STAINING_SERVICE}
            className={classes.categoryDiv}
          >
            <img
              className={classes.img}
              src={Painting}
              alt={Services.painting}
              loading="lazy"
            ></img>
            <label className={classes.label}>
              {Services.painting} & {Services.staining}
            </label>
          </Link>
        </div>
        <div className={classes.rows}>
          <Link to={Pathnames.DOOR_AND_WINDOWS_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Window}
              alt={Services.windows}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.doors} & {Services.windows}</label>
          </Link>
          <Link to={Pathnames.FRAMING_AND_SHEETROCK_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Framing}
              alt={Services.framing}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.framing} & {Services.sheetrock}</label>
          </Link>
          <Link to={Pathnames.FLOORING_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Flooring}
              alt={Services.flooring}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.flooring}</label>
          </Link>
        </div>
        <div className={classes.rows}>
          <Link
            to={Pathnames.BASEMENT_SERVICE}
            className={classes.categoryDiv}
          >
            <img
              className={classes.img}
              src={Basement}
              alt={Services.basement}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.basement}</label>
          </Link>
          <Link to={Pathnames.PORCH_AND_DECK_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Deck}
              alt={Services.porch_and_decks}
              loading="lazy"
            ></img>
            <label className={classes.label}>
              {Services.porch_and_decks}
            </label>
          </Link>
          <Link to={Pathnames.CARPENTRY_SERVICE} className={classes.categoryDiv}>
            <img
              className={classes.img}
              src={Carpentry}
              alt={Services.carpentry}
              loading="lazy"
            ></img>
            <label className={classes.label}>{Services.carpentry}</label>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ListServices;
