import React from "react";
import { Bar } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js/auto";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
  title: {
    display: true,
    text: 'Bar Chart',
  },
};

const BarChart = ({ chartData }: any) => {
  return <Bar options={options} data={chartData} />;
};

export default BarChart;
