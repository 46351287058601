import React, { useState, useEffect, ChangeEvent } from "react";
import { Spinner } from "../index";
import ListInGallerry from "../../shared/components/images/ListInGallery";
import { Button } from "@material-ui/core";
import { Pagination } from "@mui/material";
import GalleryModel from "../../model/gallery";
import { Helmet } from "react-helmet-async";
import ApiService from "../../service/ApiService";
import useStyles from "./styles";
import GalleryResponse from "../../model/galleryResponse";
import Service from "../../model/service";

const Gallery = ({ handleNavbar }: any) => {
  const classes = useStyles();

  const [gallery, setGallery] = useState<GalleryModel[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [services, setServices] = useState<Service[]>([]);
  const [service, setService] = useState<Service>();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set(
      "serviceId",
      service?.id ? service.id.toString() : "null"
    );
    url.searchParams.set("page", page.toString());
    window.history.pushState({}, "gallery", url);
  });

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<GalleryResponse>(
        `frontend/gallery?serviceId=${
          service?.id ? service.id : "0"
        }&page=${page}`
      ).then((data) => {
        const { galleries, totalItems, totalPages } = data;
        setGallery(galleries);
        setTotalPages(totalPages);
        setToggleSpinner(false);
      });
    };

    //Done this way because server retrieves only 30 items
    const fetchServices = async () => {
      await ApiService.get<Service[]>("frontend/services").then((data) => {
        setServices(data);
      });
    };

    if (services.length <= 0) fetchServices();
    fetchData();
  }, [service, page]);

  const handlePageChange = (event: ChangeEvent<unknown>, pg: number) => {
    setPage(pg);
    scrollToTop();
  };

  const handleServiceClick = (srvc: Service | undefined) => {
    setService(srvc);
    setPage(1);
    setGallery(() => []);
    scrollToTop();
  };

  return (
    <>
      <Helmet>
        <title>Gallery - Setup Remodeling</title>
        <meta
          name="description"
          content="Check our Gallery to view the excellence in our work. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/gallery" />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.titleDiv}>
          <span className={classes.title}>Our Work</span>
          <div className={classes.filters}>
            <Button
              className={
                service == undefined ? classes.buttonClicked : classes.button
              }
              disabled={service == undefined}
              onClick={() => handleServiceClick(undefined)}
            >
              All
            </Button>
            {services.map((srvc) => (
              <Button
                key={srvc.id}
                className={
                  srvc.id === service?.id
                    ? classes.buttonClicked
                    : classes.button
                }
                disabled={srvc.id === service?.id}
                onClick={() => handleServiceClick(srvc)}
              >
                {srvc.name}
              </Button>
            ))}
          </div>
        </div>
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <ListInGallerry images={gallery} handleNavbar={handleNavbar} />
            </div>
            <div className={classes.pagination}>
              <Pagination
                count={totalPages}
                showFirstButton
                showLastButton
                onChange={handlePageChange}
                page={page}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Gallery;
