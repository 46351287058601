import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "@material-ui/core";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import Pathnames from "../../model/enum/Pathnames";
import Spinner from "../../shared/components/loader/Spinner";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ToastMessages from "../../model/enum/ToastMessages";
import AlertService from "../../service/AlertService";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import UserModel from "../../model/user";
import AuthModel from "../../model/auth";
import SessionModel from "../../model/session";
import ErrorModel from "../../model/error";
import Logo from "../../assets/main.png";
import useStyles from "./styles";
import "./Login.scss";

const Login = () => {
  const classes = useStyles();

  const [cookies, setCookie] = useCookies(["session"]);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPwd, setErrorPwd] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const validEmail = () => {
    if (email === "") {
      setErrorEmail("Invalid Email");
    } else if (!email.includes("@")) {
      setErrorEmail("Email must contain @");
    } else if (!email.includes(".")) {
      setErrorEmail("Email must contain '.'");
    } else {
      return true;
    }
    return false;
  };

  const validPwd = () => {
    if (password === "") {
      setErrorPwd("Invalid password");
    } else if (password.length < 6) {
      setErrorPwd("Password not allowed");
    } else {
      return true;
    }
    return false;
  };

  const login = async () => {
    if (validEmail() && validPwd()) {
      setToggleSpinner(true);
      setErrorEmail("");
      setErrorPwd("");
      try {
        const payload: AuthModel = {
          email: email,
          password: password,
        };

        const session: SessionModel = await ApiService.post<SessionModel>(
          `${DatabaseEntities.AUTH}/loginWithEmail`,
          payload
        );

        if (session) {
          setCookie("session", session);
          localStorage.setItem("accessToken", session.accessToken);
          localStorage.setItem("email", email);
          AlertService.succes(ToastMessages.LOGGED_IN);

          const user: UserModel = await ApiService.get<UserModel>(
            `${DatabaseEntities.USERS}/${session.userId}`,
            session.accessToken
          );

          setInterval(() => {
            if (!user.activated) {
              window.open(
                Pathnames.ACTIVATE_ACCOUNT +
                  "?userId=" +
                  user.id +
                  "&email=" +
                  email,
                "_self"
              );
            } else {
              window.open(Pathnames.DASHBOARD, "_self");
            }
          }, 3000);
        } else {
          AlertService.error(ToastMessages.LOGIN_FAILED);
          let err: ErrorModel = {
            code: "500",
            reason: "Login attempt failed",
            service: "Login",
          };

          await ApiService.post<ErrorModel>(DatabaseEntities.ERRORS, err);
        }
      } catch (error: any) {
        AlertService.error(ToastMessages.LOGIN_FAILED);
        let err: ErrorModel = {
          code: "500",
          reason: error.toString(),
          service: "Login",
        };

        await ApiService.post<ErrorModel>(DatabaseEntities.ERRORS, err);
      }
    } else {
      AlertService.warning(ToastMessages.INVALID_LOGIN_FORM);
    }

    setToggleSpinner(false);
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (validEmail() && validPwd()) login();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  return (
    <div className={classes.login}>
      <Card className={classes.loginCard}>
        <img src={Logo} className={classes.logo} alt="logo" />
        <div className="form__group">
          <input
            type="input"
            className={classes.input}
            placeholder="example@setupremodeling.com"
            name="email"
            id="email"
            required
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
          />
          <label className="form__label">Email</label>
        </div>
        <label className={classes.errorText}>{errorEmail}</label>
        <div className="form__group">
          <div className={classes.inputDiv}>
            <input
              type={viewPassword ? "text" : "password"}
              className={classes.emptyInput}
              placeholder="**********"
              name="password"
              id="password"
              required
              value={password}
              onChange={(event: any) => setPassword(event.target.value)}
            />
            <label className="form__label">Password</label>
            {viewPassword ? (
              <Button onClick={() => setViewPassword(!viewPassword)}>
                <VisibilityOffIcon />
              </Button>
            ) : (
              <Button onClick={() => setViewPassword(!viewPassword)}>
                <VisibilityIcon />
              </Button>
            )}
          </div>
        </div>
        <label className={classes.errorText}>{errorPwd}</label>
        <button
          className={classes.button}
          onClick={login}
          disabled={toggleSpinner}
        >
          Login
        </button>
        {error ? <p className={classes.errorText}>{error}</p> : null}
        {toggleSpinner && <Spinner />}
        <hr style={{ width: "20%" }} />
        {/** TODO: send info to server */}
        {/* <div className={classes.checkboxDiv}>
          <input type="checkbox" className={classes.checkbox} />
          <Typography>Remember me for 30 days</Typography>
        </div> */}
      </Card>
      <ToastContainer />
    </div>
  );
};

export default Login;
