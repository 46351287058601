import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  BackgroundBlue,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  homepage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: BackgroundBlue,
  },
  carrouselDiv: {
    marginTop: "150px",
    "@media (max-width: 640px)": {
      marginTop: "105px",
    },
  },
  carrouselBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(180deg, rgba(24, 47, 88, 0) 0%, #182F58 100%)",
    marginTop: "100px",
    top: 0,
    zIndex: 1,
    "@media (max-width: 640px)": {
      marginTop: "-180px",
    },
    "@media (min-width: 1536px)": {
      marginTop: "70px",
    },
  },
  requestDiv: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    width: "750px",
    //marginTop: "-350px",
    //top: "500px",
    bottom: "100px",
    zIndex: 2,
    "@media (min-width: 1536px)": {
      //marginTop: "-70%",
      //top: "700px",
    },
    "@media (max-width: 640px)": {
      top: 0,
      bottom: 0,
      width: "100%",
      //marginTop: "-130%",
    },
  },
  request_button3: {
    width: "auto",
    border: "none",
    padding: "10px",
    marginTop: "40px",
    cursor: "pointer",
    borderRadius: ".5rem",
    fontWeight: "700",
    color: PrimaryColor,
    backgroundColor: White,
    "&:hover": {
      background: BackgroundBlue,
    },
    "@media (max-width: 640px)": {
      fontSize: "0.7rem",
    },
  },
  requestService: {
    font: "verdana, sans-serif",
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: White,
    "@media (max-width: 640px)": {
      fontSize: "1.5rem",
    },
  },
  request_div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: White,
    width: "100% !important",
  },
  request_img_and_background_div: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    "@media (max-width: 640px)": {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      width: "100%",
    },
  },
  request_color_bgd: {
    background: PrimaryColor,
    width: "20rem",
    height: "27.5rem",
    marginTop: "20px",
    //marginLeft: "35%",
    marginBottom: "10%",
    borderRadius: "8%",
    "@media (max-width: 820px)": {
      width: "18rem",
      height: "28rem",
      marginTop: "5%",
      marginLeft: "18%",
    },
    "@media (max-width: 640px)": {
      width: "15rem",
      height: "16rem",
      marginTop: "10%",
      marginLeft: "0%",
    },
  },
  request_img: {
    width: "20rem",
    height: "28rem",
    position: "absolute",
    marginTop: "-10px",
    marginLeft: "2%",
    borderRadius: "8%",
    "@media (max-width: 820px)": {
      width: "18rem",
      height: "28rem",
      marginTop: "0%",
      marginLeft: "12%",
    },
    "@media (max-width: 640px)": {
      width: "15rem",
      height: "17rem",
      marginTop: "-12%",
      marginLeft: "10%",
    },
  },
  request_text_div: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    width: "33rem",
    height: "14rem",
    padding: "4rem",
    borderRadius: "30px",
    marginLeft: "30%",
    marginTop: "17rem",
    backgroundColor: BackgroundBlue,
    "@media (min-width: 1536px)": {
      marginLeft: "15%",
      marginTop: "15%",
      width: "35rem",
    },
    "@media (max-width: 640px)": {
      position: "relative",
      width: "80%",
      height: "auto",
      marginLeft: "0%",
      marginTop: "0%",
      marginBottom: "7.5%",
      padding: "2rem",
      //TODO: fix phone icon stsying behind this div on phones
    },
  },
  request_text: {
    width: "100%",
    fontSize: "2rem",
    fontWeight: "700",
    wordWrap: "break-word",
    color: PrimaryColor,
    margin: "10%",
    "@media (max-width: 640px)": {
      fontSize: "1.3rem",
      margin: "0%",
    },
  },
  text: {
    color: "#333333",
    fontSize: "1.5rem",
    textAlign: "start",
    fontWeight: "700",
    "@media (max-width: 640px)": {
      fontSize: "1.3rem",
      margin: "0%",
    },
  },
  request_button_group: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
    "@media (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  request_button: {
    borderRadius: ".5rem",
    width: "auto",
    border: "none",
    padding: "10px",
    background: PrimaryColor,
    color: White,
    cursor: "pointer",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      fontSize: "0.9rem",
      marginTop: "5%",
      width: "10rem"
    },
  },
  request_button2: {
    borderRadius: ".5rem",
    width: "auto",
    border: "2px solid",
    padding: "10px",
    marginLeft: "5%",
    backgroundColor: BackgroundBlue,
    color: PrimaryColor,
    cursor: "pointer",
    "&:hover": {
      background: LightPrimaryColor,
      color: White,
    },
    "@media (max-width: 640px)": {
      fontSize: "0.9rem",
      marginTop: "5%",
      marginLeft: "0%",
      width: "10rem"
    },
  },
  link: {
    color: PrimaryColor,
    "&:hover": {
      color: LightPrimaryColor,
    },
  },
}));
