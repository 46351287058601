import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  carousel: {
    marginTop: "-5%",
    width: "100%",
  },
  carouselMedia: {
    height: "100vh",
    objectFit: "cover",
    "@media (max-width: 640px)": {
      height: "30rem",
    },
  },
  mediaDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(51, 51, 51, .8)",
    zIndex: 11,
    opacity: 1,
    transition: "opacity 0.3s ease",
    "&:click": {
      transition: "opacity 0.3s ease",
    },
  },
  media: {
    marginTop: "5%",
    marginRight: "2.5%",
    width: "90%",
    height: "80%",
    objectFit: "contain",
    cursor: "zoom-out",
    borderRadius: "5px",
    "@media (max-width: 640px)": {
      width: "100%",
      height: "100%",
    },
  },
  mediaReview: {
    marginTop: "7.5%",
    width: "65%",
    height: "80%",
    objectFit: "contain",
    cursor: "zoom-out",
    "@media (max-width: 640px)": {
      width: "100%",
      height: "100%",
    },
  },
  closeIcon: {
    position: "absolute",
    top: "10%",
    right: "5%",
    border: "none",
    background: "none",
    WebkitAppearance: "none",
    cursor: "pointer",
    color: "black",
    zIndex: 10,
  },
  closeIconReview: {
    position: "absolute",
    top: "20%",
    right: "10%",
    border: "none",
    background: "none",
    WebkitAppearance: "none",
    cursor: "pointer",
    color: "black",
    zIndex: 10,
  },
}));
