import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { Spinner } from "../../components";
import Calendar from "react-calendar";
import AlertService from "../../service/AlertService";
import EmailService from "../../service/EmailService";
import SmsService from "../../service/SmsService";
import ApiService from "../../service/ApiService";
import ToastMessages from "../../model/enum/ToastMessages";
import AppointmentModel from "../../model/appointment";
import StatusModel from "../../model/status";
import UserModel from "../../model/user";
import ClientModel from "../../model/client";
import Status from "../../model/enum/StatusClass";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import ClientModal from "../client/ClientModal";
import DeleteModal from "../modal/DeleteModal";
import TimePicker from "react-time-picker";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/admin/edit-icon.svg";
import useStyles from "./styles";
import styled from "styled-components";

// Customize the button styles here
export const CalendarWrapper = styled.div`
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: white; /* Custom color */
    color: black; /* Button text color */
    border: none; /* Remove default border */
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:after {
    color: white; /* Text color for active date */
    background: #1e2e55;
    border-radius: 20px; /* Optional: make the button rounded */
  }

  .react-calendar__tile--active:hover {
    background-color: #1e2e5580; /* Darker color on hover */
  }
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
    top: "80%",
  },
};

const AppointmentModal = ({
  appointment,
  handleCreate,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = appointment != null;
  const id = edit ? appointment.id : 0;
  const [scheduledAt, setScheduledAt] = useState(
    edit ? new Date(appointment.scheduledAt) : new Date()
  );
  const [start, setStart] = useState(edit ? appointment.initialTime : "08:00");
  const [end, setEnd] = useState(edit ? appointment.finalTime : "09:00");
  const [notes, setNotes] = useState(edit ? appointment.notes : null);
  const [status, setStatus] = useState(edit ? appointment.Status : null);
  const [statusList, setStatusList] = useState<StatusModel[]>([]);
  const [sellerId, setSellerId] = useState(
    edit && appointment.userId ? appointment.userId : null
  );
  const [sellers, setSellers] = useState<UserModel[]>([]);
  const [client, setClient] = useState(edit ? appointment.Client : null);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [notify, setNotify] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get clients list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          setClients(data);
        }
      );

      setToggleSpinner(false);
    };

    if (clients.length <= 0 && open) fetchData();
  }, [open, clients]);

  //Get sellers list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<UserModel[]>(
        `${DatabaseEntities.USERS}?roleId=4` //Get only the users that are sellers
      ).then((data: UserModel[]) => {
        setSellers(data);
      });

      setToggleSpinner(false);
    };

    if (sellers.length <= 0 && open) fetchData();
  }, [open, sellers]);

  //Get status list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<StatusModel[]>(
        `${DatabaseEntities.STATUS}?type=General`
      ).then((data: StatusModel[]) => {
        setStatusList(data);
      });

      setToggleSpinner(false);
    };

    if (statusList.length <= 0 && open) fetchData();
  }, [open, statusList]);

  const handleClient = (e: any) => {
    let value = e.target.value;
    var clt = clients.find((x) => x.id == value);
    setClient(clt);
  };

  const handleNotes = (e: any) => {
    setNotes(e.target.value);
  };

  const handleStatus = (e: any) => {
    let value = e.target.value;
    var stt = statusList.find((x) => x.id == value);
    setStatus(stt);
  };

  const handleSeller = (e: any) => {
    let value = e.target.value;
    setSellerId(value);
  };

  const handleNotification = () => {
    setNotify((notify) => !notify);
  };

  const handleStart = (value: any) => {
    setStart(value);
  };

  const handleEnd = (value: any) => {
    setEnd(value);
  };

  const register = async () => {
    if (invalidInput()) return;

    const payload: AppointmentModel = {
      id: id,
      scheduledAt: scheduledAt,
      initialTime: start,
      finalTime: end,
      notes: notes,
      clientId: client?.id,
      userId: sellerId,
      statusId: status?.id,
    };

    if (edit) {
      await handleUpdate(payload)
        .then(async () => {
          if (notify) {
            await handleEmailAndSmsSend();
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.APPOINTMENT_FAILED);
        });
    } else {
      await handleCreate(payload)
        .then(async () => {
          if (notify) {
            await handleEmailAndSmsSend();
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.APPOINTMENT_FAILED);
        });
    }
    handleClose();
  };

  const handleEmailAndSmsSend = async () => {
    let type: string = "";

    switch (status.name) {
      case Status.ACCEPTED:
        type = "Appointment Confirmed";
        break;
      case Status.CANCELED:
        type = "Appointment Denied";
        break;
      case Status.COMPLETED:
        type = "Service Completed";
        break;
      default:
        break;
    }

    if (type != "") {
      await SmsService.send(client.phone, type, start, end, scheduledAt)
        .then(() => {
          AlertService.succes("Sms notification sent");
        })
        .catch(() => {
          AlertService.error("Sms notification not sent");
        });
      await EmailService.sendEmailToUser(
        client.firstName,
        client.email,
        type,
        scheduledAt,
        start,
        end
      )
        .then(() => {
          AlertService.succes("Email notification sent");
        })
        .catch(() => {
          AlertService.error("Email notification not sent");
        });
    }
  };

  const invalidInput = () => {
    return (
      scheduledAt === undefined ||
      start === "" ||
      end === "" ||
      client === null ||
      status === ""
    );
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Appointment
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <Typography className={classes.title}>
                  <DeleteModal item={appointment} handleDelete={handleDelete} />
                </Typography>
              ) : (
                <Typography className={classes.title}>
                  Schedule appointment
                </Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            {edit && <hr />}
            <form className={classes.form}>
              <div className={classes.container}>
                <div className={classes.inputContainer}>
                  <div className={classes.inputGroup}>
                    <select
                      className={classes.input}
                      onChange={handleClient}
                      value={client?.id}
                    >
                      <option value="">
                        Select Client
                      </option>
                      {clients.map((clt: ClientModel) => {
                        return (
                          <option key={clt.id} value={clt.id}>
                            {clt.firstName} {clt.lastName}
                          </option>
                        );
                      })}
                    </select>
                    <ClientModal
                      externalService={true}
                      resetList={() => setClients([])}
                      setClient={(clt: ClientModel) => setClient(clt)}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <TimePicker
                      className={classes.input}
                      onChange={handleStart}
                      value={start}
                      clearIcon={null}
                      clockIcon={null}
                      shouldOpenClock={() => false}
                    />
                    <TimePicker
                      className={classes.input}
                      onChange={handleEnd}
                      value={end}
                      clearIcon={null}
                      clockIcon={null}
                      shouldOpenClock={() => false}
                    />
                  </div>
                  <select
                    className={classes.input}
                    onChange={handleSeller}
                    value={sellerId}
                  >
                    <option value="">
                      Select Seller
                    </option>
                    {sellers.map((slr: UserModel) => {
                      return (
                        <option key={slr.id} value={slr.id}>
                          {slr.firstName} {slr.lastName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className={classes.input}
                    onChange={handleStatus}
                    value={status?.id}
                  >
                    <option value="">
                      Status
                    </option>
                    {statusList.map((stts) => {
                      return (
                        <option key={stts.id} value={stts.id}>
                          {stts.name}
                        </option>
                      );
                    })}
                  </select>
                  <textarea
                    className={classes.textarea}
                    placeholder="Add some notes..."
                    onChange={handleNotes}
                    value={notes}
                  />
                  <div className={classes.inputGroup}>
                    <input
                      type="checkbox"
                      className={classes.checkbox}
                      onChange={handleNotification}
                      checked={notify}
                    />
                    <Typography className={classes.checkboxtext}>
                      Send notification{" "}
                    </Typography>
                  </div>
                </div>
                <div
                  className={`calendar-container ${classes.calendarContainer}`}
                >
                  <CalendarWrapper>
                    <Calendar
                      className={classes.calendar}
                      calendarType={"US"}
                      value={scheduledAt}
                      tileDisabled={({ date }) => date.getDay() === 6} //disabled on saturdays
                      onChange={(date: any) => setScheduledAt(date)}
                    />
                  </CalendarWrapper>
                </div>
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={register}
                disabled={invalidInput()}
              >
                Schedule now
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default AppointmentModal;
