export const PrimaryColor = '#1e2e55'; //'#832D29'
export const PrimaryColorHover = "rgba(0, 0, 0, .25)"
export const LightPrimaryColor = '#1e2e5580';// '#5C1814'
export const SecondaryColor = '#CEBD90';
export const LightBudgetGrey = 'f5f5f5';
export const DarkRedText = 'darkRed';
export const GoldText = '#a1895f';
export const PurpleText = '#592C5F';
export const DarkBlueText = '#070441';
export const White = 'white';
export const Green = 'green';
export const Black = 'black';
export const Red = 'red';
export const LightGrey = '#F9F9F9';
export const DarkGrey = '#777';
export const Orange = '#FF4F1D';
export const LightOrange = '#ff6900';
export const DarkBlue = '#153D7C';
export const BackgroundBlue = "#EEEFF6";
export const TableHeaderGrey = "#e0e0e0";
export const TableBodyGrey = '#757575';
export const LightGreyBackground = 'rgba(249, 249, 249, 1)';
export const InputColor = '#7E7D7D';