import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import ErrorModel from "../../model/error";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TransformDate from "../../utils/TransformDate";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { Spinner } from "../../components";
import { ToastContainer } from "react-toastify";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";
import ApiService from "../../service/ApiService";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Errors = () => {
  const classes = useStyles();
  const [errorList, setErrorList] = useState<ErrorModel[]>([]);
  const [items, setItems] = useState<ErrorModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ErrorModel[]>(DatabaseEntities.ERRORS).then(
        (data: ErrorModel[]) => {
          if (data.length == 0) {
            setToggleSpinner(false);
          }

          setErrorList(data);

          for (let i = 0; i < rowsPerPage && i < data.length; i++) {
            items.push(data[i]);
          }

          setToggleSpinner(false);
        }
      );
    };

    if (toggleSpinner) fetchData();
  });

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: ErrorModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < errorList.length) items.push(errorList[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  const resetList = (): void => {
    setErrorList([]);
    setItems([]);
    setPage(0);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <div className={classes.headerContainer}>
            <Typography className={classes.title}>Error</Typography>
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Code</StyledTableCell>
                    <StyledTableCell align="right">Reason</StyledTableCell>
                    <StyledTableCell align="right">Service</StyledTableCell>
                    <StyledTableCell align="right">Created At</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: ErrorModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.code}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.reason}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.service}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <MoreVertIcon />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={errorList.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Errors;
