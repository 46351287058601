import { makeStyles } from "@material-ui/core/styles";

import {
  White,
  PrimaryColor,
  LightPrimaryColor,
  LightGrey,
  InputColor
} from "../../styles/colors";

export default makeStyles(() => ({
  container: {
    marginTop: "150px",
    marginBottom: "50px",
    "@media (max-width: 640px)": {
        width: "100%",
        marginTop: "0%",
      },
  },
  budgetCard: {
    width: "auto",
    padding: "20px",
    borderRadius: "25px",
    marginLeft: "5%",
    "@media (max-width: 640px)": {
      width: "90% !important",
      marginTop: "7.5%",
    },
  },
  budget_content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  budget_title: {
    color: PrimaryColor,
    fontSize: "1.2rem",
    textAlign: "start",
    width: "100%",
  },
  budget_input: {
    marginTop: "5%",
    width: "100% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color: InputColor,
    background: LightGrey,
  },
  budget_button: {
    width: "95% !important",
    height: "auto",
    borderRadius: "10px",
    marginTop: "10%",
    padding: ".5rem",
    cursor: "pointer",
    background: PrimaryColor,
    color: White,
    textTransform: "uppercase",
    "&:disabled": {
      cursor: "not-allowed",
    },
    "&:hover": {
      background: LightPrimaryColor,
    },
  },
}));
