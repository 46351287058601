import imageCompression from "browser-image-compression";

export default class ImageCompressor {
  /**
   * 
   * @param file 
   * @returns 
   */
  static async compress(file: File | null) {
    if (file === null) return;

    const options = {
      maxSizeMB: 4
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }
}
