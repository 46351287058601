import { makeStyles } from "@material-ui/core/styles";
import {
  Green,
  White,
  PrimaryColor,
  LightPrimaryColor,
  LightGrey,
  BackgroundBlue,
  InputColor
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  request: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    padding: "5rem",
    "@media (max-width: 820px)": {
      width: "90% !important",
      //marginTop: "30%",
      marginTop: "120px",
      padding: 0,
    },
    "@media (min-width: 1536px)": {
      width: "60% !important",
    },
  },
  city: {
    color: Green,
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: PrimaryColor,
  },
  subtitleDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  subtitle: {
    color: PrimaryColor,
    fontSize: "1.5rem",
    fontWeight: "bold",
    "@media (max-width: 640px)": {
      fontSize: "1rem",
    },
  },
  inputDiv: {
    display: "flex",
    flexDirection: "row",
    width: "80% !important",
    marginTop: "5%",
    marginLeft: "7.5%",
    "@media (max-width: 640px)": {
      flexDirection: "column",
      width: "100% !important",
      alignItems: "center",
      margin: 0,
      padding: 10,
    },
  },
  input: {
    width: "100% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color: InputColor,
    background: LightGrey,
    "@media (max-width: 640px)": {
      fontSize: "0.8rem",
    },
    "& div, input, button": {
      //child
      color: InputColor,
      border: "none",
    },
  },
  button: {
    padding: "1% 0% 1% 0%",
    width: "7rem",
    marginTop: "5%",
    color: "white",
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
  },
  stpperDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "-5%",
    "@media (max-width: 640px)": {
      width: "70%",
      marginTop: "10%",
    },
  },
  breadcrumbCircular: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    borderRadius: "20px",
    background: PrimaryColor,
    color: White,
  },
  breadcrumbCircularDisabled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    borderRadius: "20px",
    background: LightGrey,
    color: White,
  },
  breadcrumbText: {
    color: PrimaryColor,
    marginTop: "1rem",
    textAlign: "center",
    "@media (max-width: 640px)": {
      fontSize: ".8rem",
    },
  },
  breadcrumbTextDisabled: {
    color: LightGrey,
    marginTop: "1rem",
  },
}));
